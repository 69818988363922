import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate'
import { messages } from 'vee-validate/dist/locale/pt_BR.json'
import { required, length, regex } from 'vee-validate/dist/rules'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} é obrigatório',
  computesRequired: true,
})

extend('length', {
  ...length,
  message: messages.length,
})

extend('regex', {
  ...regex,
  message: messages.regex,
})

export { ValidationProvider, ValidationObserver }
