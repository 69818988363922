<template>
  <div class="balance">
    <span v-if="config.loading" class="icon spinner">
      <i class="fas fa-spinner"></i>
    </span>

    <template v-if="config.loading">
      <span>carregando ...</span>
    </template>

    <template v-else>
      <h3 class="title">{{ title }}</h3>

      <p class="text" v-html="formattedMessage" />

      <template v-if="isReadyToType && info.periodos">
        <hr />

        <h3 class="title">Períodos</h3>

        <ul class="list">
          <li
            v-for="(period, index) in info.periodos"
            :key="index"
            class="list__item text"
          >
            <span>{{ period.dtRepasse | truncate(4, '') }}: </span>
            <input
              v-model.lazy="period.valor"
              v-money="money"
              type="text"
              disabled
            />
          </li>
        </ul>
      </template>

      <template v-if="hasNotJoin || hasLackOfAuthorization">
        <hr />

        <iframe
          :src="video"
          class="iframe"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </template>
    </template>
  </div>
</template>

<script>
import BalanceAPI from '@/lib/api/Balance'

import { VMoney } from 'v-money'

export default {
  name: 'InfoBalance',

  directives: { money: VMoney },

  props: {
    documentNumber: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    config: {
      loading: false,
    },
    videos: {
      adhesion: 'https://www.youtube.com/embed/WGoWqfSHL9c',
      authorization: 'https://www.youtube.com/embed/boaNGCxkzHg',
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    info: {},
  }),

  computed: {
    isReadyToType() {
      const status = 'Digitar'
      return this.info?.Planilha === status || false
    },

    hasInsufficientFunds() {
      const status = 'Saldo insuficiente'
      return this.info?.Planilha === status || false
    },

    hasNotJoin() {
      const status = 'Não fez adesão ao saque-aniversário'
      return this.info?.Planilha === status || false
    },

    hasLackOfAuthorization() {
      const status = 'Falta autorização'
      return this.info?.Planilha === status || false
    },

    title() {
      let title = 'Pode ser antecipado'

      if (!this.isReadyToType) {
        title = this.info.Planilha
      }

      return title
    },

    video() {
      if (this.hasNotJoin) return this.videos.adhesion
      if (this.hasLackOfAuthorization) return this.videos.authorization
      return ''
    },

    formattedMessage() {
      let text = this.info?.Mensagem || ''

      text = text.replace(/(?:\r\n|\r|\n)/g, '<br />')

      return text
    },
  },

  mounted() {
    this.consult()
  },

  methods: {
    setData({ data }) {
      for (const key of Object.keys(this.info)) {
        this.$delete(this.info, key)
      }
      for (const key of Object.keys(data)) {
        this.$set(this.info, key, data[key])
      }
    },

    handleError(err) {
      this.$emit(
        'message',
        err?.response?.data?.error?.message ||
          err?.error?.response?.data?.error?.message ||
          err?.message ||
          err
      )
    },

    async consult() {
      const api = new BalanceAPI()
      const params = {
        document: this.documentNumber.replace(/\D/g, ''),
      }

      this.config.loading = true
      this.$emit('message', '')
      this.$emit('status', '')

      try {
        const { data } = await api.consult(params)
        this.setData(data)
        this.$emit('status', {
          status: data.data.Planilha,
          amount: data.data.valorPrincipal,
        })
      } catch (err) {
        this.handleError(err)
      } finally {
        this.config.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.balance {
  position: relative;
}

.title {
  font: {
    family: 'Montserrat-Semibold', sans-serif;
    size: 1.25rem;
  }
  color: $color-secondary;
}

.text {
  font: {
    family: 'Montserrat-Regular', sans-serif;
    size: 1.175rem;
  }
  color: #444;
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;

  font-size: 2em;
  color: $color-primary;

  animation: 0.75s linear infinite spinner-border;
}

.list {
  margin: 0;
  padding: 0;

  &__item {
    $padding: 30px;

    position: relative;
    margin-bottom: 20px;

    display: flex;
    padding-left: $padding;

    &::before {
      $size: 10px;

      content: '';

      position: absolute;
      top: 50%;
      left: (($padding / 2) - ($size / 2));

      display: block;
      width: $size;
      height: $size;
      background-color: $color-primary;

      transform: translateY(-50%);
    }

    input,
    span {
      display: inline-block;

      font: {
        family: inherit;
        size: inherit;
      }
    }

    span {
      color: $color-secondary;
    }

    input {
      margin-left: 5px;

      border: none;
      background-color: rgba(#fff, 0);

      color: #444;
    }
  }
}

.iframe {
  width: 100%;
  min-height: 350px;
  padding: 20px;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
</style>
