import axios from 'axios'

import config from '@/lib/data/config'
import theme from '@/lib/data/theme'

export default class API {
  constructor() {
    axios.defaults.baseURL = config.BASE_URL
    axios.defaults.headers.common['X-Authorization'] = `${config.API_KEY}`
    axios.defaults.headers.common['content-type'] = 'application/json'

    this.api = axios
    this.mockedTheme = { data: theme }
  }

  async fetchTheme() {
    const { mockedTheme } = this

    // const { data } = await this.api.get(`${this.baseUrl}/theme`);
    // return data ?? mockedTheme;

    return mockedTheme
  }
}
