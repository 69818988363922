import Vue from 'vue'
import money from 'v-money'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import { ValidationProvider, ValidationObserver } from './utils/vee-validate'

import App from './App.vue'
import router from './router'
import store from './store'
import meta from 'vue-meta'

import '../plugins'

require('@/assets/scss/main.scss')
require('@/assets/scss/material-form.scss')

Vue.component('VValidate', ValidationProvider)
Vue.component('VValidateObserver', ValidationObserver)

Vue.config.productionTip = false

Vue.filter('truncate', (text, length, clamp) => {
  const node = document.createElement('div')
  node.innerHTML = text
  const content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
})

Vue.use(money, { precision: 2, decimal: ',', thousands: '.', prefix: 'R$ ' })

Vue.use(meta)

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://ada29657a6084892ba84fdf07d53a154@o1313975.ingest.sentry.io/6564842',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.25 : 1.0,
  })
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
