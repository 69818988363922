<template>
  <div class="form-info-end">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="form-contract__title">
          <h2><span>Final</span></h2>
        </div>
      </div>

      <div class="column is-full">
        <div class="form-contract__subtitle">
          <p>
            Estamos quase terminando:
            <span v-if="isPage.fgts">
              Informe seus dados bancários para depósito do crédito FGTS
            </span>
            <span v-else>
              Informe seus dados bancários para depósito do empréstimo
            </span>
          </p>

          <br />
          <p>IMPORTANTE:</p>

          <ul>
            <li v-if="isPage.fgts" class="text">
              <span>Se você não autorizou o</span> Banco J. Safra
              <span>
                no App do FGTS, sua proposta levará tempo superior ao normal
                para ser processada;
              </span>
            </li>
            <li v-if="pix_key === ''" class="text">
              <span>Os dados bancários abaixo</span>
              devem ser SEUS<span>;</span>
            </li>
          </ul>

          <br />
          <p v-if="showPixOptions">
            <span>Escolha uma das opções abaixo:</span>
          </p>
        </div>
      </div>

      <div v-if="pix.loading" class="column is-full">
        <div class="message has-background-success-light">
          <span class="has-text-success">
            {{ pix.loadingMessages[pix.loadingMessageIndex] }}
          </span>
          <span class="has-text-success awaiting">. . .</span>
        </div>
      </div>

      <div v-if="showPixOptions" class="column is-full">
        <ul class="pix__container mb-5">
          <li
            v-for="(
              { id, bankName, branchCode, accountNumber }, index
            ) in pix.list"
            :key="index"
            :class="{ active: pix_key === id }"
            class="pix__item"
          >
            <label>
              <input
                v-model="pix_key"
                :value="id"
                type="radio"
                class="pix__item--radio"
              />
              <span class="pix__item--key">Chave Pix: {{ id }}</span>
              <span class="pix__item--bank">Banco: {{ bankName }}</span>
              <span class="pix__item--branch">Agência: {{ branchCode }}</span>
              <span class="pix__item--number">Número: {{ accountNumber }}</span>
              <i
                :class="[
                  pix_key === id ? 'fas fa-check-circle' : 'far fa-circle',
                ]"
              />
            </label>
          </li>
          <li :class="{ active: pix_key === '' }" class="pix__item">
            <label>
              <input
                v-model="pix_key"
                :value="''"
                type="radio"
                class="pix__item--radio"
              />
              <span class="pix__item--key">Quero utilizar outra conta</span>
              <i
                :class="[
                  pix_key === '' ? 'fas fa-check-circle' : 'far fa-circle',
                ]"
              />
            </label>
          </li>
        </ul>

        <div
          v-if="pix_key"
          class="pix__control control has-icons has-icons-right"
        >
          <input
            v-model.lazy="wish_amount"
            v-money="money"
            class="control control-lg control-input"
            type="text"
            name="wish_amount"
          />
          <label class="label">Qual valor deseja?</label>
          <i class="bar" />
        </div>

        <div
          v-if="pix_key && isPage.person"
          class="pix__control control has-icons has-icons-right"
        >
          <base-input
            :value="referral_phone"
            :type="'tel'"
            :format="'(##) #####-####'"
            @input="referral_phone = $event"
          >
            Indicar amigo: telefone
            <span class="info-basic--span">(opcional)</span>
          </base-input>
        </div>

        <div
          v-if="pix_key && isPage.cpr"
          class="pix__control control has-icons has-icons-right"
        >
          <input
            v-model.lazy="patrimony"
            v-money="money"
            class="control control-lg control-input"
            type="text"
            name="patrimony"
          />
          <label class="label">
            Qual o valor do seu patrimônio?
            <span class="info-career--span">*</span>
          </label>
          <i class="bar" />
        </div>
      </div>

      <template v-if="showBankInputs">
        <div class="column">
          <div class="info-end">
            <p class="control address__address-local address-number">
              <base-select
                :value="bank_id"
                :options="banks"
                :disabled="isPage.assistance"
                :autocomplete="!isPage.assistance"
                required
                @selected="bank_id = $event.value"
              >
                Qual seu banco?
              </base-select>
            </p>
            <small style="font-size: 0.8rem">
              OBS: Bancos digitais não são aceitos, apenas os que estão na lista
            </small>

            <div class="info-end__radio">
              <p>Tipo de conta? <span class="info-end--span">*</span></p>
              <label
                v-for="(option, index) in account_typeOptions"
                :key="index"
              >
                <input v-model="account_type" type="radio" :value="option" />
                {{ option }}
              </label>
            </div>
            <small style="font-size: 0.8rem">
              OBS: Não são aceitas conta salário.
            </small>
          </div>
        </div>

        <div class="column">
          <div class="info-end">
            <base-input
              :value="branch_number"
              :has-error="
                validation.hasError('branch_number') || branch_number_error
                  ? true
                  : false
              "
              :first-error="
                validation.firstError('branch_number') || branch_number_error
              "
              :is-passed="
                validation.isPassed('branch_number') && !branch_number_error
              "
              maxlength="6"
              @input="branch_number = $event"
            >
              Sua agência?
              <span class="info-professional--span">*</span>
            </base-input>

            <base-input
              :value="account_number"
              :has-error="
                validation.hasError('account_number') || account_number_error
                  ? true
                  : false
              "
              :first-error="
                validation.firstError('account_number') || account_number_error
              "
              :is-passed="
                validation.isPassed('account_number') && !account_number_error
              "
              @input="account_number = $event"
            >
              Número da sua conta (com dígito)?
              <span class="info-professional--span">*</span>
            </base-input>

            <!-- <div class="info-end__radio">
              <p>
                Você é uma pessoa politicamente exposta?
                <span class="info-end--span">*</span>
              </p>
              <label v-for="(option, index) in personTypeOptions" :key="index">
                <input v-model="personType" type="radio" :value="option" />
                {{ option }}
              </label>
              <div class="info-end__radio">
                <p>
                  Ou, você se relaciona com alguém politicamente exposto?
                  <span class="info-end--span">*</span>
                </p>
                <label v-for="(option, index) in personTypeOptions" :key="index">
                  <input
                    v-model="politicyRelationship"
                    type="radio"
                    :value="option"
                  />
                  {{ option }}
                </label>
              </div> -->

            <!-- <div class="control has-icons has-icons-right">
              <input
                v-model.lazy="balance"
                v-money="money"
                class="control control-lg control-input"
                type="text"
                name="balance"
              />
              <label class="label"> Qual seu saldo atual de FGTS? </label>
              <i class="bar"></i>
            </div>
            <small style="font-size: 0.8rem">
              Se o seu saldo livre no FGTS for inferior a R$ 500,00, o seu pedido
              não poderá ser processado.
            </small> -->

            <div class="control has-icons has-icons-right">
              <input
                v-model.lazy="wish_amount"
                v-money="money"
                class="control control-lg control-input"
                type="text"
                name="wish_amount"
              />
              <label class="label">Qual valor gostaria de antecipar?</label>
              <i class="bar" />
            </div>

            <base-input
              v-if="isPage.person"
              :value="referral_phone"
              :type="'tel'"
              :format="'(##) #####-####'"
              @input="referral_phone = $event"
            >
              Indicar amigo: telefone
              <span class="info-basic--span">(opcional)</span>
            </base-input>

            <div v-if="isPage.cpr" class="control has-icons has-icons-right">
              <input
                v-model.lazy="patrimony"
                v-money="money"
                class="control control-lg control-input"
                type="text"
                name="patrimony"
              />
              <label class="label">
                Qual o valor do seu patrimônio?
                <span class="info-career--span">*</span>
              </label>
              <i class="bar" />
            </div>
          </div>
        </div>
      </template>
    </div>

    <div>
      <div>
        <div class="info-end">
          <div class="form-contract__button-next has-text-right">
            <button
              :disabled="!formIsValidated || actionLoading"
              type="submit"
              class="is-centralized"
              @click="submit"
            >
              ENVIAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { VMoney } from 'v-money'
import SimpleVueValidator from 'simple-vue-validator'

import BaseInput from '@/components/BaseInput.vue'
import BaseSelect from '@/components/BaseSelect.vue'

import FormContractApi from '@/lib/api/FGTS'
import StarkbankApi from '@/lib/api/Starkbank'
import QITechAPI from '@/lib/api/QITech'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'InfoEnd',

  directives: { money: VMoney },

  components: {
    BaseInput,
    BaseSelect,
  },

  mixins: [SimpleVueValidator.mixin],

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  validators: {
    bankName(value) {
      return Validator.value(value).required()
    },
    account_type(value) {
      return Validator.value(value).required()
    },
    branch_number(value) {
      return Validator.value(value).required()
    },
    account_number(value) {
      return Validator.value(value).required()
    },
    patrimony(value) {
      return Validator.value(value).required()
    },
    // personType(value) {
    //   return Validator.value(value).required()
    // },
    // politicyRelationship(value) {
    //   return Validator.value(value).required()
    // },
  },

  data: () => ({
    account_created_at: null,
    bank_id: '',
    banks: [],
    // balance: '',
    // balance_error: '',
    wish_amount: 0,
    wish_amount_error: '',
    account_typeOptions: ['Poupança', 'Corrente', 'Pagamento'],
    account_type: '',
    account_type_error: '',
    branch_number: '',
    branch_number_error: '',
    account_number: '',
    account_number_error: '',
    // personTypeOptions: ['Sim', 'Não'],
    // personType: '',
    // politicyRelationship: '',
    referral_phone: '',
    patrimony: '',
    actionLoading: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    pix_key: null,
    pix: {
      list: {},
      loading: false,
      loadingMessageIndex: 0,
      loadingMessages: ['Verificando chaves PIX', 'Aguarde'],
    },
    accountTypes: {
      checking: 'Corrente',
      savings: 'Poupança',
      payment: 'Pagamento',
    },
    bank_name: '',
    validBankIDs: [
      1, 2, 3, 4, 5, 6, 72, 101, 142, 157, 189, 190, 191, 192, 195, 234, 243,
      257, 306, 313, 324, 512, 523, 569,
    ],
  }),

  computed: {
    ...mapState({
      account: (state) => state.fgts.account,
      spreadsheet: (state) => state.fgts.spreadsheet,
    }),

    hasPixOptions() {
      const keys = Object.keys(this.pix.list)
      return keys.length !== 0
    },

    showPixOptions() {
      return !this.pix.loading && this.hasPixOptions
    },

    showBankInputs() {
      if (!this.pix.loading && !this.hasPixOptions) return true
      return !this.pix.loading && this.pix_key === ''
    },

    formIsValidated() {
      const wish_amount = parseInt(
        (this.wish_amount || '').replace(/\D/g, '') || 0
      )

      return (
        !!wish_amount &&
        this.validation.isPassed('branch_number') &&
        this.validation.isPassed('account_number') &&
        this.validation.isPassed('account_type') &&
        (this.isPage.cpr ? this.validation.isPassed('patrimony') : true)
        // this.validation.isPassed('politicyRelationship')
      )
    },

    productID() {
      // Default: 3 (FGTS)
      const options = {
        cpr: 2,
        vehicle: 4,
        immobile: 5,
        person: 7,
        assistance: 11,
      }
      const key = Object.keys(this.isPage).find((key) => this.isPage[key])

      return options[key] ?? 3
    },

    hasMobileCompatibility() {
      const agent = navigator.userAgent || navigator.vendor || window.opera
      let check = false

      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          agent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          agent.substr(0, 4)
        )
      ) {
        check = true
      }

      return check
    },

    hasMediaDevicesCompatibility() {
      return !!navigator.mediaDevices
    },

    isMobileFirefox() {
      const vendor = navigator.vendor
      return vendor === ''
    },

    isValid() {
      return (
        this.hasMobileCompatibility &&
        this.hasMediaDevicesCompatibility &&
        !this.isMobileFirefox
      )
    },
  },

  watch: {
    pix_key(key) {
      if (key) {
        this.bank_id = this.pix.list[key].bank_id
        this.branch_number = this.pix.list[key].branchCode
        this.account_number = this.pix.list[key].accountNumber
        this.account_type = this.accountTypes[this.pix.list[key].accountType]
        this.account_created_at = this.dateFormat(
          this.pix.list[key].accountCreated
        )
        this.patrimony = ''
      } else {
        this.bank_id = ''
        this.branch_number = ''
        this.account_number = ''
        this.account_type = ''
        this.referral_phone = ''
        this.patrimony = ''
        this.account_created_at = null
      }
    },
  },

  async mounted() {
    await this.getBanksList()

    if (this.isPage.assistance) {
      this.bank_id = 2
    }
    if (!this.isPage.assistance) this.fetchPix()
  },

  methods: {
    ...mapActions({
      setAccount: 'validation/SET_ACCOUNT',
    }),

    dateFormat(data) {
      if (!data) return ''

      const options = {
        second: '2-digit',
        minute: '2-digit',
        hour: '2-digit',
        timeZone: 'America/Sao_Paulo',
      }
      const formattedDate = new Date(data * 1000).toLocaleDateString(
        'pt-br',
        options
      )

      return formattedDate
    },

    backStep() {
      this.$emit('nextStep', -1)
    },

    handleError(err) {
      const error = {
        message:
          err?.response?.data?.error?.message ||
          err?.error?.response?.data?.error?.message ||
          err?.message ||
          err,
        others: [],
      }

      if (err?.response?.data?.error?.errors) {
        const entries = Object.entries(err?.response?.data?.error?.errors)
        for (const [entry, values] of entries) {
          for (const [key, value] of Object.entries(values)) {
            error.others.push({ entry, value })
          }
        }
      }

      this.$emit('message', error)
    },

    formatToNumber(number) {
      return parseFloat(
        number.replace('R$', '').replace('.', '').replace(',', '.')
      )
    },

    getFormattedData() {
      const status = this.spreadsheet

      const promoter_id = !~window.location.origin.indexOf('fgts') ? 1 : 1541
      const date = this.dateFormat(this.account_created_at)

      return {
        // Primary data
        tenant_id: 1,
        master_id: 1,
        promoter_id,
        account_id: this.account.id,
        rate_id: 6,

        //Bank Account
        bank_id: this.bank_id,
        account_type: this.account_type,
        branch_number: this.branch_number,
        account_number: this.account_number,
        pix_key: this.pix_key,
        account_created_at: date.split(',')[0].split('/').reverse().join('-'),

        //Proposals
        product_id: this.productID,
        wish_amount: this.formatToNumber(this.wish_amount),
        wish_bankaccount: false,
        wish_creditcard: false,
        // balance: this.balance,
        // ppe: this.personType == "Sim" ? true : false,
        // rppe: this.politicyRelationship == "Sim" ? true : false,
        ...(this.isPage.person ? { referral_phone: this.referral_phone } : ''),
        ...(this.isPage.cpr ? { patrimony: this.patrimony } : ''),
        ...(status ? { status } : ''),
      }
    },

    async saveBankData(data) {
      const api = new FormContractApi()
      return await api.formContractBankAccount(data)
    },

    async saveProposalData(data) {
      const api = new FormContractApi()
      return await api.formContractProposal(data)
    },

    handleSuccess(data) {
      const { status, account_id } = data
      const isValidStatus = String(status).toLowerCase() === 'digitar'
      const isFGTS = this.isPage.fgts

      this.$gtm.trackEvent({ event: 'contract-end', account_id })

      if (isValidStatus && isFGTS) {
        this.setAccount(data)
        this.consultQITech()
      } else {
        const el = document.querySelector('.form-contract__step-by-step')
        el.scrollIntoView()
        this.$emit('nextStep')
      }
    },

    consultQITech() {
      const api = new QITechAPI()
      const document = (this.account.document || '').replace(/\D/g, '')

      this.actionLoading = true
      this.$emit('loading', true)

      api
        .consult({ document })
        .then(({ data }) => {
          if (!data.data.disbursed_issue_amount) return this.$emit('nextStep')

          if (this.isValid) this.goToValidation()
          else {
            this.$emit('showQRCode')
            this.$emit('nextStep')
          }
        })
        .catch((err) => {
          console.log(err)
          this.$emit('nextStep')
        })
        .finally(() => {
          this.actionLoading = false
          this.$emit('loading', false)
        })
    },

    goToValidation() {
      // const name = 'validation-photo-selfie'
      // const type = 'selfie'
      // const side = 'unico'
      // const mode = 'user'

      // this.$router.push({ name, params: { type, side, mode } })
      const { token } = this.account
      window.location = `https://fgts-cb.pratadigital.com.br/validacao/${token}`
    },

    async submit() {
      const data = this.getFormattedData()

      this.actionLoading = true
      this.$emit('loading', true)

      try {
        await this.saveBankData(data).then(async () => {
          await this.saveProposalData(data)
          this.handleSuccess(data)
        })
      } catch (error) {
        this.handleError(error)
      }
    },

    async getBanksList() {
      const api = new FormContractApi()

      await api
        .getBanks()
        .then((response) => {
          this.banks = response.data.data.map(({ name, id }) => ({
            name,
            value: id,
          }))
        })
        .catch((error) => console.log(error))
    },

    async fetchPix() {
      const api = new StarkbankApi()
      const keys = [
        this.account.document,
        this.account.email,
        (this.account.phone || '').replace(/\D/g, ''),
      ]

      let interval = null
      this.pix.loading = true
      interval = setInterval(() => {
        this.pix.loadingMessageIndex = +!this.pix.loadingMessageIndex
      }, 4000)

      for (const key of keys) {
        if (key) {
          const pix_key = key
          await api
            .getBankAccountInfoByPix({ pix_key })
            .then(({ data }) => {
              if (data.data.name)
                this.$set(this.pix.list, pix_key, {
                  id: pix_key,
                  ...data.data,
                })
            })
            .catch((error) =>
              console.log(error?.response?.data?.error?.message || error)
            )
        }
      }

      this.pix.loading = false
      clearInterval(interval)
    },
  },
}
</script>

<style lang="scss">
.message {
  margin: 0 20px;
  padding: 30px 20px;

  font-size: 1.5em;
  text-align: center;

  .awaiting {
    position: relative;
    font-weight: bold;

    transition: 0.35s;
    animation: sliding 1.25s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;

    @keyframes sliding {
      from {
        left: 10px;
        opacity: 1;
      }

      to {
        left: 25px;
        opacity: 0;
      }
    }
  }
}

.pix {
  &__container {
    margin: 0 auto;

    display: grid;
    width: 80%;
    row-gap: 15px;

    @media (min-width: 769px) {
      width: 50%;
    }
  }

  &__control.control {
    margin: 4em auto 0;
    width: 80%;

    @media (min-width: 769px) {
      width: 50%;
    }
  }

  &__item {
    $recuo: 2.5px;

    label {
      position: relative;

      display: grid;
      width: 100%;
      padding: 10px 20px;
      background-color: $color-light;
      border-radius: 10px;
      border: 2px solid rgba(#fff, 0);
      box-sizing: border-box;

      transition: 0.35s;

      grid-template-columns: 30px 1fr 1fr;
      grid-template-areas:
        'mark key key'
        'mark bank bank'
        'mark branch branch'
        'mark number number';
      align-items: center;
      cursor: pointer;

      @media (min-width: 769px) {
        grid-template-columns: 30px 1fr 1fr;
        grid-template-areas:
          'mark key key'
          'mark bank branch'
          'mark number number';
      }
    }

    &--radio {
      position: absolute;
      top: 0;
      left: 0;

      width: 0.1px;
      height: 0.1px;
      opacity: 0;
    }

    &--key {
      grid-area: key;
      font-weight: bold;
    }

    &--bank {
      grid-area: bank;
      margin-top: $recuo;
    }

    &--branch {
      grid-area: branch;
      margin-top: $recuo;
    }

    &--number {
      grid-area: number;
      margin-top: $recuo;
    }

    &.active {
      label {
        background-color: $color-primary;
        color: $color-secondary;
      }
    }

    &:hover {
      label {
        background-color: #fff;
        border-color: $color-primary;
        color: $color-secondary;
      }
    }

    .fas {
      grid-area: mark;
      align-self: flex-start;
      padding-top: 4px;
    }
  }
}

.form-info-end {
  .column {
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      padding: auto;
    }
  }
}

.info-end {
  max-width: 80%;
  margin: 0 auto;

  .control {
    margin-top: 3.25rem;
  }

  .info-end-number {
    margin-top: 4.5rem;

    label,
    select {
      top: -2.5rem !important;
    }
  }

  &--span {
    color: $color-primary;
  }

  &__radio {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    display: flex;
    flex-direction: column;

    p,
    label {
      font-size: 0.8rem;
      color: #616161;
      top: -1rem;
      left: 0;
      margin-bottom: 6px;
    }

    &--other {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      input {
        margin: 0;
        margin-right: 5px;
        margin-top: 4px;
      }

      .control {
        margin: -5px;
        width: 100%;
        padding-left: 10px;

        input {
          width: 100%;
        }
      }
    }
  }

  &__info-end-local {
    margin-top: 3.25rem;
    select {
      height: 33px;
    }
  }
}

.is-centralized {
  margin: 0 auto;
  display: block;
}
</style>
