<template>
  <div class="b-videos">
    <div v-for="topic in topics" :key="topic.number" class="b-topic-video">
      <div class="b-topic-video__item">
        <div class="b-topic-video__number">
          <p>{{ topic.number }}.</p>
        </div>
        <div class="b-topic-video__text" v-html="topic.text"></div>
      </div>
      <div v-if="topic.image" class="b-topic-video__topics--bg-video">
        <div class="b-topic-video__topics--img">
          <img :src="topic.image" alt="" />
          <div
            class="b-topic-video__topics--mask"
            @click="openModal(topic.urlVideo)"
          >
            <img :src="playButton" />
          </div>
        </div>
      </div>
    </div>

    <BaseModalVideo
      v-show="showModal"
      :link-youtube="linkVideo"
      :is-mobile="true"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import playButton from '@/assets/img/fgts-lp/play-button.png'
import BaseModalVideo from '@/components/BaseModalVideo.vue'

export default {
  name: 'BaseTopicVideo',

  components: {
    BaseModalVideo,
  },

  props: {
    topics: {
      type: Array,
      require: true,
    },
  },

  data() {
    return {
      playButton,
      showModal: false,
      linkVideo: null,
    }
  },

  methods: {
    openModal(url) {
      this.linkVideo = url
      this.showModal = true
    },
  },
}
</script>

<style lang="scss">
.b-videos {
  @media (min-width: 767px) {
    display: flex;
  }
}
.b-topic-video {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // padding: 20px 35px;

  @media (min-width: 500px) {
    align-items: center;
  }

  &__item {
    display: flex;
    padding: 20px 35px;

    @media (min-width: 767px) {
      //padding: 20px 15px;
    }
  }

  &__number {
    background-color: $color-primary;
    width: 54px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.6;

    p {
      color: #ffffff;
      font-size: 35px;
      font-family: Montserrat-Bold;
    }
  }

  &__text {
    color: $color-secondary;
    font-size: 16px;
    font-family: Montserrat-Light;
    flex: 2;
    line-height: 1.5;
    padding-left: 15px;

    span {
      font-family: Montserrat-Medium;

      a {
        color: $color-secondary;
        text-decoration: underline;
        font-family: Montserrat-Medium;
      }
    }
  }

  &__topics {
    &--bg-video {
      background-image: url('~@/assets/img/fgts-lp/bg-video.png');
      background-position: center;
      background-size: cover;
      padding: 30px;
      position: relative;
      margin-bottom: 40px;

      @media (min-width: 767px) {
        display: none;
      }
    }

    &--img {
      position: relative;
    }

    &--mask {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #00000042;
      width: 100%;
      height: 97%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
