import Consult from '@/views/Consult.vue'

const name = 'consult-view'
const component = Consult

export default [
  {
    path: '/consulte',
    name,
    component,
  },
]
