import axios from 'axios'

export default class API {
  constructor() {
    delete axios.defaults.headers.common['Authorization'] // prevent CORS errors
    delete axios.defaults.headers.common['X-Authorization'] // prevent CORS errors

    axios.defaults.baseURL = 'https://parallelum.com.br/fipe/api/v2/'

    this.api = axios
  }

  getBrands({ type }) {
    const url = `${type}/brands`
    return this.api.get(url)
  }

  getModels({ type, brand }) {
    const url = `${type}/brands/${brand}/models`
    return this.api.get(url)
  }

  getYears({ type, brand, model }) {
    const url = `${type}/brands/${brand}/models/${model}/years`
    return this.api.get(url)
  }

  getValue({ type, brand, model, year }) {
    const url = `${type}/brands/${brand}/models/${model}/years/${year}`
    return this.api.get(url)
  }
}
