const API_KEY = !~window.location.origin.indexOf('fgts')
  ? 'GIYmCh1FcouwOmgb2xYUhdJJqKjeSqHJzzmbMJVcA8XOQ5ETq2WGqwbxZeWj1ZYA'
  : 'dUd4QCRb49kUoFqxd337BPorPphXllHxknY2crt1XckrxXkxkcakgj4fgnmOHSxz'
const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? !~window.location.origin.indexOf('fgts')
      ? 'https://pratadigital.com.br/sistema/v1'
      : 'https://pratadigital.com.br/sistema-cb/v1'
    : 'http://localhost/v1'

export default {
  BASE_URL,
  API_KEY,
}
