import Base from './Base'

export default class Starkbank extends Base {
  getBankAccountInfoByPix({ pix_key }) {
    const params = { pix_key }
    return this.api.get(`/payments/bank-account/info`, {
      params,
    })
  }
}
