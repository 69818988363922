<template>
  <div class="footer">
    <div class="container">
      <div class="columns footer__container">
        <ul class="column list-bookmarks">
          <li>
            <a class="bookmark" :href="business.socialFacebook" target="_blank">
              <i class="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a
              class="bookmark"
              :href="business.socialInstagram"
              target="_blank"
            >
              <i class="fab fa-instagram" />
            </a>
          </li>
          <li>
            <a class="bookmark" :href="business.socialLinkedin" target="_blank">
              <i class="fab fa-linkedin-in" />
            </a>
          </li>
        </ul>
        <div class="column is-3">
          <div class="site-branding">
            <a
              :href="externalLinks.institutional_1.href"
              :title="business.name"
            >
              <img :src="logo" :alt="`logo da ${business.name}`" />
            </a>
          </div>
        </div>
        <div class="column is-4">
          <div class="columns footer__submenu">
            <div class="column">
              <ul id="menu-footer">
                <li>
                  <a :href="externalLinks.about.href">
                    {{ externalLinks.about.text }}
                  </a>
                </li>

                <li>
                  <a :href="externalLinks.institutional_2.href">
                    {{ externalLinks.institutional_2.text }}
                  </a>
                </li>

                <li>
                  <a :href="externalLinks.institutional_3.href">
                    {{ externalLinks.institutional_3.text }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="column">
              <ul id="menu-footer">
                <li>
                  <a :href="externalLinks.faq.href">
                    {{ externalLinks.faq.text }}
                  </a>
                </li>
                <li>
                  <a :href="externalLinks.contact.href">
                    {{ externalLinks.contact.text }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="footer__title">
            <h3>ENDEREÇO <i class="fas fa-map-pin"></i></h3>
            <p>
              {{ business.addressStreet }} {{ business.addressNumber }}
              <br />
              {{ business.addressNeighborhood }} - {{ business.addressCity }} /
              {{ business.addressState }}
            </p>
          </div>
        </div>
        <div class="column is-3">
          <div class="footer__title">
            <h3>CONTATO <i class="far fa-envelope"></i></h3>
            <a
              :href="`mailto:${business.mail}`"
              :title="`Fale ${business.mail}`"
            >
              {{ business.mail }}
            </a>
            <br />
            <h3 class="phone">SAC <i class="fas fa-phone"></i></h3>
            <a :href="`tel:${business.phoneNumber}`">
              {{ business.phoneDisplay }}
            </a>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column footer-cpnj">
          <p>
            O {{ business.name }} Ltda. é um correspondente bancário - CNPJ nº
            {{ business.cnpj }}, logo, não é um banco ou uma instituição
            financeira. O {{ business.name }} é parceiro dos Bancos
            {{ banks.join(' e ') }}. Na nossa plataforma você terá acesso ao
            produto “Empréstimo com garantia no FGTS” oferecido pelos Bancos
            {{ banks.join(' e ') }}.
          </p>
        </div>
      </div>
      <div class="columns copyright">
        <div class="column site-info">
          <p>
            Copyright {{ business.name }} 2021. Todos os direitos reservados.
          </p>
        </div>
        <!-- .site-info -->
        <div class="column autor-info">
          <a href="https://paandadesign.com.br/" target="_blank">
            Design por
            <img
              src="https://queroprata.com/institucional/wp-content/themes/base-wp-v2/assets/images/paanda.png?v1"
              alt="paanda"
            />
          </a>
        </div>
        <!-- .site-info -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseFooter',

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    }),

    logo() {
      return this.theme.logo?.default || ''
    },

    externalLinks() {
      const links = this.theme?.externalLinks || {}

      return {
        about: links?.about || {},
        faq: links?.faq || {},
        contact: links?.contact || {},
        institutional_1: links?.institutional_1 || {},
        institutional_2: links?.institutional_2 || {},
        institutional_3: links?.institutional_3 || {},
      }
    },

    business() {
      const business = this.theme?.business || {}

      return {
        name: business?.name || '',
        cnpj: business?.cnpj || '',
        mail: business?.mail || '',
        phoneNumber: business?.phone?.number || '',
        phoneDisplay: business?.phone?.display || '',
        addressStreet: business?.address?.street || '',
        addressNumber: business?.address?.number || '',
        addressNeighborhood: business?.address?.neighborhood || '',
        addressCity: business?.address?.city || '',
        addressState: business?.address?.state || '',
        socialFacebook: business?.social?.facebook || '',
        socialInstagram: business?.social?.instagram || '',
        socialLinkedin: business?.social?.linkedin || '',
      }
    },

    banks() {
      return ['Safra', 'C6']
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 50px 20px 20px;
  background: none;

  &__container {
    position: relative;
    padding-bottom: 50px;

    .column {
      min-height: 100px !important;
    }
  }

  .list-bookmarks {
    position: absolute;
    right: 15px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 769px) {
      right: 0;
      margin-right: -40px;
      display: block;
    }

    .bookmark {
      font-size: 22px;
      color: $color-primary;
      padding: 6px;

      @media (min-width: 769px) {
        font-size: 18px;
      }
    }
  }

  .site-branding {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 20px;

    @media (min-width: 769px) {
      justify-content: center;
    }

    a {
      img {
        max-width: 164px;

        @media (min-width: 769px) {
          max-width: 215px;
        }
      }
    }
  }

  &__submenu {
    display: flex;
  }

  ul {
    li {
      margin-bottom: 2px;
      a {
        font-family: Montserrat-Bold;
        font-size: 12px;
        color: #000;

        &:hover {
          text-decoration: none;
          color: $color-primary;
        }
      }
    }
  }

  &__title {
    h3 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: $color-primary;
      margin-top: 4px;
      margin-bottom: 5px;
    }

    a,
    p {
      font-size: 12px;
      line-height: 1.8;
      color: #000;
      font-family: Montserrat-Bold;
    }

    a:hover {
      text-decoration: none;
      color: $color-primary;
    }

    .phone {
      margin-top: 30px;

      @media (min-width: 769px) {
        margin-top: 15px;
      }
    }
  }

  .footer-cpnj {
    border-top: 2px solid #e0e0e0;
    padding: 25px 0;

    p {
      font-size: 12px;
      font-family: Montserrat-Regular;
      color: $color-secondary;
      text-align: center;
      padding: 0 20px;
    }
  }

  .copyright {
    .site-info {
      p {
        color: #bdbdbd;
        font-size: 11px;
        font-family: Montserrat-Regular;
        text-align: center;

        @media (min-width: 769px) {
          text-align: left;
        }
      }
    }

    .autor-info {
      text-align: center;

      a {
        color: #bdbdbd;
        font-size: 11px;
        font-family: Montserrat-Regular;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 769px) {
          justify-content: flex-end;
        }

        img {
          padding-left: 5px;
        }
      }

      @media (min-width: 769px) {
        text-align: right;
      }
    }
  }
}
</style>
