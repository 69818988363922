<template>
  <div class="fgts">
    <BaseMenu />

    <section class="fgts__header">
      <div class="container">
        <div class="columns fgts__header--columns">
          <div class="column fgts__header--image">
            <img :src="bannerMobile" alt="Banner FGTS" />
          </div>
          <div class="column is-8-desktop">
            <div class="columns">
              <div class="column fgts__header--text is-6">
                <span class="fgts__header--text-light">Viva o presente</span
                ><br />
                <span class="fgts__header--text-medium">e antecipe o</span
                ><br />
                <span class="fgts__header--text-highlight"
                  >FGTS<img :src="dotFgts" />
                </span>
                <div class="column fgts__header--button is-hidden-touch">
                  <BaseButton :link="'contrate'" :text="'CONTRATE AGORA'" />
                </div>
              </div>
              <div class="column fgts__header--topics">
                <BaseTopic :topics="items" />
                <div class="column fgts__header--button is-hidden-desktop">
                  <BaseButton :link="'contrate'" :text="'CONTRATE AGORA'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="information-banner">
      <div class="container">
        <div class="columns">
          <h2 class="column information-banner__title">
            Por que
            <span class="information-banner__title--strong">negativados</span> e
            <span class="information-banner__title--strong">endividados </span>
            <span class="information-banner__title--highlight"
              >podem pedir o empréstimo FGTS?</span
            >
          </h2>
          <div class="column information-banner__button">
            <BaseButton :link="'faq'" :text="'SAIBA MAIS'" />
          </div>
        </div>
      </div>
    </section>

    <section class="withdraw-fgts">
      <div class="container">
        <div class="columns">
          <div class="column withdraw-fgts__text">
            <h2 class="withdraw-fgts__text--title">
              Tire seu FGTS da nuvem, <span>sonho realizado hoje</span>
            </h2>
            <p class="withdraw-fgts__text--subtitle">
              Juros menor, vida Melhor!<br />
              <span>
                Substitua sua dívida: a antecipação do FGTS pode ter juros
                menores.</span
              >
            </p>
            <div class="withdraw-fgts__text--button">
              <BaseButton :link="'faq'" :text="'SAIBA MAIS'" />
            </div>
          </div>
          <div class="column withdraw-fgts__image has-text-centered">
            <img :src="cofre" alt="Empréstimo com FGTS" />
          </div>
        </div>
      </div>
    </section>

    <section class="videos">
      <div class="container">
        <div class="columns videos__columns">
          <div class="column videos__text">
            <h2 class="videos__text--title">
              Veja como é fácil antecipar seu crédito do FGTS<br /><span
                >Siga os 3 passos abaixo</span
              >
            </h2>
          </div>
          <div class="column videos__topics">
            <div class="columns">
              <div class="column videos__topics--item">
                <BaseTopicVideo :topics="itemsVideos" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="videos__topics-desktop">
        <div class="container">
          <div class="columns videos__topics-desktop--items">
            <div class="column is-6 videos__topics-desktop--item">
              <h3><img :src="dotTitle" /> Saque aniversário</h3>
              <div class="videos__topics-desktop--img">
                <img :src="imgFgts" alt="Saque de aniversário" />
                <div
                  class="videos__topics-desktop--mask"
                  @click="
                    openModal('https://www.youtube.com/embed/WGoWqfSHL9c')
                  "
                >
                  <img :src="playButton" />
                </div>
              </div>
            </div>
            <div class="column is-6 videos__topics-desktop--item">
              <h3><img :src="dotTitle" /> Saldo no FGTS</h3>
              <div class="videos__topics-desktop--img">
                <img :src="imgBancoSafra" alt="Saldo do FGTS" />
                <div
                  class="videos__topics-desktop--mask"
                  @click="
                    openModal('https://www.youtube.com/embed/boaNGCxkzHg')
                  "
                >
                  <img :src="playButton" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="faq" class="section-faq">
      <div class="container">
        <h3 class="section-faq__title">Entenda</h3>
        <BaseFaq :items="faq" />
      </div>
    </section>

    <section class="prata-for-me">
      <div class="container">
        <div class="columns">
          <div class="column is-8 is-offset-4 prata-for-me__text">
            <h3>O que o Prata<br /><span>faz por mim?</span></h3>
            <p>
              O Prata é um facilitador na obtenção de crédito. A atuação do
              Prata não encarece a operação, isto significa que
              não ocorre a majoração de juros ou taxas. As condições ofertadas
              pelo Prata são as mesmas oferecidas pelos Bancos.
            </p>
          </div>
        </div>
      </div>
    </section> -->

    <section id="simulator" class="section-simulator">
      <BaseSimulator />
    </section>

    <!-- <section id="correspondentes-bancarios">
      <BaseBannerInformation :is-page="isPage" />
    </section> -->

    <BaseFooter :is-page="isPage" />

    <!-- <BaseModalVideo
      v-show="showModal"
      :linkYoutube="linkVideo"
      @close="showModal = false"
    /> -->
  </div>
</template>

<script>
import BaseMenu from '@/components/BaseMenu.vue'
import BaseTopic from '@/components/BaseTopic.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseSimulator from '@/components/BaseSimulator.vue'
import BaseFooter from '@/components/BaseFooter.vue'

//images
import bannerMobile from '@/assets/img/fgts-lp/banner-mobile.png'
import dotFgts from '@/assets/img/fgts-lp/ponto.png'
import cofre from '@/assets/img/fgts-lp/cofre.png'
import imgFgts from '@/assets/img/fgts-lp/fgts.png'
import imgBancoSafra from '@/assets/img/fgts-lp/banco-safra.png'
import playButton from '@/assets/img/fgts-lp/play-button.png'
import dotTitle from '@/assets/img/dot-title.png'

export default {
  name: 'FgtsLpSimple',

  components: {
    BaseMenu,
    BaseTopic,
    BaseButton,
    BaseSimulator,
    BaseFooter,
  },

  data() {
    return {
      showModal: false,
      linkVideo: null,
      bannerMobile,
      dotFgts,
      cofre,
      dotTitle,
      playButton,
      imgFgts,
      imgBancoSafra,
      items: [
        { number: '01', text: 'Antecipe o FGTS' },
        { number: '02', text: 'Renda mensal preservada' },
        { number: '03', text: 'Não precisa abrir conta corrente nova' },
        { number: '04', text: 'Crédito rápido na sua conta' },
      ],
      itemsVideos: [
        {
          number: '01',
          text: 'Ser optante da modalidade saque aniversário. <span>No vídeo abaixo veja como fazer a migração.</span>',
          image: imgFgts,
          urlVideo: 'https://www.youtube.com/embed/WGoWqfSHL9c',
        },
        {
          number: '02',
          text: 'Autorizar os Bancos: J. Safra e C6 Bank a consultar seu saldo no FGTS. <span>No vídeo abaixo veja como autorizar.</span>',
          image: imgBancoSafra,
          urlVideo: 'https://www.youtube.com/embed/boaNGCxkzHg',
        },
        {
          number: '03',
          text: '<span><b><a href="#simulator">INICIAR CONTRATAÇÃO</a></b></span> pelo simulador e preenchimento do formulário de adesão.',
        },
      ],
      faq: [
        {
          id: '0',
          question: 'O que é o saque-aniversário?',
          answer:
            'O saque-aniversário é uma das hipóteses previstas em Lei para retirada do FGTS (apenas parte). A data de nascimento é a referência temporal para o saque. O valor disponível será proporcional ao total do crédito disponível no FGTS.',
        },
        {
          id: '1',
          question:
            'O que é o Empréstimo com Garantia do Saque Aniversário do FGTS?',
          answer:
            'É uma modalidade de empréstimo que utiliza o saldo do FGTS como garantia do contrato de empréstimo.',
        },
        {
          id: '2',
          question: 'Como são pagas as parcelas?',
          answer:
            'As parcelas são pagas anualmente mediante débito direto na conta vinculada do FGTS, ou seja, o cliente não desembolsa o recurso, o que mantém sua renda mensal intacta.',
        },
        {
          id: '3',
          question: 'Depende de margem consignável?',
          answer:
            'Não. O crédito com garantia do saque-aniversário do FGTS não depende de margem consignável livre e não compromete a contratação de outras linhas de crédito.',
        },
        {
          id: '4',
          question:
            'Por que negativados e endividados podem pedir o empréstimo FGTS?',
          answer:
            'Não há risco na operação. Se você dispõe de saldo no FGTS terá acesso ao crédito, independente de estar com seu nome negativado ou de estar endividado(a). Este é um produto inclusivo que recebe qualquer brasileiro com saldo no FGTS.',
        },
        {
          id: '5',
          question: 'Ainda tem dúvida?',
          answer:
            'Você pode visitar o nosso site. <a href="https://pratadigital.com.br/institucional/perguntas-frequentes/" target="_blank">Clique aqui</a> e veja nossas 21 perguntas e respostas.',
        },
      ],
    }
  },

  computed: {
    pageID() {
      const [domainParts] = window.location.host.split('.')
      return domainParts
    },

    isPage() {
      return {
        fgts: this.pageIdHas('fgts'),
        vehicle: this.pageIdHas('veiculo'),
        person: this.pageIdHas('pessoa'),
        cpr: this.pageIdHas('consig-privado'),
        assistance: this.pageIdHas('auxilio'),
        immobile: this.pageIdHas('imovel'),
      }
    },
  },

  methods: {
    openModal(url) {
      this.linkVideo = url
      this.showModal = true
    },

    pageIdHas(key) {
      return !!~this.pageID.indexOf(key)
    },
  },
}
</script>
