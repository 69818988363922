<template>
  <div id="app">
    <div
      v-if="showWhatsAppChat"
      class="joinchat joinchat--right joinchat--show"
    >
      <div class="joinchat__button" @click="joinchat">
        <div class="joinchat__button__open" />
        <div class="joinchat__button__sendtext">Open chat</div>
      </div>
      <svg height="0" width="0">
        <defs>
          <clipPath id="joinchat__message__peak">
            <path
              d="M17 25V0C17 12.877 6.082 14.9 1.031 15.91c-1.559.31-1.179 2.272.004 2.272C9.609 18.182 17 18.088 17 25z"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <router-view />
  </div>
</template>

<script>
import themeMixin from '@/mixins/themeMixin'

export default {
  name: 'App',

  mixins: [themeMixin],

  computed: {
    businessName() {
      return this.theme?.business?.name || ''
    },

    pageID() {
      const domainParts = window.location.host.split('.')
      return domainParts[0]
    },

    isContract() {
      return ~this.$router.currentRoute.path.indexOf('-agora')
    },

    isValidation() {
      return ~this.$router.currentRoute.path.indexOf('validacao')
    },

    showWhatsAppChat() {
      return !(this.isContract || this.isValidation)
    },
  },

  methods: {
    joinchat() {
      this.$gtm.trackEvent({ event: 'whatsapp' })
      let strMessage = `Olá *${this.businessName}*! Eu gostaria de ter mais informações sobre FGTS (LP)`

      if (this.$route.query.utm_campaign) {
        var utm_campaign = this.$route.query.utm_campaign
        var arrParts = utm_campaign.split('-')
        var strInfluencer = arrParts.slice(-1).pop()

        strInfluencer =
          strInfluencer.charAt(0).toUpperCase() + strInfluencer.slice(1)
        strMessage = `Olá *${this.businessName}*! ${strInfluencer} falou de vocês, quero saber mais!`
      }
      window.location.href =
        'http://api.whatsapp.com/send?phone=+552139422489&text=' + strMessage
    },

    appendOctaDeskChat() {
      const script = document.createElement('script')
      script.innerHTML = (function (o, c, t, a, d, e, s, k) {
        o.octadesk = o.octadesk || {}
        s = c.getElementsByTagName('body')[0]
        k = c.createElement('script')
        k.async = 1
        k.src = t + '/' + a + '?showButton=' + d + '&openOnMessage=' + e
        s.appendChild(k)
      })(
        window,
        document,
        'https://chat.octadesk.services/api/widget',
        'pratadigital',
        true,
        true
      )
      document.body.appendChild(script)
    },
  },
}
</script>

<style>
@import 'https://pratadigital.com.br/institucional/wp-content/plugins/creame-whatsapp-me/public/css/joinchat.min.css?ver=4.1.14';

html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
}

section {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
