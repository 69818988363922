/* eslint-disable prettier/prettier */
import Base from './Base'

export default class Validation extends Base {
  constructor() {
    super()
    this.baseURL = '/anti-fraud'
  }

  async login(data) {
    return this.api
      .post(`${this.baseURL}/proposal-verification`, data)
      .then(({ data }) => data)
  }

  async postDocs(data) {
    return this.api
      .post(`${this.baseURL}/document-verification`, data)
      .then(({ data }) => data)
  }

  async postSelfie(data) {
    return this.api
      .post(`${this.baseURL}/selfie`, data)
      .then(({ data }) => data)
  }
  
  async check(data) {
    this.api.post(`${this.baseURL}/check`, data)
  }
}
