var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-document"},[_c('div',{staticClass:"columns is-multiline"},[_vm._m(0),_c('div',{staticClass:"column"},[_c('div',{staticClass:"document"},[(!_vm.isPage.assistance)?_c('div',{staticClass:"document__radio"},[_vm._m(1),_vm._l((_vm.documentTypeOptions),function(option,index){return _c('label',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.documentType),expression:"documentType"}],attrs:{"type":"radio"},domProps:{"value":option,"checked":_vm._q(_vm.documentType,option)},on:{"click":function($event){_vm.isOtherDocumentType = false},"change":function($event){_vm.documentType=option}}}),_vm._v(" "+_vm._s(option)+" ")])})],2):_vm._e(),_c('base-input',{staticClass:"document-number",attrs:{"value":_vm.document,"has-error":_vm.validation.hasError('document') || _vm.document_error ? true : false,"first-error":_vm.validation.firstError('document') || _vm.document_error,"is-passed":_vm.validation.isPassed('document') && !_vm.document_error,"type":"tel"},on:{"input":function($event){_vm.document = $event}}},[_vm._v(" Número do documento "),_c('span',{staticClass:"document--span"},[_vm._v("*")])]),(!_vm.isPage.assistance)?[_c('base-input',{staticClass:"document-number",attrs:{"value":_vm.document_issued_at,"has-error":_vm.validation.hasError('document_issued_at') ||
              _vm.document_issued_at_error
                ? true
                : false,"first-error":_vm.validation.firstError('document_issued_at') ||
              _vm.document_issued_at_error,"is-passed":_vm.validation.isPassed('document_issued_at') &&
              !_vm.document_issued_at_error,"type":'tel',"format":'##/##/####'},on:{"input":function($event){_vm.document_issued_at = $event}}},[_vm._v(" Data de expedição do documento "),_c('span',{staticClass:"document--span"},[_vm._v("*")])]),_c('small',{staticStyle:{"font-size":"0.8rem"}},[_vm._v("(Dia/Mês/Ano)")]),_c('p',{staticClass:"control document__document-local document-number"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.local),expression:"local"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.local=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("ESCOLHER")]),_vm._l((_vm.states),function(state,index){return _c('option',{key:index,attrs:{"required":true},domProps:{"value":state.value}},[_vm._v(" "+_vm._s(state.name)+" ")])})],2),_vm._m(2),_c('i',{staticClass:"bar"})])]:_vm._e(),_c('base-input',{attrs:{"value":_vm.mother_name,"has-error":_vm.validation.hasError('mother_name') || _vm.mother_name_error
              ? true
              : false,"first-error":_vm.validation.firstError('mother_name') || _vm.mother_name_error,"is-passed":_vm.validation.isPassed('mother_name') && !_vm.mother_name_error},on:{"input":function($event){_vm.mother_name = $event}}},[_vm._v(" Nome de mãe "),_c('span',{staticClass:"document--span"},[_vm._v("*")])])],2)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"document"},[(_vm.isPage.vehicle || _vm.isPage.immobile)?_c('base-input',{staticClass:"document-number",attrs:{"value":_vm.cnpj,"format":'##.###.###/####-##',"type":"tel"},on:{"input":function($event){_vm.cnpj = $event}}},[_vm._v(" CNPJ (se possuir % societária) ")]):_vm._e(),(!_vm.isPage.assistance)?_c('div',{staticClass:"document__radio"},[_vm._m(3),_vm._l((_vm.maritalStatusOptions),function(option,index){return _c('label',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.maritalStatus),expression:"maritalStatus"}],attrs:{"type":"radio"},domProps:{"value":option,"checked":_vm._q(_vm.maritalStatus,option)},on:{"click":function($event){_vm.isOthermaritalStatus = false},"change":function($event){_vm.maritalStatus=option}}}),_vm._v(" "+_vm._s(option)+" ")])})],2):_vm._e(),(_vm.maritalStatus == 'Casado(a)')?_c('base-input',{attrs:{"value":_vm.spouse_name,"has-error":_vm.validation.hasError('spouse_name') || _vm.spouse_name_error
              ? true
              : false,"first-error":_vm.validation.firstError('spouse_name') || _vm.spouse_name_error,"is-passed":_vm.validation.isPassed('spouse_name') && !_vm.spouse_name_error},on:{"input":function($event){_vm.spouse_name = $event}}},[_vm._v(" Nome da(o) esposa(o) "),_c('span',{staticClass:"document--span"},[_vm._v("*")])]):_vm._e(),(_vm.maritalStatus == 'Casado(a)')?_c('base-input',{staticClass:"document-number",attrs:{"value":_vm.spouse_birthdate,"has-error":_vm.validation.hasError('spouse_birthdate') || _vm.spouse_birthdate_error
              ? true
              : false,"first-error":_vm.validation.firstError('spouse_birthdate') ||
            _vm.spouse_birthdate_error,"is-passed":_vm.validation.isPassed('spouse_birthdate') && !_vm.spouse_birthdate_error,"type":'tel',"format":'##/##/####'},on:{"input":function($event){_vm.spouse_birthdate = $event}}},[_vm._v(" Data de nascimento da(o) esposa(o) "),_c('span',{staticClass:"document--span"},[_vm._v("*")])]):_vm._e(),(_vm.isPage.cpr)?_c('div',{staticClass:"document__radio"},[_vm._m(4),_vm._l((_vm.alimonyOptions),function(option,index){return _c('label',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.alimony),expression:"alimony"}],attrs:{"type":"radio"},domProps:{"value":option,"checked":_vm._q(_vm.alimony,option)},on:{"change":function($event){_vm.alimony=option}}}),_vm._v(" "+_vm._s(option)+" ")])})],2):_vm._e(),(_vm.isPage.cpr)?_c('base-input',{staticClass:"document-number",attrs:{"value":_vm.dependents,"has-error":_vm.validation.hasError('dependents') || _vm.dependents_error
              ? true
              : false,"first-error":_vm.validation.firstError('dependents') || _vm.dependents_error,"is-passed":_vm.validation.isPassed('dependents') && !_vm.dependents_error,"type":'tel'},on:{"input":function($event){_vm.dependents = $event}}},[_vm._v(" Quantos dependentes possui? "),_c('span',{staticClass:"document--span"},[_vm._v("*")])]):_vm._e()],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"address"},[_c('div',{staticClass:"form-contract__button-next has-text-right"},[_c('button',{attrs:{"disabled":!_vm.formIsValidated || _vm.actionLoading,"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" PRÓXIMO ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"form-contract__title"},[_c('h2',[_c('span',[_vm._v("Documento")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Documento "),_c('span',{staticClass:"document--span"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"label"},[_vm._v(" Estado de expedição do documento "),_c('span',{staticClass:"document--span"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Estado civil "),_c('span',{staticClass:"document--span"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Paga pensão alimentícia? "),_c('span',{staticClass:"document--span"},[_vm._v("*")])])
}]

export { render, staticRenderFns }