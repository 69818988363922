export default {
  SET_STATUS({ commit }, payload) {
    commit('SET_STATUS', payload)
  },

  SET_AMOUNT({ commit }, payload) {
    commit('SET_AMOUNT', payload)
  },

  SET_DOCUMENT({ commit }, payload) {
    commit('SET_DOCUMENT', payload)
  },
}
