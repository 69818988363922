<template>
  <div>
    <base-menu :contract-now="true" />

    <section class="fgts__header">
      <div class="container">
        <div class="columns fgts__header--columns">
          <div class="column fgts__header--image">
            <img :src="images.banner" alt="Banner FGTS" />
          </div>

          <div class="column is-8-desktop">
            <div class="columns">
              <div class="column fgts__header--text is-6">
                <p class="is-grid">
                  <span class="fgts__header--text-light">Viva o presente</span>
                  <span class="fgts__header--text-medium">e antecipe o</span>
                  <span class="fgts__header--text-highlight">FGTS</span>
                </p>
              </div>

              <div class="column fgts__header--topics">
                <p class="text">Saiba quanto $$$ você pode receber em 24h!</p>

                <div class="form-control">
                  <base-input
                    v-model="form.document"
                    :has-error="hasError"
                    :is-passed="hasPassed"
                    :first-error="hasFirstError"
                    class="my-0"
                    type="tel"
                    format="###.###.###-##"
                  >
                    Digite seu CPF <span class="document--span">*</span>
                  </base-input>
                </div>

                <div class="fgts__header--button">
                  <button class="btn" @click="consult">Consultar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <base-footer :is-page="isPage" />

    <base-modal
      v-if="modal.show"
      :title="modal.title"
      :has-btn-secondary="modal.btn.close"
      :has-btn-primary="modal.btn.submit"
      :message-error="modal.error"
      btn-secondary="Fechar"
      btn-primary="Contratar"
      @close="close"
      @click-secondary="close"
      @click-primary="submit"
    >
      <template #body>
        <p v-if="modal.alert" class="has-text-danger">
          {{ modal.alert }}
        </p>
        <info-balance
          v-else-if="!modal.error"
          :document-number="form.document"
          @message="modal.error = $event"
          @status="handleStatus"
        />
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Validator from 'simple-vue-validator'

import BaseMenu from '@/components/BaseMenu'
import BaseInput from '@/components/BaseInput'
import BaseFooter from '@/components/BaseFooter.vue'
import BaseModal from '@/components/BaseModal.vue'
import InfoBalance from '@/components/InfoBalance.vue'

const validator = Validator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'ConsultView',

  components: {
    BaseMenu,
    BaseInput,
    BaseFooter,
    BaseModal,
    InfoBalance,
  },

  mixins: [Validator.mixin],

  validators: {
    'form.document'(value) {
      return validator
        .value(value)
        .required()
        .minLength(14, 'Não foi possível validar seu CPF')
        .maxLength(14, 'Não foi possível validar seu CPF')
    },
  },

  data: () => ({
    form: {
      document: '',
    },
    images: {
      banner: require('@/assets/img/fgts-lp/banner-mobile.png'),
      dot: require('@/assets/img/fgts-lp/ponto.png'),
    },
    list: {
      items: [
        { number: '01', text: 'Antecipe o FGTS' },
        { number: '02', text: 'Renda mensal preservada' },
        { number: '03', text: 'Não precisa abrir conta corrente nova' },
        { number: '04', text: 'Crédito rápido na sua conta' },
      ],
    },
    modal: {
      show: false,
      title: '',
      alert: '',
      error: '',
      btn: {
        close: false,
        submit: false,
      },
    },
  }),

  computed: {
    isValidDocument() {
      return this.validation.isPassed('form.document')
    },

    hasPassed() {
      return this.validation.isPassed('form.document')
    },

    hasError() {
      return this.validation.hasError('form.document')
    },

    hasFirstError() {
      return this.validation.firstError('form.document')
    },

    pageID() {
      const [domainParts] = window.location.host.split('.')
      return domainParts
    },

    isPage() {
      return {
        fgts: this.pageIdHas('fgts'),
        vehicle: this.pageIdHas('veiculo'),
        person: this.pageIdHas('pessoa'),
        cpr: this.pageIdHas('consig-privado'),
        assistance: this.pageIdHas('auxilio'),
        immobile: this.pageIdHas('imovel'),
      }
    },
  },

  methods: {
    ...mapActions({
      setStatus: 'balance/SET_STATUS',
      setAmount: 'balance/SET_AMOUNT',
      setDocument: 'balance/SET_DOCUMENT',
    }),

    pageIdHas(key) {
      return !!~this.pageID.indexOf(key)
    },

    close() {
      this.modal.show = false
    },

    submit() {
      this.$router.push({ path: '/contrate-agora' })
    },

    handleStatus(data) {
      const { amount, status } = data

      if (!status) return

      this.setStatus(status)
      this.setAmount(amount)
      this.setDocument(this.form.document)

      if (status === 'Digitar') {
        this.modal.btn.close = true
        this.modal.btn.submit = true
      } else {
        this.modal.btn.close = true
        this.modal.btn.submit = false
      }
    },

    resetModal() {
      this.modal.error = ''
      this.modal.alert = ''
      this.modal.btn.close = false
      this.modal.btn.submit = false
    },

    consult() {
      this.resetModal()
      this.modal.show = true

      if (this.isValidDocument) {
        this.modal.title = 'Informações da consulta'
      } else {
        this.modal.title = 'Atenção'
        this.modal.alert = this.hasFirstError ?? 'CPF precisa ser preenchido'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text {
  margin: {
    top: 0;
    bottom: 5rem;
  }

  font: {
    family: 'Montserrat-SemiBold', sans-serif;
    size: 18px;
  }
  color: $color-secondary;
  text-align: center;

  @media (min-width: 640px) {
    text-align: left;
  }
}

.control {
  margin: 40px 0 30px;

  &::v-deep {
    input {
      height: 2rem;
      padding: 0;

      font-size: 17px;

      &:valid ~ .label {
        top: -2rem;
        margin: 0;

        padding: 0;

        font-size: 18px;
        color: $color-secondary;
      }
    }
  }
}

.btn {
  margin: 3rem auto 0;

  display: flex;
  background-color: $color-primary;
  border: 2px solid $color-primary;
  border-radius: 10px;
  padding: 20px 40px;

  font: {
    family: 'Montserrat-SemiBold', sans-serif;
    size: 18px;
    weight: bold;
  }
  text: {
    transform: uppercase;
    align: center;
  }
  line-height: 1;
  color: #fff;

  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(#fff, 0);
    color: $color-primary;
  }
}

.fgts {
  &__header {
    @media (min-width: 1024px) {
      background: {
        position: -5vw center;
      }
    }

    .is-grid {
      display: grid;
    }

    &--image {
      @media (max-width: 640px) {
        height: 25vh;

        img {
          margin: 0 auto;

          display: block;
          width: auto;
          height: 100%;
        }
      }
    }

    &--topics,
    &--text {
      padding: 1.5rem 3rem !important;

      @media (min-width: 640px) {
        padding: 5rem 3rem !important;
      }
    }

    &--text {
      font-size: 4rem;

      @media (min-width: 640px) {
        font-size: 6rem;
      }

      @media (min-width: 1190px) {
        font-size: 7rem;
      }
    }

    &--text-light {
      font-size: 41%;
      line-height: 100%;
    }

    &--text-medium {
      font-size: 48%;
      line-height: 100%;
    }

    &--text-highlight {
      position: relative;

      display: inline-block;

      font-size: 120%;
      line-height: 100%;

      &::after {
        $size: 15px;

        content: '';

        position: absolute;
        bottom: $size;
        right: $size * (-2);

        display: block;
        width: $size;
        height: $size;
        background-color: $color-primary;
      }
    }
  }
}
</style>
