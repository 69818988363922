<template>
  <div :class="{ loading }" class="validation-documents is--full">
    <validation-header :current="1">Dados pessoais</validation-header>

    <div class="validation-documents__container slide-in">
      <div class="validation-documents__content">
        <fieldset v-for="({ title, arr }, i) in data" :key="i" class="fieldset">
          <legend class="fieldset__legend">{{ title }}</legend>
          <template v-for="({ label, value }, j) in arr">
            <template v-if="value">
              <div :key="j" class="control">
                <label>{{ label }}</label>
                <span class="input">{{ value }}</span>
                <i class="bar" />
              </div>
            </template>
          </template>
        </fieldset>
      </div>

      <div class="validation-documents__ctrl">
        <button class="button btn" @click="nextStep">
          <span>Continuar</span>
          <span class="icon">
            <i class="fas fa-arrow-right" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationHeader from './Header.vue'

import checkMobileCompatibility from '@/mixins/checkMobileCompatibility'
import checkAccountID from '@/mixins/checkAccountID'

export default {
  name: 'ValidationDocuments',

  components: {
    ValidationHeader,
  },

  mixins: [checkMobileCompatibility, checkAccountID],

  data: () => ({
    loading: false,
  }),

  computed: {
    basic() {
      const { name, document, ddd, phone, gender, birthdate, email } =
        this.account

      return [
        { label: 'Nome', value: name },
        { label: 'Documento', value: document },
        { label: 'DDD', value: ddd },
        { label: 'Telefone', value: phone },
        { label: 'Gênero', value: gender },
        { label: 'Data Nasc.', value: birthdate },
        // { label: 'E-mail', value: email },
      ]
    },

    docs() {
      const {
        document_type,
        document_number,
        document_issued_by,
        document_issued_at,
        document_state,
        marital_status,
        alimony,
        dependents,
        spouse_name,
        spouse_document,
        spouse_document_state,
        spouse_birthdate,
        father_name,
        mother_name,
      } = this.account

      return [
        { label: 'Documento', value: document_type },
        { label: 'Número', value: document_number },
        { label: 'Órgão emissor', value: document_issued_by },
        { label: 'Data de expedição', value: document_issued_at },
        { label: 'Estado de expedição', value: document_state },
        { label: 'Estado civil', value: marital_status },
        { label: 'Nome da(o) esposa(o)', value: spouse_name },
        { label: 'CPF da(o) esposa(o)', value: spouse_document },
        {
          label: 'Estado do documento da(o) esposa(o)',
          value: spouse_document_state,
        },
        {
          label: 'Data de nascimento da(o) esposa(o)',
          value: spouse_birthdate,
        },
        { label: 'Nome do pai', value: father_name },
        { label: 'Nome de mãe', value: mother_name },
        { label: 'Dependentes', value: dependents },
        { label: 'Pensão alimentícia', value: alimony },
      ]
    },

    address() {
      const { zipcode, street, number, neighborhood, city, state, unit } =
        this.account

      return [
        { label: 'CEP', value: zipcode },
        { label: 'Rua', value: street },
        { label: 'Número', value: number },
        { label: 'Bairro', value: neighborhood },
        { label: 'Cidade', value: city },
        { label: 'Estado', value: state },
        { label: 'Complemento', value: unit },
      ]
    },

    professional() {
      const { occupation, salary, admitted_at } = this.account

      return [
        { label: 'Profissão', value: occupation },
        { label: 'Renda mensal', value: salary },
        { label: 'Data de admissão', value: admitted_at },
      ]
    },

    vehicle() {
      const { type, brand, model, year, price, license } = this.account

      return [
        { label: 'Tipo', value: type },
        { label: 'Marca', value: brand },
        { label: 'Modelo', value: model },
        { label: 'Ano', value: year },
        { label: 'Preço', value: price },
        { label: 'Placa', value: license },
      ]
    },

    financial() {
      const {
        patrimony,
        wish_amount,
        bank,
        bank_code,
        account_type,
        branch_number,
        account_number,
      } = this.account

      return [
        { label: 'Código do banco', value: bank_code },
        { label: 'Banco', value: bank },
        { label: 'Tipo de conta', value: account_type },
        { label: 'Agência', value: branch_number },
        { label: 'Número da conta', value: account_number },
        { label: 'Patrimônio', value: patrimony },
        { label: 'Valor esperado', value: wish_amount },
      ]
    },

    data() {
      return [
        { title: 'Dados básicos', arr: this.basic },
        { title: 'Documentos', arr: this.docs },
        { title: 'Endereço', arr: this.address },
        { title: 'Profissional', arr: this.professional },
        { title: 'Veículo', arr: this.vehicle },
        { title: 'Financeiro', arr: this.financial },
      ]
    },
  },

  methods: {
    nextStep() {
      const name = 'validation-consult'
      const type = ''
      const side = ''
      const mode = ''

      this.$router.push({ name, params: { type, side, mode } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

.validation-documents {
  @include base();
}

div.control {
  margin-bottom: 0;

  display: grid;
  width: 100%;

  grid-template-columns: 1fr;

  &:first-child {
    margin-top: 0;
  }

  + div.control {
    margin-top: 30px;
  }

  label {
    font-size: 0.8rem;
    color: #616161;
  }

  span {
    display: block;
    border: none;
    height: auto;
    box-shadow: none;
    padding: 0.5em 0.125rem 0.75em;
    background-color: rgba(#000, 0);

    color: $color-secondary;
    line-height: 1em;
  }

  .input {
    &:hover,
    &:focus {
      border-color: #dbdbdb;
    }
  }
}

.fieldset {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__legend {
    color: $color-primary;
    font-weight: bold;
  }
}
</style>
