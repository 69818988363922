import HomeSimple from '@/views/FgtsLpSimple.vue'

const path = '/simule'
const name = 'fgts-lp'
const component = HomeSimple

export default [
  {
    path,
    name,
    component,
  },
]
