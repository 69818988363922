<template>
  <div class="form-info-career">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="form-contract__title">
          <h2><span>Informações Profissionais</span></h2>
        </div>
      </div>
      <div class="column is-6">
        <div class="info-career">
          <base-input :value="career" @input="career = $event">
            Qual sua profissão?
            <span class="info-career--span">*</span>
          </base-input>
          <small style="font-size: 0.8rem">
            (Analista, Auxiliar, Assistente, Secretária, Desempregado, etc)
          </small>

          <div class="control has-icons has-icons-right">
            <input
              v-model.lazy="salary"
              v-money="money"
              class="control control-lg control-input"
              type="text"
              name="salary"
            />
            <label class="label">
              O valor
              {{
                isPage.cpr ? ' do seu sálário base?' : ' da sua renda mensal?'
              }}
              <span class="info-career--span">*</span>
            </label>
            <i class="bar" />
          </div>

          <template v-if="isPage.cpr">
            <base-input
              :value="admitted_at"
              :has-error="
                validation.hasError('admitted_at') || admitted_at_error
                  ? true
                  : false
              "
              :first-error="
                validation.firstError('admitted_at') || admitted_at_error
              "
              :is-passed="
                validation.isPassed('admitted_at') && !admitted_at_error
              "
              :type="'tel'"
              :format="'##/##/####'"
              @input="admitted_at = $event"
            >
              Em qual data você foi contratado?
              <span class="info-basic--span">*</span>
            </base-input>
            <small style="font-size: 0.8rem">
              OBS: Caso esteja desempregado, informe a data do seu último
              emprego
            </small>
          </template>

          <base-upload v-if="isPage.person" @file="file = $event">
            <span>Comprovante de renda</span>
            <span class="info-career--span">*</span>
          </base-upload>
        </div>
      </div>
    </div>
    <div class="columns">
      <!-- <div class="column is-6">
        <div class="info-career">
          <div class="form-contract__button-back">
            <button @click="backStep">VOLTAR</button>
          </div>
        </div>
      </div> -->

      <div class="column is-6">
        <div class="info-career">
          <div class="form-contract__button-next has-text-right">
            <button
              :disabled="!formIsValidated || actionLoading"
              type="submit"
              @click="submit()"
            >
              PRÓXIMO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { VMoney } from 'v-money'
import SimpleVueValidator from 'simple-vue-validator'

import BaseInput from '@/components/BaseInput.vue'
import BaseUpload from '@/components/BaseUpload.vue'

import FormContractApi from '@/lib/api/FGTS'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'InfoProfessional',

  components: {
    BaseInput,
    BaseUpload,
  },

  directives: { money: VMoney },

  mixins: [SimpleVueValidator.mixin],

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  validators: {
    // career(value) {
    //   return Validator.value(value).required()
    // },
    file(value) {
      return Validator.value(value).required()
    },
    admitted_at(value) {
      return Validator.value(value)
        .required()
        .regex(
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
          'Insira uma data válida'
        )
    },
  },

  data() {
    return {
      career: '',
      career_error: '',
      salary: '',
      salary_error: '',
      admitted_at: '',
      admitted_at_error: '',
      // occupations: null,
      file: '',
      actionLoading: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
    }
  },

  computed: {
    ...mapState({
      account: (state) => state.fgts.account,
    }),

    formIsValidated() {
      const salary = parseInt((this.salary || '').replace(/\D/g, '') || 0)

      return (
        !!salary &&
        (this.isPage.person ? this.validation.isPassed('file') : true) &&
        (this.isPage.cpr ? this.validation.isPassed('admitted_at') : true)
        // this.validation.isPassed('career') &&
      )
    },
  },

  methods: {
    backStep() {
      this.$emit('nextStep', -1)
    },

    async submit() {
      const formContractApi = new FormContractApi()

      this.actionLoading = true
      this.$emit('loading', true)

      const data = {
        account_id: this.account.id,
        occupation: this.career,
        role: this.career,
        salary: this.salary,
        ...(this.isPage.person ? { proof_of_income: this.file } : ''),
        ...(this.isPage.cpr ? { admitted_at: this.admitted_at } : ''),
      }

      formContractApi
        .formContractProfessional(data)
        .then(() => {
          this.$gtm.trackEvent({
            event: 'contract-info-professional',
            account_id: this.account.id,
          })

          this.$emit('nextStep')
        })
        .catch((err) => {
          const error = {
            message:
              err?.response?.data?.error?.message ||
              err?.error?.response?.data?.error?.message ||
              err?.message ||
              err,
            others: [],
          }

          if (err?.response?.data?.error?.errors) {
            const entries = Object.entries(err?.response?.data?.error?.errors)
            for (const [entry, values] of entries) {
              for (const [key, value] of Object.entries(values)) {
                error.others.push({ entry, value })
              }
            }
          }

          this.$emit('message', error)
        })
        .finally(() => {
          const el = document.querySelector('.form-contract__step-by-step')
          el.scrollIntoView()

          this.actionLoading = false
          this.$emit('loading', false)
        })
    },
  },
}
</script>

<style lang="scss">
.form-info-career {
  .column {
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      padding: auto;
    }
  }
}

.info-career {
  max-width: 80%;
  margin: 0 auto;

  .control {
    margin-top: 3.25rem;
  }

  .info-career-number {
    margin-top: 3.5rem;

    label,
    select {
      top: -1.5rem !important;
    }
  }

  &--span {
    color: $color-primary;
  }

  &__radio {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    display: flex;
    flex-direction: column;

    p,
    label {
      font-size: 0.8rem;
      color: #616161;
      top: -1rem;
      left: 0;
      margin-bottom: 6px;
    }

    &--other {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      input {
        margin: 0;
        margin-right: 5px;
        margin-top: 4px;
      }

      .control {
        margin: -5px;
        width: 100%;
        padding-left: 10px;

        input {
          width: 100%;
        }
      }
    }
  }
}
</style>
