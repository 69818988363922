<template>
  <div>
    <div v-for="topic in topics" :key="topic.number" class="b-topic">
      <div class="b-topic__number">
        <p>{{ topic.number }}.</p>
      </div>
      <div class="b-topic__text">{{ topic.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTopic',

  props: {
    topics: {
      type: Array,
      require: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.b-topic {
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  align-items: flex-start;
  margin: 20px 0;

  &__number {
    background-color: $color-secondary;
    max-width: 92px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.8;

    p {
      color: #ffffff;
      font-size: 46px;
      font-family: Montserrat-Bold;
    }
  }

  &__text {
    color: $color-secondary;
    font-size: 26px;
    font-family: Montserrat-Bold;
    flex: 2;
    line-height: 1;
    padding-left: 30px;
  }
}
</style>
