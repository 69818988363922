<template>
  <div :class="styleButton" @click="goTo(link)">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'BaseButton',

  props: {
    styleButton: {
      type: String,
      default: 'ghost',
    },

    text: {
      type: String,
      required: true,
    },

    link: {
      type: String,
      default: '',
      required: false,
    },
  },

  methods: {
    goTo(refName) {
      if (refName === 'contrate') {
        this.$gtm.trackEvent({ event: 'contract' })
        let strUrl = ''

        switch (this.$router.currentRoute.path) {
          case '/supersim':
            strUrl = '/supersim-contrate-agora?'
            break
          case '/e-contrate':
            strUrl = '/e-contrate-agora?'
            break
          case '/c-contrate':
            strUrl = '/c-contrate-agora?'
            break
          default:
            strUrl = '/contrate-agora?'
            break
        }

        if (this.$route.query.utm_source) {
          strUrl += 'utm_source=' + this.$route.query.utm_source + '&'
        }
        if (this.$route.query.utm_medium) {
          strUrl += 'utm_medium=' + this.$route.query.utm_medium + '&'
        }
        if (this.$route.query.utm_campaign) {
          strUrl += 'utm_campaign=' + this.$route.query.utm_campaign + '&'
        }

        window.location.href = strUrl
      } else if (refName === 'faq') {
        for (var answerId = 0; answerId <= 5; answerId++) {
          document.getElementById(`answer${answerId}`).style.display = 'none'
          document.getElementById(`img-question-${answerId}`).innerHTML = '+'

          if (answerId === 4) {
            document.getElementById(`answer4`).style.display = 'flex'
            document.getElementById(`img-question-4`).innerHTML = '-'
          }
        }

        var result = document.getElementById(refName)
        result.scrollIntoView()
      } else {
        var result = document.getElementById(refName)
        result.scrollIntoView()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ghost {
  cursor: pointer;
  font-family: Montserrat-Regular;
  border: 2px solid $color-primary;
  color: $color-primary;
  font-size: 18px;
  border-radius: 10px;
  padding: 20px 40px 18px;
  text-align: center;
  line-height: 1;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: $color-primary;
    color: #ffffff;
  }
}
</style>
