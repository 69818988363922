import Login from '@/components/validation/Login.vue'
import Documents from '@/components/validation/Documents.vue'
import PhotoSelfie from '@/components/validation/PhotoSelfie.vue'
import PhotoType from '@/components/validation/PhotoType.vue'
import PhotoDocs from '@/components/validation/PhotoDocs.vue'
import Consult from '@/components/validation/Consult.vue'
import Final from '@/components/validation/Final.vue'

const path = '/validacao'
const name = 'validation'

export default [
  {
    path: `${path}/:token`,
    name: `${name}-login`,
    component: Login,
  },
  {
    path: `${path}/:token/dados-pessoais`,
    name: `${name}-documents`,
    component: Documents,
  },
  {
    path: `${path}/:token/foto-documento/selfie`,
    name: `${name}-photo-selfie`,
    component: PhotoSelfie,
  },
  {
    path: `${path}/:token/foto-documento`,
    name: `${name}-photo-type`,
    component: PhotoType,
  },
  {
    path: `${path}/:token/foto-documento/:type/:side`,
    name: `${name}-photo-docs`,
    props: (route) => {
      const { type, side } = route.params
      return { docType: type, docSide: side }
    },
    component: PhotoDocs,
  },
  {
    path: `${path}/:token/confirmar`,
    name: `${name}-consult`,
    component: Consult,
  },
  {
    path: `${path}/:token/sucesso`,
    name: `${name}-final`,
    component: Final,
    props: true,
  },
]

/**
 * Fluxo
 * - Login
 * - Dados Pessoais
 * - Confirmar
 * - Foto Selfie
 * - Tipo de Documento
 * - Foto Documento
 * - Sucesso
 */
