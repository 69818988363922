<template>
  <div
    :class="{ hasAllPermissions: !message.error && hasAllPermissions }"
    class="validation-photo is--full"
  >
    <validation-header
      v-show="showTitle"
      ref="header"
      class="validation-photo__header"
      :current="3"
    >
      Foto de você
      <span class="type">(SELFIE)</span>
    </validation-header>

    <div v-if="message.error" class="alert">
      <p class="has-text-danger has-background-danger-light p-3">
        {{ message.text }}
      </p>
    </div>

    <div ref="container" class="validation-photo__container slide-in">
      <div
        ref="content"
        :class="{ expanded: config.expanded, hasPhotoLoaded }"
        class="validation-photo__content"
      >
        <i
          v-if="!message.error"
          :class="[config.expanded ? 'fa-times' : 'fa-question']"
          class="fas"
          @click="config.expanded = !config.expanded"
        />

        <div v-show="!config.expanded" key="image" class="photo">
          <span v-show="!hasPhotoLoaded && !config.loading">
            sem foto carregada
          </span>

          <span v-if="config.loading" class="icon spinner">
            <i class="fas fa-circle-notch" />
          </span>

          <div v-show="hasPhotoLoaded" ref="img" class="camera camera--img">
            <img :src="form.img" alt="selfie" @load="handleImageLoaded" />
          </div>

          <div
            id="box-camera"
            ref="canvas"
            :class="{ hasPhotoLoaded }"
            class="camera--canvas"
          />

          <p v-show="config.showInfo" class="info">
            <span>isLoading: {{ config.loading }}</span>
            <span>hasPhotoLoaded {{ hasPhotoLoaded }}</span>
            <span>longitude: {{ form.location.longitude }}</span>
            <span>latitude: {{ form.location.latitude }}</span>
          </p>
        </div>

        <div
          v-show="showTutorial"
          key="tutorial"
          class="validation-photo__tutorial"
        >
          <template v-if="message.error">
            <p>
              <strong>Ocorreu um erro na solicitação de recursos</strong>
            </p>
            <ul class="list error">
              <li>É preciso garantir permissão de acesso à câmera</li>
              <li>
                Feche outras aplicações que possam estar utilizando a câmera
              </li>
              <li>
                Dispositivos podem, momentaneamente, falhar em liberar o acesso
                à câmera. Tente novamente em instantes
              </li>
            </ul>
          </template>
          <template v-else>
            <p>
              <strong>Cuidados para tirar a sua foto corretamente</strong>
            </p>
            <ul class="list">
              <template v-if="!sdk.camera">
                <li>Permitir acesso a câmera e localização</li>
              </template>
              <li>A foto deve ser tirada de frente</li>
              <li>O rosto deve estar centralizado</li>
              <li>Os olhos devem estar abertos</li>
              <li>Sem óculos, chapéu ou boné</li>
              <li>Fisionomia neutra</li>
              <li>Deve estar olhando diretamente para a câmera</li>
            </ul>
          </template>
        </div>
      </div>

      <div class="validation-photo__ctrl isSelfie">
        <button
          v-if="message.error"
          key="btn-go-home"
          class="button btn"
          @click="retry"
        >
          <span>Tentar novamente</span>
          <span class="icon">
            <i class="fas fa-undo" />
          </span>
        </button>

        <button
          v-else-if="showPermissionBtn"
          key="btn-allow"
          class="button btn"
          @click="loadSDK"
        >
          <span>Permitir</span>
          <span class="icon">
            <i class="fas fa-shield-alt" />
          </span>
        </button>

        <template v-else-if="!config.expanded">
          <span v-if="hasPhotoLoaded">
            <button
              class="button btn is-secondary"
              @click="resetImage({ toPlay: true })"
            >
              <i class="fas fa-undo" />
            </button>

            <button
              class="button btn"
              :disabled="config.loading"
              @click="submit"
            >
              <span>Salvar</span>
              <span class="icon">
                <i class="fas fa-save" />
              </span>
            </button>
          </span>
        </template>
      </div>
    </div>

    <div v-if="showCTABack && !message.error" class="cta-back">
      <button class="button is-text is-small" @click="goBack">
        <span v-if="hasAllPermissions" key="cta-icon">
          <i class="fas fa-undo-alt" />
        </span>
        <span v-else key="cta-text">Enviar outro tipo?</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UAParser from 'ua-parser-js'
import {
  UnicoCheckBuilder,
  UnicoThemeBuilder,
  SelfieCameraTypes,
} from 'unico-webframe'
import _ from 'lodash'

import ValidationHeader from './Header.vue'

import checkMobileCompatibility from '@/mixins/checkMobileCompatibility'
import checkAccountID from '@/mixins/checkAccountID'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationPhotoSelfie',

  components: {
    ValidationHeader,
  },

  mixins: [checkMobileCompatibility, checkAccountID],

  data: () => ({
    sdk: {
      builder: null,
      theme: null,
      camera: null,
      services: '/data/services.json',
      models: '/data/models',
      resources: '/data/resources',
    },
    form: {
      img: '',
      location: {
        latitude: '',
        longitude: '',
      },
      photos: {
        selfie: {
          base64: '',
          encrypted: '',
        },
      },
    },
    deviceData: {},
    config: {
      showInfo: false,
      showAllowOnSelfie: true,
      expanded: true,
      loading: false,
    },
    message: {
      error: false,
      text: null,
    },
  }),

  computed: {
    ...mapState({
      account: (state) => state.validation.account,
    }),

    hasDocsAlready() {
      return this.account?.document_sent_by_admin === '1' || false
    },

    hasPhotoLoaded() {
      return this.form.img !== ''
    },

    hasLocation() {
      const { latitude, longitude } = this.form.location
      return latitude !== '' && longitude !== ''
    },

    hasAllPermissions() {
      return this.sdk.camera !== null
    },

    showTitle() {
      if (this.message.error) return true
      if (this.hasAllPermissions && this.config.expanded) return false
      return true
    },

    showTutorial() {
      return this.message.error || this.config.expanded
    },

    showPermissionBtn() {
      return this.config.showAllowOnSelfie || !this.hasAllPermissions
    },

    showCTABack() {
      return this.hasAllPermissions ? !this.config.expanded : true
    },
  },

  async mounted() {
    await this.getDeviceData()
  },

  methods: {
    ...mapActions({
      setProcessID: 'validation/SET_PROCESS_ID',
    }),

    retry() {
      window.location.reload(true)
    },

    goBack() {
      this.$router.push({ name: 'validation-documents' })
    },

    async getDeviceData() {
      const parser = new UAParser()
      this.deviceData = parser.getResult()
    },

    async getLocation() {
      await this.getCoordinates()
        .then(({ coords }) => {
          const {
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            latitude,
            longitude,
            speed,
          } = coords

          const data = {
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            latitude,
            longitude,
            speed,
          }

          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key]
              this.$set(this.form.location, key, element)
            }
          }
        })
        .catch(() => {
          const error = 'Geolocalização não está disponível em seu dispositivo'
          console.log(error)
        })
    },

    getCoordinates() {
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },

    resetImage({ toPlay = false } = {}) {
      this.form.img = ''

      this.removeAnimation(this.$refs.img, 'fade-in')

      if (toPlay) this.play()
    },

    instantiateNewBuilder() {
      try {
        this.sdk.builder = new UnicoCheckBuilder()
      } catch (err) {
        this.handleError(err)
      }
    },

    instantiateTheme() {
      try {
        this.sdk.theme = new UnicoThemeBuilder()
          .setColorSilhouetteSuccess('#0384fc')
          .setColorSilhouetteError('#D50000')
          .setColorSilhouetteNeutral('#fcfcfc')
          .setBackgroundColor('#dff1f5')
          .setColorText('#0384fc')
          .setBackgroundColorComponents('#0384fc')
          .setColorTextComponents('#dff1f5')
          .setBackgroundColorButtons('#0384fc')
          .setColorTextButtons('#dff1f5')
          .setBackgroundColorBoxMessage('#fff')
          .setColorTextBoxMessage('#000')
          .setHtmlPopupLoading(
            `<div style="position:absolute;top:45%;right:50%;transform:translate(50%,-50%);z-index:10;text-align:center;">Carregando...</div>`
          )
          .build()
      } catch (err) {
        this.handleError(err)
      }
    },

    async instantiateCamera() {
      try {
        await this.sdk.builder
          .setTheme(this.sdk.theme)
          .setModelsPath(this.sdk.models)
          .setResourceDirectory(this.sdk.resources)

        this.sdk.camera = await this.sdk.builder.build()
      } catch (err) {
        this.handleError(err)
      }
    },

    async loadSDK() {
      this.config.loading = true

      if (!this.hasLocation) await this.getLocation()

      await Promise.all([this.instantiateNewBuilder(), this.instantiateTheme()])
        .then(async () => {
          await this.instantiateCamera()

          this.config.expanded = false
          this.config.showAllowOnSelfie = false

          await this.play()
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.config.loading = false
        })
    },

    async play() {
      this.resetImage()
      await this.prepareSelfie()
    },

    handleError(err) {
      this.message.text = _.isString(err)
        ? err
        : err?.error?.response?.data?.error?.message ||
          err?.response?.data?.error?.message ||
          err?.error?.message ||
          err?.message ||
          'Ocorreu um problema durante sua solicitação'

      this.message.error = true
      this.config.expanded = true
      this.config.loading = false
    },

    handleSuccess({ base64, encrypted }, target = 'selfie') {
      const base64Formatted = !~base64.indexOf('data:image')
        ? `data:image/jpeg;base64,${base64}`
        : base64

      this.$set(this.form.photos, target, {
        base64: base64Formatted,
        encrypted,
      })
      this.form.img = base64Formatted
    },

    async prepareSelfie() {
      const _this = this

      this.config.loading = true

      await this.sdk.camera
        .prepareSelfieCamera(this.sdk.services, SelfieCameraTypes.NORMAL)
        .then((opener) => {
          this.config.loading = false

          opener.open({
            on: {
              success: (data) => _this.handleSuccess(data, 'selfie'),
              error: (err) => _this.handleError(err),
              support: (msg) => console.log(msg),
            },
          })
        })
        .catch((err) => _this.handleError(err))
        .finally(() => {
          this.config.loading = false
        })
    },

    nextStep(toSkip = false) {
      let name = 'validation-photo-type'

      this.config.expanded = false
      if (toSkip || this.hasDocsAlready) name = 'validation-final'

      this.$router.push({ name })
    },

    getFormattedData() {
      const { location } = this.form
      const { account_id, name, document } = this.account
      const { base64: img, encrypted } = this.form.photos.selfie
      const deviceData = this.deviceData

      return {
        name,
        document,
        account_id,
        img,
        encrypted,
        location,
        deviceData,
      }
    },

    async submit() {
      const api = new ValidationAPI()
      const params = this.getFormattedData()

      this.config.loading = true
      this.message.error = false
      this.message.text = null

      try {
        const { data } = await api.postSelfie(params)

        this.setProcessID(data.process_id)
        this.nextStep(data.skip_documents)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.resetImage()
        this.config.loading = false
      }
    },

    addAnimation(target, animation) {
      // reset animation effect
      target.style.animation = 'none'
      target.offsetWidth
      target.style.animation = null

      // add new animation
      if (animation) target.classList.add(animation)
    },

    removeAnimation(target, animation) {
      if (target) target.classList.remove(animation)
    },

    handleImageLoaded(event) {
      const target = event.target
      const { width, height, style } = target

      if (width > height)
        style.setProperty('transform', 'rotate(90deg) scale(1.5)')
      else style.setProperty('transform', 'initial')

      this.addAnimation(target, 'fade-in')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_photo.scss';
</style>
