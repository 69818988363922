<template>
  <div class="control has-icons has-icons-right">
    <input
      :id="`i${key}`"
      :accept="allowed"
      v-bind="$attrs"
      class="file"
      type="file"
      @change="handle($event.target.files)"
    />
    <label class="label">
      <slot />
    </label>
    <label ref="label" :for="`i${key}`" class="file__label">
      <template v-if="name">{{ name }}</template>
      <span v-else>Clique para carregar arquivo (PDF ou imagens)</span>
    </label>
    <i class="bar" />
    <p v-if="message" class="help has-text-danger">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BaseUpload',

  props: {
    allowed: {
      type: Array,
      default: () => ['.pdf', 'application/pdf', 'image/*'],
      required: false,
    },

    mb: {
      type: Number,
      default: 2,
      required: false,
    },
  },

  data: () => ({
    key: Math.random(1000),
    name: '',
    message: '',
  }),

  computed: {
    size() {
      return this.mb * 1024 * 1024
    },
  },

  methods: {
    handle([File]) {
      this.setData({})

      if (File.size > this.size)
        return this.setData({
          message: `Arquivo muito grande. Limite ${this.mb}MB`,
        })

      this.setData({ name: File.name })
      this.$emit('file', File)
    },

    setData(data) {
      this.name = data.name || ''
      this.message = data.message || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.file {
  position: absolute;
  top: 0;
  left: 0;

  width: 0.1px;
  height: 0.1px;
  padding: 0;
  overflow: hidden;

  &__label {
    display: block;
    width: 100%;
    padding: 0.125rem 0.125rem 0.0625rem;
    border-width: 0;
    border-color: transparent;

    font-size: 1rem;
    line-height: 1.9;

    transition: all 0.38s ease;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      color: #999;

      text-align: right;
    }
  }
}
</style>
