import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      account: (state) => state.validation.account,
    }),
  },

  mounted() {
    const { account_id } = this.account
    const hasNoAccountID = account_id === undefined

    if (hasNoAccountID) this.$router.push({ name: 'validation-login' })
  },
}
