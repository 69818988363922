export default {
  SET_ACCOUNT({ commit }, payload) {
    commit('SET_ACCOUNT', payload)
  },

  SET_SPREADSHEET({ commit }, payload) {
    commit('SET_SPREADSHEET', payload)
  },

  SET_PROPOSAL_ID({ commit }, payload) {
    commit('SET_PROPOSAL_ID', payload)
  },

  SET_WISH_AMOUNT({ commit }, payload) {
    commit('SET_WISH_AMOUNT', payload)
  },
}
