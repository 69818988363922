<template>
  <div class="container">
    <div class="section-simulator__form">
      <div class="columns">
        <div class="column">
          <h3 class="section-simulator__title">Simule</h3>
        </div>
      </div>

      <div class="columns">
        <div class="column is-full-desktop is-four-fifths-widescreen">
          <input
            id="credit_type"
            type="hidden"
            name="credit_type"
            value="emprestimo-fgts"
          />

          <div class="columns is-multiline">
            <div class="column is-6 field">
              <label><b>Informe seu saldo FGTS atual</b></label>
              <input
                v-model.lazy="balance"
                v-money="money"
                class="control control-lg control-input"
                type="text"
                name="balance"
                placeholder="Seu saldo no FGTS"
                @blur="onChg()"
              />
            </div>
            <div class="column is-6 field">
              <label><b>Informe seu mês de aniversário</b></label>
              <select
                v-model="birth_month"
                class="control control-input control-select"
                @change="onChg()"
              >
                <option selected="selected" value="0">
                  Mês de aniversário
                </option>
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </select>
            </div>
          </div>

          <div v-if="resultSimulator" class="result">
            <h3 class="result__title">
              <img :src="dotTitle" width="15" heigth="15" /> <b> Resultado</b>
            </h3>
            <!-- MOBILE  -->
            <div class="result__header-mobile">
              <div class="result__img-mobile has-text-centered">
                <img :src="logo" alt="Logo Prata" />
              </div>
              <div
                v-for="(year, index) in values_withdraw"
                :id="`${index}-mobile`"
                :key="index"
                class="result__anticipation"
                :class="{ 'is-hidden': index >= 1 }"
              >
                <div class="result__anticipation-values" style="border: none">
                  <div class="result__anticipation-values--content">
                    <div class="result__anticipation-values--label">
                      Saldo bloqueado
                    </div>
                    <div
                      class="result__anticipation-values--value result__anticipation-values--bg"
                    >
                      {{ sum_of_withdraw | currency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- DESKTOP -->
            <div v-if="values_withdraw" class="result__header-desktop">
              <div
                class="result__header-desktop--column result__header-desktop--column-label"
              >
                <div
                  class="result__header-desktop--row result__header-desktop--img"
                >
                  <img :src="logo" alt="Logo Prata" />
                </div>
                <div
                  class="result__header-desktop--row result__header-desktop--row-body result__header-desktop--label"
                >
                  Saldo que será bloqueado
                </div>
                <div
                  class="result__header-desktop--row result__header-desktop--row-body result__header-desktop--label"
                >
                  Antecipação
                </div>
              </div>

              <div
                v-for="(year, index) in values_withdraw"
                :key="index"
                class="result__header-desktop--column result__header-desktop--column-year"
              >
                <div
                  :id="index"
                  class="result__header-desktop--row result__header-desktop--row-year"
                  :class="{ 'border-right-green': index < 4 }"
                >
                  <div
                    class="result__anticipation-years--year result__header-desktop--year"
                  >
                    <p>{{ year.year }}</p>
                    <a
                      class="result__button-antecipate"
                      @click="getAnticipate(index, false)"
                    >
                      Antecipar
                    </a>
                  </div>
                </div>

                <div
                  class="result__header-desktop--row result__header-desktop--row-body"
                  :class="{
                    'remove-border-right': index == 4,
                    'border column-diferent-first': index % 2 != 0,
                  }"
                >
                  <div class="result__value">
                    <p>{{ year.withdraw | currency }}</p>
                  </div>
                </div>

                <div
                  class="result__header-desktop--row result__header-desktop--row-body"
                  :class="{
                    'remove-border-right': index == 4,
                    'border column-diferent': index % 2 != 0,
                  }"
                >
                  <div class="result__value">
                    <p>{{ year.credit | currency }}</p>
                  </div>
                </div>
              </div>

              <div
                class="result__header-desktop--column result__header-desktop--column-end"
              >
                <div
                  class="result__header-desktop--row result__header-desktop--row-header"
                ></div>
                <div
                  class="result__header-desktop--row result__header-desktop--row-end"
                ></div>
                <div
                  class="result__header-desktop--row result__header-desktop--row-end"
                ></div>
              </div>
            </div>

            <div class="result__taxes">
              <div class="result__taxes--content result__taxes--last">
                <p class="result__taxes--title result__taxes--last-title">
                  Crédito liberado
                </p>
                <p
                  class="result__taxes--value result__taxes--last-value result__taxes--border-left-green"
                >
                  {{ credit | currency }}
                </p>
              </div>
            </div>
            <small
              >Taxa de {{ tax }}% am. O saldo livre de bloqueio será de:
              {{ final_balance | currency }}</small
            >
          </div>

          <div
            v-if="resultSimulator"
            class="columns section-simulator__form--button"
          >
            <div class="column is-4">
              <div class="actions-btns">
                <button
                  class="section-simulator__form--button-ghost"
                  @click="contrateAgora"
                >
                  QUERO ESSE CRÉDITO!
                </button>
              </div>
            </div>
            <div class="col">
              <div class="txt-aviso">
                <img :src="arrow" />
                <span v-if="contractNow" class="text"
                  >Fique tranquilo, seus dados estão seguros. Nós levamos
                  segurança e privacidade muito à sério.</span
                >
                <span v-else class="text"
                  >Você será direcionado para o atendimento no WhatsApp.<br />
                  Tire suas dúvidas e siga os passos até a contratação.</span
                >
              </div>
            </div>
          </div>

          <div v-if="resultErrors" class="result">
            <h3 class="result__title">
              <img :src="dotTitle" width="15" heigth="15" /> <b> Resultado</b>
            </h3>
            <div
              v-for="(error, index) in resultErrors"
              :key="index"
              class="result__header-desktop--column result__header-desktop--column-year"
            >
              <p>{{ error[0] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import SimpleVueValidator from 'simple-vue-validator'
import { VMoney } from 'v-money'

//Image
import arrow from '@/assets/img/arrow.png'
import dotTitle from '@/assets/img/dot-title.png'
import back from '@/assets/img/arrow-back.png'
import next from '@/assets/img/arrow-next.png'

//libs
import SimulatorApi from '@/lib/api/FGTS'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'BaseSimulator',

  directives: { mask, money: VMoney },

  mixins: [SimpleVueValidator.mixin],

  props: {
    contractNow: {
      type: Boolean,
    },
    minimumBalance: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      arrow,
      dotTitle,
      value: null,
      back,
      next,
      valueRange: 0,
      balance: this.minimumBalance ?? 'R$ 500,00',
      balanceApi: 0,
      credit: null,
      name: null,
      email: null,
      phone: null,
      birth_month: 1,
      error_name: null,
      withdraws: null,
      tax: null,
      values_withdraw: null,
      resultSimulator: false,
      resultErrors: false,
      sum_of_withdraw: null,
      final_balance: null,
      total_anticipate: null,
      total_warranty: null,
      anticipation: [],
      activacted: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
    }
  },

  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    }),

    businessName() {
      return this.theme.business?.name || ''
    },

    logo() {
      return this.theme.logo?.simulator || ''
    },
  },

  beforeMount() {
    this.onChg()
  },

  methods: {
    formatToNumber(number) {
      return parseFloat(
        number.replace('R$', '').replace('.', '').replace(',', '.')
      )
    },
    getSimulation(balance, birth_month) {
      this.$gtm.trackEvent({
        event: 'simulate',
        category: 'Calculator',
        value: balance,
      })

      const balance_value = this.formatToNumber(balance)
      const dataSubmit = { balance_value, birth: birth_month }

      const simulatorApi = new SimulatorApi()
      const result = simulatorApi
        .getDataSimulation(dataSubmit)
        .then((response) => {
          return response.data
        })
        .catch((err) => {
          return (
            err?.response?.data?.error?.message ||
            err?.error?.response?.data?.error?.message ||
            err?.message ||
            err
          )
        })

      return result
    },

    contrateAgora() {
      if (this.contractNow) {
        this.$gtm.trackEvent({ event: 'contract' })
        let strUrl = ''

        switch (this.$router.currentRoute.path) {
          case '/supersim':
            strUrl = '/supersim-contrate-agora?'
            break
          case '/e-contrate':
            strUrl = '/e-contrate-agora?'
            break
          case '/c-contrate':
            strUrl = '/c-contrate-agora?'
            break
          default:
            strUrl = '/contrate-agora?'
            break
        }

        if (this.$route.query.utm_source) {
          strUrl += 'utm_source=' + this.$route.query.utm_source + '&'
        }
        if (this.$route.query.utm_medium) {
          strUrl += 'utm_medium=' + this.$route.query.utm_medium + '&'
        }
        if (this.$route.query.utm_campaign) {
          strUrl += 'utm_campaign=' + this.$route.query.utm_campaign + '&'
        }

        window.location.href = strUrl
      } else {
        this.$gtm.trackEvent({ event: 'whatsapp' })
        let strMessage = `Olá *${this.businessName}*! Eu gostaria de ter mais informações sobre FGTS (LP)`

        if (this.$route.query.utm_campaign) {
          var utm_campaign = this.$route.query.utm_campaign
          var arrParts = utm_campaign.split('-')
          var strInfluencer = arrParts.slice(-1).pop()
          strInfluencer =
            strInfluencer.charAt(0).toUpperCase() + strInfluencer.slice(1)
          strMessage = `Olá *${this.businessName}*! ${strInfluencer} falou de vocês, quero saber mais!`
        }
        window.location.href =
          'http://api.whatsapp.com/send?phone=+552139422489&text=' + strMessage
      }
    },

    async onChg() {
      let result = null
      console.log(this.balance)
      if (this.balance && this.birth_month != 0) {
        result = await this.getSimulation(this.balance, this.birth_month)
      }

      this.resultSimulator = false

      if (result != null && result.data) {
        this.resultSimulator = true
        this.resultErrors = false
        this.tax = result.data.tax
        this.balanceApi = result.data.balance
        this.credit = result.data.credit
        this.sum_of_withdraw = result.data.sum_of_withdraw
        this.final_balance = result.data.final_balance
        this.withdraws = result.data.withdraw
        this.values_withdraw = result.data.values
      } else if (result != null && result.error) {
        this.resultErrors = result.error?.errors ?? result.error
      }
    },

    getAnticipate(index, isMobile) {
      this.sum_of_withdraw = 0
      this.credit = 0

      for (let i = 0; i <= index; i++) {
        this.sum_of_withdraw += parseFloat(
          this.values_withdraw[i].withdraw.replace(',', '.')
        )
        this.credit += parseFloat(
          this.values_withdraw[i].credit.replace(',', '.')
        )
      }

      this.final_balance = this.balanceApi - this.sum_of_withdraw

      // element classes manipulation
      this.manipulationStyle(index, isMobile)
    },

    manipulationStyle(index, isMobile) {
      for (let i = 0; i < this.values_withdraw.length; i++) {
        var element = document.getElementById(i)

        // if mobile get great-grandchild element
        if (isMobile) {
          var el = '-mobile'
          var element = document.getElementById(i + el).childNodes[0]
            .childNodes[1]
        }

        element.classList.remove('inactive-header-year')
        element.classList.add('active')

        if (i > index) {
          element.classList.add('inactive-header-year')
          element.classList.remove('active')
        }
      }
    },

    changeYear(id) {
      if (id >= 0 && id <= 4) {
        // element classes manipulation
        for (let i = 0; i < this.values_withdraw.length; i++) {
          const className = `${i}-mobile`
          var element = document.getElementById(className)
          element.classList.add('is-hidden')

          if (i === id) {
            element.classList.remove('is-hidden')
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.active {
  a,
  button {
    background-color: $color-primary !important;
  }
}

.inactive-header-year {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.arrow-disabled {
  opacity: 0.5;
}

.section-simulator {
  &__title {
    font-size: 65px;
    color: $color-primary;
    font-family: Montserrat-Bold;
    text-align: center;
  }

  &__form {
    margin: 50px auto;
    padding: 50px 30px 80px;
    margin-bottom: 0;

    @media (min-width: 769px) {
      background-image: url('~@/assets/img/fgts-lp/bg-simulator.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0px 0px 15px 0px rgba(39, 39, 39, 0.2);
      border-radius: 5px;
      margin: 50px auto;
    }

    .columns {
      justify-content: center;
    }

    &--range-label {
      display: flex;
      justify-content: space-between;

      label {
        font-family: Montserrat-Light;
        font-size: 20px;
        color: $color-secondary;
      }

      span {
        font-family: Montserrat-ExtraBold;
        font-size: 24px;
        color: $color-secondary;
      }
    }

    #slider-valor {
      margin-bottom: 20px;
      margin-top: 20px;
      -webkit-appearance: none;
      width: 100%;
      height: 2px;
      background: $color-secondary;
      outline: none;
      opacity: 0.7;
      border-radius: 2px;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }

    #slider-valor:hover {
      opacity: 1;
    }

    #slider-valor::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      background: $color-primary;
      cursor: pointer;
      border-radius: 50%;
    }

    #slider-valor::-moz-range-thumb {
      width: 3px;
      height: 2px;
      background: $color-secondary;
      cursor: pointer;
      border-radius: 2px;
    }

    .control-juros {
      background-color: #f6f6f6;
      color: $color-secondary;
      font-family: Montserrat-Light;
      width: 100%;
      border: none;
      height: 40px;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus-visible {
        outline: none;
      }
    }

    .range {
      margin-bottom: 15px !important;
      padding: 10px 0 !important;
    }

    .field {
      margin-bottom: 0;
      padding: 10px;

      .control-input {
        width: 100%;
        border: 2px solid $color-primary;
        height: 40px;
        border-radius: 5px;
        padding: 5px;
        margin: 0;

        &:focus-visible {
          outline: none;
        }
      }

      .control-select {
        box-shadow: none;
        background-color: transparent;
        padding: 0;
      }
    }

    &--require-data {
      font-size: 16px;
      color: $color-secondary;
      font-family: Montserrat-Light;
      padding-left: 10px;
    }

    &--button {
      margin-top: 15px;
      margin-bottom: 15px;
      align-items: center;

      @media (min-width: 769px) {
        justify-content: flex-start !important;
      }

      .col-5 {
        @media (min-width: 769px) {
          padding: 0;
          margin-right: 15px;
        }
      }

      .actions-btns {
        text-align: center;

        @media (min-width: 769px) {
          text-align: left;
        }
      }

      .txt-aviso {
        background-color: $color-primary;
        font-size: 12px;
        color: $color-secondary;
        font-family: Montserrat-Regular;
        border-radius: 5px;
        padding: 10px;
        position: relative;
        max-width: 240px;
        margin: 10px auto;
        text-align: center;

        @media (min-width: 769px) {
          margin: 0 auto;
          text-align: left;
        }

        img {
          position: absolute;
          left: 50%;
          top: -10px;
          margin-left: -10px;

          @media (min-width: 769px) {
            left: 0px;
            top: 50%;
            margin-top: -10px;
          }
        }
      }
    }

    &--button-ghost {
      cursor: pointer;
      font-family: Montserrat-Regular;
      border: 2px solid $color-primary;
      background: transparent;
      color: $color-primary;
      font-size: 18px;
      border-radius: 10px;
      padding: 20px 40px 18px;
      text-align: center;
      line-height: 1;
      transition: all 0.5s ease-in-out;

      @media (min-width: 769px) {
        padding: 17px 40px 15px;
      }

      &:hover {
        background-color: $color-primary;
        color: #ffffff;
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
          background: transparent;
          color: $color-primary;
        }
      }
    }
  }
}

.result {
  margin-top: 20px;

  &__header-mobile {
    display: block;

    @media (min-width: 769px) {
      display: none;
    }
  }

  &__header-desktop {
    display: none;

    @media (min-width: 769px) {
      display: flex;
    }

    &--column-label {
      flex: 1.4;
    }

    &--column-year {
      flex: 1.2;
    }

    &--column-end {
      flex: 0.4;
    }

    &--row {
      height: 115px;

      img {
        max-width: 130px;
        margin: 25px;
      }
    }

    &--row-body {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-primary-350;
      color: $color-secondary;
      font-family: 'Montserrat-SemiBold';
      font-size: 16px;

      &:nth-child(2) {
        border-bottom: 2px solid #ffffff;
        border-top: 2px solid $color-primary-350;
      }

      &:nth-child(3) {
        border-bottom: 2px solid $color-primary-350;
        background-color: #ffffff;
      }
    }

    &--row-end {
      height: 70px;
      border: none;
      background-color: $color-primary;

      &:nth-child(2) {
        border-top-right-radius: 4px;
        border-top: 2px solid $color-primary-350;
        border-right: 2px solid $color-primary-350;
      }

      &:nth-child(3) {
        border-bottom-right-radius: 4px;
        border-right: 2px solid $color-primary-350;
        border-bottom: 2px solid $color-primary-350;
        background-color: $color-primary;
      }
    }

    &--label {
      background-color: $color-primary;
      color: $color-secondary;
      font-size: 18px;
      font-family: Montserrat-Bold;
      justify-content: start;
      padding: 10px;

      &:nth-child(2) {
        border-top-left-radius: 4px !important;
        border-top: 2px solid $color-primary-350;
        border-bottom: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        border-left: 2px solid $color-primary-350;
      }

      &:nth-child(3) {
        border-bottom-left-radius: 4px !important;
        border-bottom: 2px solid $color-primary-350;
        border-right: 2px solid #ffffff;
        border-left: 2px solid $color-primary-350;
        background-color: $color-primary;
      }
    }

    &--img {
      background-color: #f8f8f8;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      border-right: 2px solid #f8f8f8;
    }

    &--row-header {
      background-color: #f8f8f8;
      margin-bottom: 16px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &--row-year {
      margin-bottom: 16px;
      /* opacity: 0.5;

      &:hover {
        opacity: 1;
      } */
    }

    &--year {
      padding: 10px;

      p {
        font-size: 26px !important;
      }

      a {
        font-size: 15px !important;
        padding: 5px 7px !important;
      }
    }
  }

  &__title {
    font-family: Montserrat-ExtraBold;
    font-size: 24px;
    color: $color-secondary;
    margin-bottom: 10px;

    img {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  &__img-mobile {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 25px;
  }

  &__anticipation-years {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
    border-radius: 8px;
    background-color: #f8f8f8;

    &--btn-back {
      padding: 20px;

      img {
        cursor: pointer;
        position: relative;
        transition: all 0.5s ease-in-out;
        left: 0;

        &:hover {
          left: -10px;
        }
      }
    }

    &--year {
      flex: 1;
      min-height: 115px;
      background-color: $color-secondary;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      p {
        font-family: Montserrat-Bold;
        font-size: 30px;
        color: $color-primary;
      }

      button {
        background-color: transparent;
        color: #ffffff;
        border-radius: 8px;
        border: 1px solid $color-primary;
        text-transform: uppercase;
        font-size: 18px;
        padding: 5px 10px;
        font-family: Montserrat-Regular;
        transition: all 0.5s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: $color-primary;
        }
      }
    }

    &--btn-next {
      padding: 20px;

      img {
        cursor: pointer;
        position: relative;
        transition: all 0.5s ease-in-out;
        right: 0;

        &:hover {
          right: -10px;
        }
      }
    }
  }

  &__anticipation-values {
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid $color-primary-350;

    &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
      height: 51px;
    }

    &--last {
      margin-bottom: 0px;
    }

    &--label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: $color-primary;
      height: 100%;
      padding: 10px;
      flex: 1;
      color: $color-secondary;
      font-size: 17px;
      font-family: Montserrat-Bold;
      line-height: 1.2;
    }

    &--bg {
      background-color: $color-primary-350;
    }

    &--value {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1.3;
      text-align: center;
      color: $color-secondary;
      font-size: 20px;
      font-family: Montserrat-SemiBold;
      margin-left: 2px;
      padding: 10px;
    }
  }

  &__taxes {
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid $color-primary-350;
    margin-top: 16px;

    &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
      height: 70px;

      @media (min-width: 1024px) {
        height: 55px;
      }
    }

    &--title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: $color-primary;
      height: 100%;
      padding: 10px;
      flex: 1;
      color: $color-secondary;
      font-size: 17px;
      font-family: Montserrat-Bold;
      line-height: 1.2;

      @media (min-width: 1024px) {
        line-height: 1;
      }
    }

    &--value {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      //align-items: center;
      flex: 1.3;
      text-align: center;
      color: $color-secondary;
      font-size: 20px;
      font-family: Montserrat-SemiBold;
      margin-left: 2px;
      line-height: 1.2;
      padding: 10px;

      @media (min-width: 1024px) {
        line-height: 1;
        flex: 1;
      }

      span {
        font-size: 17px;
      }
    }

    &--without-bg {
      @media (min-width: 769px) {
        background-color: transparent;
      }
    }

    &--border-left-green {
      @media (min-width: 769px) {
        margin-left: 0;
        border-left: 2px solid $color-primary;
      }
    }

    &--last-title {
      background-color: $color-secondary;
      color: #ffffff;
    }

    &--last-value {
      background-color: $color-secondary;
      color: $color-primary;
    }

    &--bg {
      background-color: $color-primary-350;
    }

    &--last {
      margin-bottom: 0px;
    }
  }

  &__button-antecipate {
    cursor: pointer;
    font-family: Montserrat-Regular;
    border: 1px solid $color-primary;
    background: transparent;
    color: #ffffff;
    font-size: 16px;
    border-radius: 6px;
    padding: 20px 40px 18px;
    text-align: center;
    line-height: 1;
    transition: all 0.2s ease-in-out;

    @media (min-width: 769px) {
      padding: 17px 40px 15px;
    }

    &:hover {
      background-color: $color-primary;
      color: #ffffff;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        background: transparent;
        color: $color-primary;
      }
    }
  }
}

.border-right-green {
  border-right: 2px solid $color-primary;
}

.remove-border-right {
  border-right: none;
}

.border {
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.column-diferent-first {
  background-color: $color-primary-350 !important;
}

.column-diferent {
  background-color: #f3f4f4 !important;
  border-left: 2px solid $color-primary;
  border-right: 2px solid $color-primary;
}
</style>
