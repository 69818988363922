<template>
  <div class="control">
    <template v-if="autocomplete">
      <input
        ref="input"
        v-model="form.search"
        v-bind="$attrs"
        type="text"
        :placeholder="placeholder"
        @focus="focus"
        @blur="blur"
        @keyup.enter="add"
      />
      <i v-if="form.search" class="far fa-times-circle" @click="close" />
      <button
        v-if="hasAdd && isFocused && form.search && !hasFilteredOptions"
        class="add button is-small"
        @click="add"
      >
        <strong>Adicionar</strong>
        <i class="fas fa-plus" />
      </button>
      <div v-if="isFocused && hasFilteredOptions" :style="height" class="list">
        <div class="list__container">
          <button
            v-for="(option, index) in filteredOptions"
            :key="index"
            class="button is-ghost"
            @click="input(option)"
          >
            {{ option.name }}
          </button>
        </div>
      </div>
    </template>
    <select
      v-else
      v-bind="$attrs"
      :value="value"
      @input="emit($event.target.value)"
      @focus="focus"
      @blur="blur"
    >
      <option value="" disabled selected>Selecione uma opção</option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>

    <label class="label">
      <slot />
      <span v-if="required">*</span>
    </label>

    <i class="bar" />

    <div v-if="errorMessage" class="error-message">
      <small class="has-text-danger">{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSelect',

  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false,
    },

    options: {
      type: Array,
      default: () => [],
      required: true,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    errorMessage: {
      type: String,
      default: '',
      required: false,
    },

    autocomplete: {
      type: Boolean,
      default: false,
      required: false,
    },

    hasAdd: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    form: {
      search: '',
    },
    isFocused: false,
  }),

  computed: {
    filteredOptions() {
      return this.options.filter((option) => {
        const name = option.name.toLowerCase()
        const search = this.form.search.toLowerCase()

        if (~name.indexOf(search)) return option
      })
    },

    hasFilteredOptions() {
      return this.filteredOptions.length !== 0
    },

    placeholder() {
      return this.$attrs?.placeholder || 'Selecione uma opção'
    },

    height() {
      const n = 5
      const height = 40
      const gap = 10

      return this.filteredOptions.length > 7
        ? `height:${n * height + (n - 1) * gap + gap * 2}px`
        : `height:auto`
    },
  },

  watch: {
    value(data) {
      const option = this.getOptionByValue(data)
      this.form.search = option?.name || ''
    },
  },

  methods: {
    emit(value, key = 'selected') {
      this.$emit(key, value)
    },

    add() {
      if (this.form.search === '') return

      this.emit(this.form.search, 'added')
      this.blur()
    },

    close() {
      this.input({ name: '', value: '' })
    },

    input(data) {
      if (this.autocomplete) {
        this.form.search = data.name
        this.isFocused = false
      }
      this.emit(data)
    },

    focus() {
      if (this.autocomplete) {
        this.form.search = ''
        this.isFocused = true
      }
      this.emit(true, 'focus')
    },

    blur() {
      setTimeout(() => {
        if (this.autocomplete) {
          const option = this.getOptionByValue(this.value)
          this.form.search = option?.name || ''
          this.isFocused = false
        }
        this.emit(true, 'blur')
      }, 350)
    },

    getOptionByValue(data) {
      const [option] = this.options.filter(({ value }) => value === data)
      return option
    },
  },
}
</script>

<style lang="scss" scoped>
.control {
  margin-top: 3.25rem;
  margin-top: 3.5rem;

  label,
  select {
    top: -1.5rem !important;
  }

  select {
    height: 33px;
  }

  span {
    color: var(--color-primary);
  }
}

.add,
.fa-times-circle {
  position: absolute;
  right: 2.5px;

  cursor: pointer;
}

.add {
  top: -5px;

  .fa-plus {
    margin-left: 5px;
  }
}

.fa-times-circle {
  top: -10px;

  background-color: #fff;
  padding: 10px;

  font-size: 1.25em;
}

.list {
  $corner: 5px;
  $recuo: 5px;

  position: absolute;
  top: 35px;
  left: $recuo;
  z-index: 2;

  width: calc(100% - #{$recuo * 2});
  background-color: #fff;
  border-radius: 0 0 $corner $corner;
  box-shadow: 2px 2px 5px rgba(#000, 0.05), -2px 2px 5px rgba(#000, 0.05);
  overflow-x: auto;

  &__container {
    display: grid;
    padding: 10px;

    row-gap: 10px;
    scroll-snap-type: y mandatory;
  }

  .button {
    background-color: #fff;
    justify-content: flex-start;
    scroll-snap-align: start;

    color: $color-secondary;

    &:hover {
      // 50, 115, 220
      box-shadow: 0 0 0 0.125em rgba(var(--color-primary-rgb), 0.25);
    }

    &:focus {
      // 50, 115, 220
      background-color: rgba(var(--color-primary-rgb), 0.75);
      color: #fff;
    }
  }
}
</style>
