module.exports = [
  { referral: 'fgts-monetizando', label: 'Monetizando' },
  { referral: 'fgts-flavio', label: 'Flávio' },
  { referral: 'fgts-vem-com-o-luh', label: 'Vem com o Luh' },
  { referral: 'fgts-valter', label: 'Valter Santos' },
  { referral: 'fgts-globalnewsbr', label: 'GLOBAL NEWS BR' },
  { referral: 'fgts-douglasdecamargos', label: 'Douglas De Camargos' },
  { referral: 'fgts-gilmar', label: 'Gilmar Ssampa' },
  { referral: 'fgts-adriano', label: 'Adriano Marques Coach Financeiro' },
  { referral: 'fgts-dicatrabalhista', label: 'Rodrigo Dica Trabalhista' },
  { referral: 'fgts-facebook', label: 'Facebook' },
  { referral: 'fgts-instagram', label: 'Instagram' },
  { referral: 'fgts-google', label: 'Google' },
  { referral: 'fgts-outros', label: 'Outros' },
]
