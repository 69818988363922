<template>
  <div :class="{ loading }" class="validation-login">
    <validation-header :has-steps="false" :has-title="false" />

    <div class="validation-login__container fade-in">
      <div class="validation-login__content">
        <i
          :class="[expanded ? 'fa-times-circle' : 'fa-question-circle']"
          class="fas"
          @click="expanded = !expanded"
        />
        <p>Bem vindo ao Sistema de Validação {{ businessName }}</p>

        <span v-show="false">{{ form.doc.number }}</span>

        <p v-if="expanded" class="expanded">
          Esse é um sistema simples e rápido, onde utilizamos seu CPF, Data de
          Nascimento e foto do documento RG ou CNH para validação da proposta.
        </p>

        <p v-if="isValid" class="mt-5">Vamos começar?</p>

        <div v-else class="alert">
          <p class="has-text-danger has-background-danger-light">
            Dispositivo incompatível
          </p>

          <p>
            Este dispositivo não oferece um ou mais recursos necessários para a
            validação.
          </p>

          <p>
            Para dispositivos móveis <strong>Android</strong>, considere
            utilizar o <strong>Google Chrome</strong>,
            <strong>Microsoft Edge</strong> ou <strong>Opera</strong>.
          </p>

          <p>
            Em caso de <strong>iOS</strong>, utilize o <strong>Safari</strong>
          </p>

          <button class="button btn" @click="back">
            <span>Retornar</span>
            <span class="icon">
              <i class="fas fa-home" />
            </span>
          </button>
        </div>
      </div>

      <template v-if="isValid">
        <v-validate-observer v-slot="{ handleSubmit }">
          <form
            class="validation-login__form"
            @submit.prevent="handleSubmit(submit)"
          >
            <v-validate
              v-slot="{ errors }"
              rules="required|length:14"
              name="CPF"
            >
              <base-input
                :value="form.doc.number"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="tel"
                maxlength="14"
                format="###.###.###-##"
                @input="form.doc.number = $event"
              >
                <span>CPF</span>
                <span class="required">*</span>
              </base-input>
            </v-validate>

            <v-validate
              v-slot="{ errors }"
              :rules="{ regex: regex.date, required: true }"
              name="Data de nascimento"
            >
              <base-input
                :value="form.birthday"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="tel"
                maxlength="10"
                format="##/##/####"
                @input="form.birthday = $event"
              >
                <span>Data de Nascimento</span>
                <span class="required">*</span>
              </base-input>
            </v-validate>

            <div>
              <button class="button btn" type="submit">
                <span>Validar</span>
                <span class="icon">
                  <i class="fas fa-sign-in-alt" />
                </span>
              </button>

              <button v-show="false" class="button is-text" @click="more">
                <span>Saiba mais</span>
              </button>
            </div>
          </form>

          <div v-if="message.error" class="alert mt-5">
            <p class="has-text-danger has-background-danger-light p-3">
              {{ message.text }}
            </p>
          </div>
        </v-validate-observer>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ValidationHeader from './Header.vue'
import BaseInput from '@/components/BaseInput'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationLogin',

  components: {
    ValidationHeader,
    BaseInput,
  },

  data: () => ({
    form: {
      doc: {
        number: '',
      },
      birthday: '',
    },
    regex: {
      date: /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/,
    },
    message: {
      error: false,
      text: null,
    },
    expanded: false,
    loading: false,
  }),

  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    }),

    token() {
      return this.$route.params.token
    },

    logo() {
      return this.theme.logo?.default || ''
    },

    businessName() {
      return this.theme.business?.name || ''
    },

    hasMobileCompatibility() {
      const agent = navigator.userAgent || navigator.vendor || window.opera
      let check = false

      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          agent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          agent.substr(0, 4)
        )
      ) {
        check = true
      }

      return check
    },

    hasMediaDevicesCompatibility() {
      return !!navigator.mediaDevices
    },

    isMobileFirefox() {
      const vendor = navigator.vendor
      return vendor === ''
    },

    isValid() {
      return (
        this.hasMobileCompatibility &&
        this.hasMediaDevicesCompatibility &&
        !this.isMobileFirefox
      )
    },
  },

  methods: {
    ...mapActions({
      setAccount: 'validation/SET_ACCOUNT',
    }),

    getFormattedData() {
      return {
        document: this.form.doc.number,
        birthdate: this.form.birthday,
        token: this.token,
      }
    },

    handleError({ error }) {
      this.message.text =
        error?.response?.data?.error?.message ||
        error?.error?.response?.data?.error?.message ||
        error?.message ||
        error

      if (error?.errors) {
        for (const key in error?.errors) {
          if (Object.hasOwnProperty.call(error?.errors, key)) {
            const [message] = error?.errors[key]
            this.message.text += message
          }
        }
      }

      this.message.error = true
    },

    setData({ ...data }) {
      this.setAccount(data)
      this.$router.push({ name: 'validation-documents' })
    },

    async submit() {
      const api = new ValidationAPI()
      const data = this.getFormattedData()

      this.loading = true
      this.message.error = false
      this.message.text = null

      await api
        .login(data)
        .then(({ data }) => this.setData(data))
        .catch(({ response }) =>
          this.handleError(response?.data || { error: response })
        )
        .finally(() => {
          this.loading = false
        })
    },

    back() {
      this.$router.push('/')
    },

    more() {
      console.log('more...')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_loading-skeleton.scss';

$recuo: 30px;

.validation-login {
  @include base();

  &__container {
    display: grid;
    row-gap: $recuo;

    &.fade-in {
      animation: fadeIn 1s linear;
    }
  }

  &__content {
    position: relative;

    color: $color-secondary;
    text-align: center;

    transition: all 0.35s ease;

    .expanded {
      margin-top: 24px;

      padding: 20px;
      border-radius: 5px;
      background-color: $color-light;
    }

    .fas[class*='circle'] {
      position: absolute;
      top: #{($recuo - 10) * (-1)};
      right: #{($recuo - 10) * (-1)};

      border-radius: 50%;
      overflow: hidden;

      font-size: 1.25em;

      cursor: pointer;
    }

    .alert {
      $recuo: 24px;

      margin: {
        top: $recuo;
        bottom: $recuo;
      }
    }

    .has-text-danger {
      padding: 10px 20px;
      border-radius: 5px;
    }

    .button {
      margin-top: $recuo;
    }
  }

  &__form {
    display: grid;

    grid-template-columns: 1fr;
    row-gap: $recuo + 10;

    .required {
      margin-left: 5px;
      color: $color-primary;
    }

    .button.is-text {
      margin-top: $recuo / 2;
    }

    &::v-deep {
      .control {
        margin-bottom: 0;
      }
    }
  }
}

.button.is-text {
  margin: 0 auto;
  display: block;

  color: $color-secondary;
}
</style>
