import Vue from 'vue'
import VueRouter from 'vue-router'

import FTGSLPInfoContract from './modules/fgts-lp-info-contract'
import FTGSLP from './modules/fgts-lp'
import FTGSLPInfoWhatsApp from './modules/fgts-lp-info-whatsapp'
import FTGSContract from './modules/form-contract'
import Validation from './modules/validation'
import Consult from './modules/consult'
import Subdomain from './modules/subdomain'

Vue.use(VueRouter)

// prettier-ignore
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...FTGSLPInfoContract,
    ...FTGSLP,
    ...FTGSLPInfoWhatsApp,
    ...FTGSContract,
    ...Validation,
    ...Consult,
    ...Subdomain,
  ],
})

export default router
