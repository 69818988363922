<template>
  <div class="form-document">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="form-contract__title">
          <h2><span>Documento</span></h2>
        </div>
      </div>
      <div class="column">
        <div class="document">
          <div v-if="!isPage.assistance" class="document__radio">
            <p>Documento <span class="document--span">*</span></p>
            <label v-for="(option, index) in documentTypeOptions" :key="index">
              <input
                v-model="documentType"
                type="radio"
                :value="option"
                @click="isOtherDocumentType = false"
              />
              {{ option }}
            </label>
          </div>

          <base-input
            class="document-number"
            :value="document"
            :has-error="
              validation.hasError('document') || document_error ? true : false
            "
            :first-error="validation.firstError('document') || document_error"
            :is-passed="validation.isPassed('document') && !document_error"
            type="tel"
            @input="document = $event"
          >
            Número do documento
            <span class="document--span">*</span>
          </base-input>

          <template v-if="!isPage.assistance">
            <base-input
              class="document-number"
              :value="document_issued_at"
              :has-error="
                validation.hasError('document_issued_at') ||
                document_issued_at_error
                  ? true
                  : false
              "
              :first-error="
                validation.firstError('document_issued_at') ||
                document_issued_at_error
              "
              :is-passed="
                validation.isPassed('document_issued_at') &&
                !document_issued_at_error
              "
              :type="'tel'"
              :format="'##/##/####'"
              @input="document_issued_at = $event"
            >
              Data de expedição do documento
              <span class="document--span">*</span>
            </base-input>
            <small style="font-size: 0.8rem">(Dia/Mês/Ano)</small>

            <p class="control document__document-local document-number">
              <select v-model="local">
                <option :value="null">ESCOLHER</option>
                <option
                  v-for="(state, index) in states"
                  :key="index"
                  :value="state.value"
                  :required="true"
                >
                  {{ state.name }}
                </option>
              </select>
              <label class="label">
                Estado de expedição do documento
                <span class="document--span">*</span>
              </label>
              <i class="bar" />
            </p>
          </template>

          <base-input
            :value="mother_name"
            :has-error="
              validation.hasError('mother_name') || mother_name_error
                ? true
                : false
            "
            :first-error="
              validation.firstError('mother_name') || mother_name_error
            "
            :is-passed="
              validation.isPassed('mother_name') && !mother_name_error
            "
            @input="mother_name = $event"
          >
            Nome de mãe
            <span class="document--span">*</span>
          </base-input>
        </div>
      </div>
      <div class="column">
        <div class="document">
          <base-input
            v-if="isPage.vehicle || isPage.immobile"
            class="document-number"
            :value="cnpj"
            :format="'##.###.###/####-##'"
            type="tel"
            @input="cnpj = $event"
          >
            CNPJ (se possuir % societária)
          </base-input>

          <div v-if="!isPage.assistance" class="document__radio">
            <p>Estado civil <span class="document--span">*</span></p>
            <label v-for="(option, index) in maritalStatusOptions" :key="index">
              <input
                v-model="maritalStatus"
                type="radio"
                :value="option"
                @click="isOthermaritalStatus = false"
              />
              {{ option }}
            </label>
            <!-- <label class="document__radio--other">
              <input
                v-model="maritalStatus"
                type="radio"
                @click="isOthermaritalStatus = true"
              />
              Outro:
              <base-input
                v-if="isOthermaritalStatus"
                :value="maritalStatusOther"
                :has-error="
                  validation.hasError('maritalStatusOther') ||
                  maritalStatusOther_error
                    ? true
                    : false
                "
                :first-error="
                  validation.firstError('maritalStatusOther') ||
                  maritalStatusOther_error
                "
                :is-passed="
                  validation.isPassed('maritalStatusOther') &&
                  !maritalStatusOther_error
                "
                @input="maritalStatusOther = $event"
              />
            </label> -->
          </div>

          <base-input
            v-if="maritalStatus == 'Casado(a)'"
            :value="spouse_name"
            :has-error="
              validation.hasError('spouse_name') || spouse_name_error
                ? true
                : false
            "
            :first-error="
              validation.firstError('spouse_name') || spouse_name_error
            "
            :is-passed="
              validation.isPassed('spouse_name') && !spouse_name_error
            "
            @input="spouse_name = $event"
          >
            Nome da(o) esposa(o) <span class="document--span">*</span>
          </base-input>

          <base-input
            v-if="maritalStatus == 'Casado(a)'"
            class="document-number"
            :value="spouse_birthdate"
            :has-error="
              validation.hasError('spouse_birthdate') || spouse_birthdate_error
                ? true
                : false
            "
            :first-error="
              validation.firstError('spouse_birthdate') ||
              spouse_birthdate_error
            "
            :is-passed="
              validation.isPassed('spouse_birthdate') && !spouse_birthdate_error
            "
            :type="'tel'"
            :format="'##/##/####'"
            @input="spouse_birthdate = $event"
          >
            Data de nascimento da(o) esposa(o)
            <span class="document--span">*</span>
          </base-input>

          <!-- <base-input
            v-if="maritalStatus == 'Casado(a)'"
            class="document-number"
            :value="spouse_document"
            :has-error="
              validation.hasError('spouse_document') || spouse_document_error
                ? true
                : false
            "
            :first-error="
              validation.firstError('spouse_document') || spouse_document_error
            "
            :is-passed="
              validation.isPassed('spouse_document') && !spouse_document_error
            "
            type="tel"
            format="###.###.###-##"
            @input="spouse_document = $event"
          >
            CPF da(o) esposa(o) <span class="document--span">*</span>
          </base-input> -->

          <!-- <p
            v-if="maritalStatus == 'Casado(a)'"
            class="control document__document-local document-number"
          >
            <select v-model="spouse_document_state">
              <option :value="null">ESCOLHER</option>
              <option
                v-for="(state, index) in states"
                :key="index"
                :value="state.value"
                :required="true"
              >
                {{ state.name }}
              </option>
            </select>
            <label class="label"
              >Estado do documento da(o) esposa(o)
              <span class="document--span">*</span></label
            >
            <i class="bar"></i>
          </p> -->

          <div v-if="isPage.cpr" class="document__radio">
            <p>
              Paga pensão alimentícia?
              <span class="document--span">*</span>
            </p>
            <label v-for="(option, index) in alimonyOptions" :key="index">
              <input v-model="alimony" type="radio" :value="option" />
              {{ option }}
            </label>
          </div>

          <base-input
            v-if="isPage.cpr"
            class="document-number"
            :value="dependents"
            :has-error="
              validation.hasError('dependents') || dependents_error
                ? true
                : false
            "
            :first-error="
              validation.firstError('dependents') || dependents_error
            "
            :is-passed="validation.isPassed('dependents') && !dependents_error"
            :type="'tel'"
            @input="dependents = $event"
          >
            Quantos dependentes possui?
            <span class="document--span">*</span>
          </base-input>
        </div>
      </div>
    </div>
    <div class="columns">
      <!-- <div class="column is-6">
        <div class="address">
          <div class="form-contract__button-back">
            <button @click="backStep">VOLTAR</button>
          </div>
        </div>
      </div> -->

      <div class="column is-6">
        <div class="address">
          <div class="form-contract__button-next has-text-right">
            <button
              :disabled="!formIsValidated || actionLoading"
              type="submit"
              @click="submit()"
            >
              PRÓXIMO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SimpleVueValidator from 'simple-vue-validator'

//components
import BaseInput from '@/components/BaseInput.vue'

//lib
import FormContractApi from '@/lib/api/FGTS'
import statesJSON from '@/lib/data/states.json'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'InfoDocument',

  components: {
    BaseInput,
  },

  mixins: [SimpleVueValidator.mixin],

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  validators: {
    documentType(value) {
      this.documentType_error = ''
      return Validator.value(value).required()
    },

    document(value) {
      this.document_error = ''
      return Validator.value(value).required()
    },

    alimony(value) {
      this.alimony_error = ''
      return Validator.value(value).required()
    },

    dependents(value) {
      this.dependents_error = ''
      return Validator.value(value).required()
    },

    local(value) {
      return Validator.value(value).required()
    },

    maritalStatus(value) {
      this.maritalStatus_error = ''
      return Validator.value(value).required()
    },
    maritalStatusOther(value) {
      return Validator.value(value).required()
    },
    document_issued_at(value) {
      this.document_issued_at_error = ''
      return Validator.value(value)
        .required()
        .regex(
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
          'Insira uma data válida'
        )
    },
    spouse_name(value) {
      if (this.maritalStatus != 'Casado(a)') {
        return true
      }
      return Validator.value(value)
        .required()
        .regex(/^[^0-9]*$/, 'Não é permitido números')
        .regex(
          /[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+\s+[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+/g,
          'Escreva o nome completo'
        )
    },
    spouse_document(value) {
      if (this.maritalStatus != 'Casado(a)') {
        return true
      }
      return Validator.value(value)
        .required()
        .minLength(14, 'Não foi possível validar seu CPF')
        .maxLength(14, 'Não foi possível validar seu CPF')
    },
    spouse_document_state(value) {
      if (this.maritalStatus != 'Casado(a)') {
        return true
      }
      return Validator.value(value).required()
    },
    spouse_birthdate(value) {
      if (this.maritalStatus != 'Casado(a)') {
        return true
      }
      return Validator.value(value)
        .required()
        .regex(
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
          'Insira uma data válida'
        )
    },
    mother_name(value) {
      return Validator.value(value)
        .required()
        .regex(/^[^0-9]*$/, 'Não é permitido números')
        .regex(/[a-zA-Z]+\s+[a-zA-Z]+/g, 'Escreva o nome completo')
    },
  },

  data: () => ({
    alimony: '',
    alimony_error: '',
    alimonyOptions: ['Sim', 'Não'],
    dependents: '',
    dependents_error: '',
    document: '',
    document_error: '',
    cnpj: '',
    documentTypeOptions: ['CNH', 'RG'],
    documentType: '',
    documentType_error: '',
    document_issued_at: '',
    document_issued_at_error: '',
    document_issued_by: '',
    document_issued_by_error: '',
    isOtherDocumentType: false,
    maritalStatusOptions: [
      'Solteiro(a)',
      'Casado(a)',
      'Separado(a)',
      'Divorciado(a)',
      'Víuvo(a)',
    ],
    isOthermaritalStatus: false,
    maritalStatus: '',
    maritalStatus_error: '',
    maritalStatusOther: '',
    maritalStatusOther_error: '',
    mother_name: '',
    mother_name_error: '',
    spouse_name: '',
    spouse_name_error: '',
    spouse_birthdate: '',
    spouse_birthdate_error: '',
    spouse_document: '',
    spouse_document_error: '',
    spouse_document_state: '',
    spouse_document_state_error: '',
    local: '',
    actionLoading: false,
    locations: {
      states: [],
    },
  }),

  computed: {
    ...mapState({
      account: (state) => state.fgts.account,
    }),

    formIsValidated() {
      const docIsValid = this.validation.isPassed('document')
      const typeIsValid = this.validation.isPassed('documentType')
      const motherNameIsValid = this.validation.isPassed('mother_name')
      const spouseNameIsValid = this.validation.isPassed('spouse_name')
      const spouseBirthIsValid = this.validation.isPassed('spouse_birthdate')
      const maritalStatusIsValid = this.validation.isPassed('maritalStatus')
      const maritalOtherIsValid = this.validation.isPassed('maritalStatusOther')
      const alimonyIsValid = this.validation.isPassed('alimony')
      const dependentsIsValid = this.validation.isPassed('dependents')

      const maritalStatusIsNotMarried = this.maritalStatus != 'Casado(a)'

      return (
        docIsValid &&
        typeIsValid &&
        motherNameIsValid &&
        (this.isPage.assistance
          ? true
          : maritalStatusIsNotMarried ||
            (spouseNameIsValid && spouseBirthIsValid)) &&
        // this.validation.isPassed('spouse_document') &&
        // this.validation.isPassed('spouse_document_state'))) &&
        (this.isPage.assistance
          ? true
          : maritalStatusIsValid || maritalOtherIsValid) &&
        (this.isPage.cpr ? alimonyIsValid : true) &&
        (this.isPage.cpr ? dependentsIsValid : true)
      )
    },

    states() {
      return this.locations.states.map(({ nome, sigla }) => ({
        name: nome,
        value: sigla,
      }))
    },
  },

  async mounted() {
    await this.getStates('states')
    if (this.isPage.assistance) {
      this.documentType = 'Benefício INSS'
    }
  },

  methods: {
    backStep() {
      this.$emit('nextStep', -1)
    },

    async submit() {
      const formContractApi = new FormContractApi()

      this.actionLoading = true
      this.$emit('loading', true)

      const data = {
        account_id: this.account.id,
        document_type: this.documentType,
        document_number: this.document,
        mother_name: this.mother_name,
        document_state: this.isPage.assistance ? '-' : this.local,
        marital_status: this.isPage.assistance
          ? '-'
          : this.isOthermaritalStatus
          ? this.maritalStatusOther
          : this.maritalStatus,
        ...(this.isPage.assistance
          ? ''
          : { document_issued_at: this.document_issued_at }),
        ...(this.isPage.assistance
          ? ''
          : { document_issued_by: this.document_issued_by }),
        ...(this.isPage.assistance ? '' : { spouse_name: this.spouse_name }),
        ...(this.isPage.assistance
          ? ''
          : { spouse_document: this.spouse_document }),
        ...(this.isPage.assistance
          ? ''
          : { spouse_document_state: this.spouse_document_state }),
        ...(this.isPage.assistance
          ? ''
          : { spouse_birthdate: this.spouse_birthdate }),
        ...(this.isPage.vehicle ? { company_document: this.cnpj } : ''),
        ...(this.isPage.cpr ? { alimony: this.alimony } : ''),
        ...(this.isPage.cpr ? { dependents: this.dependents } : ''),
      }

      await formContractApi
        .formContractQualification(data)
        .then(() => {
          this.$gtm.trackEvent({
            event: 'contract-document',
            account_id: this.account.id,
          })
          this.$emit('nextStep')
        })
        .catch((err) => {
          const error = {
            message:
              err?.response?.data?.error?.message ||
              err?.error?.response?.data?.error?.message ||
              err?.message ||
              err,
            others: [],
          }

          if (err?.response?.data?.error?.errors) {
            const entries = Object.entries(err?.response?.data?.error?.errors)
            for (const [entry, values] of entries) {
              for (const [key, value] of Object.entries(values)) {
                error.others.push({ entry, value })
              }
            }
          }

          this.$emit('message', error)
        })
        .finally(() => {
          const el = document.querySelector('.form-contract__step-by-step')
          el.scrollIntoView()

          this.actionLoading = false
          this.$emit('loading', false)
        })
    },

    async getStates(option) {
      this.loading = true

      this.locations[option] = statesJSON
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.form-document {
  .column {
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      padding: auto;
    }
  }
}

.document {
  max-width: 80%;
  margin: 0 auto;

  .control {
    margin-top: 3.25rem;
  }

  .document-number {
    margin-top: 4.5rem;

    label,
    select {
      top: -2.5rem !important;
    }
  }

  &--span {
    color: $color-primary;
  }

  &__radio {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    display: flex;
    flex-direction: column;

    p,
    label {
      font-size: 0.8rem;
      color: #616161;
      top: -1rem;
      left: 0;
      margin-bottom: 6px;
    }

    &--other {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      input {
        margin: 0;
        margin-right: 5px;
        margin-top: 4px;
      }

      .control {
        margin: -5px;
        width: 100%;
        padding-left: 10px;

        input {
          width: 100%;
        }
      }
    }
  }

  &__document-local {
    margin-top: 3.25rem;
    select {
      height: 33px;
    }
  }
}
</style>
