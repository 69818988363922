<template>
  <div :class="{ loading }" class="form-contract">
    <BaseMenu :contract-now="true" />

    <section class="form-contract__banner">
      <img
        v-if="showFormContractBannerImg1"
        class="form-contract__banner--img1"
        :src="img1"
        alt="Empréstimo FGTS"
      />
      <img
        class="form-contract__banner--img2"
        :src="img2"
        alt="Empréstimo FGTS"
      />
      <img
        class="form-contract__banner--img3"
        :src="img2"
        alt="Empréstimo FGTS"
      />
      <div class="container">
        <div class="columns">
          <div class="column">
            <template v-if="!isPage.cpr && !isPage.immobile">
              <img
                v-if="showFormContractBannerPigImage"
                class="form-contract__banner--pig-image"
                :src="pig"
                alt="Empréstimo FGTS"
              />
            </template>
            <template v-if="isPage.immobile">
              <img
                v-if="showFormContractBannerPigImage"
                class="form-contract__banner--house-image"
                :src="house"
                alt="Empréstimo com imóvel em garantia"
              />
            </template>
          </div>
          <div class="column is-7 form-contract__banner--text">
            <h1>
              {{ title }}
              <br />
              <span>Informações básicas</span>
            </h1>
            <p>*Obrigatório</p>
            <template v-if="!isPage.cpr && !isPage.immobile">
              <img
                v-if="showFormContractBannerPigImage"
                :src="imgMobile"
                class="form-contract__banner--pig-mobile"
                alt="Empréstimo FGTS"
              />
            </template>
            <template v-if="isPage.immobile">
              <img
                v-if="showFormContractBannerPigImage"
                :src="house"
                class="form-contract__banner--house-mobile"
                alt="Empréstimo com imóvel em garantia"
              />
            </template>
          </div>
        </div>
      </div>
    </section>

    <section class="form-contract__steps">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 id="contractTitle" class="form-contract__steps--title">
              Preencha o <span>formulário abaixo:</span>
            </h2>
            <br />
            <div class="form-contract__step-by-step">
              <ul class="form-contract__step-by-step--list">
                <li
                  v-for="(component, index) in componentsPerStep"
                  :key="index"
                  :class="{
                    'is-answer': currentStep > index,
                    'is-active': currentStep === index,
                  }"
                >
                  {{ component.label }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div
              v-if="messageError.message"
              class="message has-background-danger-light"
            >
              <small class="has-text-danger">
                Alerta: {{ messageError.message }}
              </small>
              <ul v-if="messageError.others[0]">
                <li
                  v-for="(message, index) in messageError.others"
                  :key="index"
                >
                  <small class="has-text-danger">{{ message.value }} </small>
                </li>
              </ul>
            </div>

            <div v-if="false" class="amount">
              <div class="p-3 has-text-success has-background-success-light">
                <span>Valor que receberá em sua conta:</span>
                <input v-money="money" :value="amount" type="text" disabled />
              </div>
            </div>

            <template v-if="currentStep !== componentsPerStepLength">
              <component
                :is="componentsPerStep[currentStep].component"
                :is-page="isPage"
                @showQRCode="showQRCode = true"
                @nextStep="nextStepForm"
                @message="messageError = $event"
                @loading="loading = $event"
              />
            </template>

            <FormSendSuccessful
              v-else
              :is-page="isPage"
              :show-qrcode="showQRCode"
            />
          </div>
        </div>
      </div>
    </section>

    <BaseBannerInformation v-if="showBannerInfo" :is-page="isPage" />

    <BaseFooter :is-page="isPage" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { VMoney } from 'v-money'

import BaseMenu from '@/components/BaseMenu.vue'
import BaseFooter from '@/components/BaseFooter.vue'
import BaseBannerInformation from '@/components/BaseBannerInformation.vue'

//steps
import InfoBasic from '@/components/form-contract/InfoBasic.vue'
import InfoDocument from '@/components/form-contract/InfoDocument.vue'
import InfoAddress from '@/components/form-contract/InfoAddress.vue'
import InfoVehicle from '@/components/form-contract/InfoVehicle.vue'
import InfoProfessional from '@/components/form-contract/InfoProfessional.vue'
import InfoEnd from '@/components/form-contract/InfoEnd.vue'
import FormSendSuccessful from '@/components/form-contract/FormSendSuccess.vue'
// import Simulate from '@/components/form-contract/Simulate.vue'

export default {
  name: 'FormContract',

  components: {
    BaseMenu,
    BaseFooter,
    BaseBannerInformation,
    InfoBasic,
    InfoDocument,
    InfoVehicle,
    InfoAddress,
    InfoProfessional,
    InfoEnd,
    FormSendSuccessful,
  },

  directives: { money: VMoney },

  data: () => ({
    pig: require('@/assets/img/contrate-agora-form/money.png'),
    house: require('@/assets/img/contrate-agora-form/house.png'),
    img1: require('@/assets/img/contrate-agora-form/img1.png'),
    img2: require('@/assets/img/contrate-agora-form/img2.png'),
    imgMobile: require('@/assets/img/contrate-agora-form/money-mobile.png'),
    msgEnviada: require('@/assets/img/contrate-agora-form/banner-msg-enviada.png'),
    currentStep: 0,
    steps: [
      {
        label: 'Dados Básicos',
        component: 'InfoBasic',
        keys: [
          'fgts-cb',
          'fgts',
          'veiculo',
          'pessoa',
          'consig-privado',
          'auxilio',
          'imovel',
        ],
      },
      {
        label: 'Documentos',
        component: 'InfoDocument',
        keys: [
          'fgts-cb',
          'fgts',
          'veiculo',
          'pessoa',
          'consig-privado',
          'auxilio',
          'imovel',
        ],
      },
      {
        label: 'Veículos',
        component: 'InfoVehicle',
        keys: ['veiculo'],
      },
      {
        label: 'Endereço',
        component: 'InfoAddress',
        keys: [
          'fgts-cb',
          'fgts',
          'veiculo',
          'pessoa',
          'consig-privado',
          'auxilio',
          'imovel',
        ],
      },
      {
        label: 'Profissional',
        component: 'InfoProfessional',
        keys: [
          'fgts-cb',
          'fgts',
          'veiculo',
          'pessoa',
          'consig-privado',
          'imovel',
        ],
      },
      {
        label: 'Financeiro',
        component: 'InfoEnd',
        keys: [
          'fgts-cb',
          'fgts',
          'veiculo',
          'pessoa',
          'consig-privado',
          'auxilio',
          'imovel',
        ],
      },
    ],
    messageError: {
      message: '',
      others: [],
    },
    loading: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    showQRCode: false,
  }),

  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
      amount: (state) => state.balance.amount,
    }),

    componentsPerStepLength() {
      return this.componentsPerStep.length
    },

    showBannerInfo() {
      return this.theme?.show?.FormContract?.BaseBannerInformation || false
    },

    showFormContractBannerImg1() {
      return this.theme?.show?.FormContract?.FormContractBannerImg1 || false
    },

    showFormContractBannerPigImage() {
      return this.theme?.show?.FormContract?.FormContractBannerPigImage || false
    },

    pageID() {
      const [domainParts] = window.location.host.split('.')
      return domainParts
    },

    isPage() {
      return {
        fgts: this.pageIdHas('fgts'),
        vehicle: this.pageIdHas('veiculo'),
        person: this.pageIdHas('pessoa'),
        cpr: this.pageIdHas('consig-privado'),
        assistance: this.pageIdHas('auxilio'),
        immobile: this.pageIdHas('imovel'),
      }
    },

    componentsPerStep() {
      return this.steps.filter((step) => {
        if (step.keys.includes(this.pageID)) return step
      })
    },

    title() {
      const options = {
        vehicle: 'Empréstimo com Veículo em Garantia',
        person: 'Empréstimo Pessoal sem Garantia',
        cpr: 'Empréstimo Consignado Privado',
        assistance: 'Auxílio Brasil',
        immobile: 'Empréstimo com Imóvel em Garantia',
      }
      const key = Object.keys(this.isPage).find((key) => this.isPage[key])

      return options[key] ?? 'Antecipação FGTS'
    },
  },

  watch: {
    currentStep() {
      this.messageError.message = ''
      this.messageError.others = []
      this.loading = false
    },
  },

  methods: {
    ...mapActions({
      setProposalID: 'fgts/SET_PROPOSAL_ID',
      setWishAmount: 'fgts/SET_WISH_AMOUNT',
    }),

    pageIdHas(key) {
      return !!~this.pageID.indexOf(key)
    },

    nextStepForm(step = 1) {
      const index = this.currentStep + step
      const max = this.componentsPerStepLength

      let element

      this.currentStep = index < 0 ? 0 : index > max ? max : index

      if (this.currentStep === max) {
        element = document.getElementById('contractTitle')
        element.style = 'display: none'
        element.scrollIntoView()
      } else {
        element = document.getElementsByClassName('form-contract__title')
        if (element != null) element[0].scrollIntoView()
      }
    },
  },

  metaInfo() {
    const { title } = this

    return {
      title: `${title} | ${process.env.VUE_APP_BUSINESS_NAME}`,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/src/_loading-skeleton.scss';

.message {
  padding: 20px;
  text-align: center;
}

.form-contract {
  &__banner {
    background-color: $color-secondary;
    position: relative;
    overflow: hidden;
    padding-bottom: 0;

    @media (min-width: 769px) {
      padding-bottom: 30px;
    }

    &--text {
      padding-right: 20px;

      p {
        margin-bottom: 60px;

        @media (min-width: 769px) {
          margin-bottom: 0;
        }
      }
    }

    h1 {
      color: $color-primary;
      font-size: 46px;
      font-family: Montserrat-Bold;
      line-height: 1.2;
      text-align: center;
      padding: 10px 20px 10px;

      span {
        font-family: Montserrat-Regular;
        color: #ffffff;
      }

      @media (min-width: 769px) {
        text-align: right;
        padding: 0;
        font-size: 35px;
      }
    }

    p {
      font-size: 30px;
      font-family: Montserrat-Regular;
      color: $color-primary;
      text-align: center;

      @media (min-width: 769px) {
        font-size: 24px;
        text-align: right;
      }
    }

    &--pig-mobile {
      display: block;
      width: 100%;

      @media (min-width: 769px) {
        display: none;
      }
    }

    &--house-mobile {
      display: block;
      width: 100%;
      max-height: 100px;
      object-fit: contain;

      @media (min-width: 769px) {
        display: none;
      }
    }

    &--pig-image {
      height: 160px;
      position: absolute;
      left: 5%;
      display: none;

      @media (min-width: 769px) {
        display: block;
      }
    }

    &--house-image {
      height: 150px;
      position: absolute;
      left: 5%;
      display: none;

      @media (min-width: 769px) {
        display: block;
      }
    }

    &--img1 {
      position: absolute;
      right: -85px;
      top: -70px;
      max-width: 185px;
      display: none;

      @media (min-width: 1408px) {
        right: 0px;
      }
      @media (min-width: 769px) {
        display: block;
      }
    }

    &--img2 {
      display: none;
      position: absolute;
      top: -120px;
      left: 30%;
      @media (min-width: 769px) {
        display: block;
      }
    }

    &--img3 {
      display: none;
      position: absolute;
      bottom: -130px;
      left: 0;
      @media (min-width: 769px) {
        display: block;
      }
    }
  }

  &__title {
    background-color: #f7f7f7;
    text-align: center;
    padding: 60px 30px;

    h2 {
      line-height: 1;
      margin: 0px 30px;
      border-bottom: 8px solid $color-primary;
      padding-bottom: 10px;

      span {
        color: $color-secondary;
        font-family: Montserrat-ExtraBold;
        font-size: 37px;
      }
    }

    @media (min-width: 769px) {
      display: none;
    }
  }

  &__subtitle {
    padding: 50px 5% 30px;
    box-sizing: border-box;

    p,
    li.text {
      font-size: 18px;
      font-family: Montserrat-Bold;
      color: $color-primary;

      span {
        color: $color-secondary;
      }
    }

    li.text {
      $recuo: 30px;

      position: relative;
      margin: 10px 0;

      padding-left: $recuo;
      box-sizing: border-box;

      &:before {
        $size: 5px;

        content: '';

        position: absolute;
        top: 9px + ($size / 2);
        left: ($recuo / 2) - ($size / 2);

        display: block;
        width: $size;
        height: $size;

        background-color: $color-secondary;
      }
    }
  }

  &__steps {
    &--title {
      font-family: Montserrat-Regular;
      font-size: 50px;
      color: $color-secondary;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      line-height: 1.1;

      @media (min-width: 769px) {
        font-size: 40px;
        padding-top: 60px;
        padding-bottom: 60px;
      }

      span {
        font-family: Montserrat-Bold;
        color: $color-primary;
      }
    }
  }

  &__step-by-step {
    display: none;

    @media (min-width: 769px) {
      background-color: #f7f7f7;
      padding: 20px 40px;
      display: block;

      &--list {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          color: $color-secondary;
          font-size: 18px;
          font-family: Montserrat-ExtraBold;
          opacity: 0.3;
          text-align: center;
          border-bottom: 3px solid #f7f7f7;
          padding: 0 15px;
          cursor: not-allowed;
        }

        li.is-active {
          opacity: 1;
          border-bottom: 4px solid $color-primary;
          cursor: pointer;
        }

        li.is-answer {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  &__button-next {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 70px;

    @media (min-width: 769px) {
      text-align: left;
      /*  margin-bottom: 0px;
      margin-top: 0px; */
    }

    button {
      background-color: $color-primary;
      color: #ffffff;
      font-family: Montserrat-Bold;
      font-size: 17px;
      border-radius: 9px;
      padding: 20px 60px;
      border: none;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      border: 2px solid $color-primary;

      &:hover {
        background-color: #ffffff;
        color: $color-primary;
      }

      &:disabled {
        background-color: #c6c6c6;
        border-color: #c6c6c6;
        cursor: not-allowed;
        color: #ffffff;
        opacity: 0.6;

        &:hover {
          background-color: #c6c6c6;
          border-color: #c6c6c6;
          color: #ffffff;
        }
      }
    }
  }

  &__button-back {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 70px;

    @media (min-width: 769px) {
      text-align: left;
      /*  margin-bottom: 0px;
        margin-top: 0px; */
    }

    button {
      color: $color-primary;
      font-family: Montserrat-Bold;
      font-size: 17px;
      border-radius: 9px;
      padding: 20px 60px;
      border: none;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      border: 2px solid $color-primary;

      &:hover {
        background-color: $color-primary;
        color: #ffffff;
      }
    }
  }
}

.loading {
  $skeleton-bg: #eee;
  $skeleton-slide-bg: darken($skeleton-bg, 7);
  $skeletion-timing: cubic-bezier(0.39, 0.58, 0.57, 1);

  $skeleton-slide-shadow-1: rgba($skeleton-slide-bg, 0);
  $skeleton-slide-shadow-2: rgba($skeleton-slide-bg, 100%);

  .control {
    position: relative;

    height: 40px;
    border-radius: 3px;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      z-index: 3;

      display: block;
      height: 100%;
    }

    &::before {
      left: 0;
      width: 100%;
      background-color: $skeleton-bg;
      border-radius: 3px;
    }

    &::after {
      left: -15px;

      width: 50px;
      background-image: linear-gradient(
        to left,
        #{$skeleton-slide-shadow-1} 10%,
        #{$skeleton-slide-shadow-2},
        #{$skeleton-slide-shadow-1} 90%
      );
      animation: skeleton-sliding 1.25s #{$skeletion-timing} infinite;

      transform: skew(10deg);
    }

    @keyframes skeleton-sliding {
      from {
        left: 115%;
      }
      to {
        left: -15%;
      }
    }
  }

  label .control {
    margin-left: 10px !important;
    padding-left: 0 !important;
  }
}

.amount {
  max-width: 90%;
  margin: 0 auto;

  input,
  span {
    display: inline-block;

    font: {
      family: inherit;
      size: inherit;
    }
  }
  input {
    margin-left: 5px;

    border: none;
    background-color: rgba(#fff, 0);

    font-weight: bold;
    color: inherit;
  }
}
</style>
