<template>
  <div class="form-info-vehicle">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="form-contract__title">
          <h2><span style="font-size: 50px">Dados do Veículo</span></h2>
        </div>
      </div>

      <div class="column">
        <div class="info-basic">
          <base-select
            ref="type"
            v-model="form.type"
            :options="list.type"
            required
            @selected="setBrand($event)"
          >
            Tipo de veículo
          </base-select>

          <base-select
            ref="brand"
            v-model="form.brand"
            :options="list.brand"
            autocomplete
            required
            has-add
            @selected="setModel($event.value)"
            @added="setModel($event, true)"
          >
            Marca
          </base-select>

          <base-select
            ref="model"
            v-model="form.model"
            :options="list.model"
            autocomplete
            required
            has-add
            @selected="setYear($event.value)"
            @added="setYear($event, true)"
          >
            Modelo
          </base-select>
        </div>
      </div>

      <div class="column">
        <div class="info-basic">
          <base-select
            ref="year"
            v-model="form.year"
            :options="list.year"
            :has-add="added.model"
            autocomplete
            required
            @selected="setPrice($event.value)"
            @added="setPrice($event, true)"
          >
            Ano
          </base-select>

          <base-input ref="price" :value="form.price" type="tel" @input="input">
            Valor do veículo
            <span class="info-basic--span">*</span>
          </base-input>

          <base-input ref="license" v-model="form.license">
            Placa do veículo
            <span class="info-basic--span">*</span>
          </base-input>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="info-basic">
          <div class="form-contract__button-next">
            <button
              :disabled="!formIsValidated || actionLoading"
              type="submit"
              @click="submit()"
            >
              PRÓXIMO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SimpleVueValidator from 'simple-vue-validator'

import FIPEAPI from '@/lib/api/FIPE'
import VehicleAPI from '@/lib/api/Vehicle'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'InfoVehicle',

  components: {
    BaseInput: () => import('@/components/BaseInput.vue'),
    BaseSelect: () => import('@/components/BaseSelect.vue'),
  },

  mixins: [SimpleVueValidator.mixin],

  validators: {
    'form.type'(value) {
      this.errors.type = ''
      return Validator.value(value).required()
    },

    'form.brand'(value) {
      this.errors.brand = ''
      return Validator.value(value).required()
    },

    'form.model'(value) {
      this.errors.model = ''
      return Validator.value(value).required()
    },

    'form.year'(value) {
      this.errors.year = ''
      return Validator.value(value).required()
    },

    'form.price'(value) {
      this.errors.price = ''
      return Validator.value(value).required()
    },

    'form.license'(value) {
      this.errors.license = ''
      return Validator.value(value).required()
    },
  },

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data: () => ({
    info: {},
    added: {
      brand: null,
      model: null,
      year: null,
    },
    form: {
      type: '',
      brand: '',
      model: '',
      year: '',
      price: '',
      license: '',
    },
    errors: {
      type: '',
      brand: '',
      model: '',
      year: '',
      price: '',
      license: '',
    },
    list: {
      type: [
        { value: 'cars', name: 'Carro' },
        { value: 'trucks', name: 'Caminhão' },
      ],
      brand: [],
      model: [],
      year: [],
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    actionLoading: false,
  }),

  computed: {
    ...mapState({
      _account: (state) => state.fgts.account,
    }),

    account: {
      get() {
        return this._account
      },
      set(payload) {
        this.setAccount(payload)
      },
    },

    formIsValidated() {
      const validate = (target) => this.validation.isPassed(target)

      return (
        validate('form.type') &&
        validate('form.brand') &&
        validate('form.model') &&
        validate('form.year') &&
        validate('form.price') &&
        validate('form.license')
      )
    },
  },

  methods: {
    ...mapActions({
      setAccount: 'fgts/SET_ACCOUNT',
    }),

    maskValue(value, fractionDigits = 2) {
      return parseFloat(value).toFixed(fractionDigits)
    },

    async setData(data) {
      const setValue = async (group) => {
        for (const key of Object.keys(data[group])) {
          const value = data[group][key]
          await this.$set(this[group], key, value)
        }
      }

      for (const key of Object.keys(data)) {
        await setValue(key)
      }
    },

    async setBrand(type) {
      await this.setData({
        list: { brand: [], model: [], year: [] },
        form: { type, brand: '', model: '', year: '', price: '', license: '' },
      })

      if (!type) return

      await this.handleSelect('getBrands', { type }, 'brand')
    },

    async setModel(brand, isAdd = false) {
      const type = this.form.type

      await this.setData({
        list: { model: [], year: [] },
        form: { brand, model: '', year: '', price: '', license: '' },
      })

      if (!brand) return

      if (isAdd) await this.handleAdd(brand, 'brand')
      await this.handleSelect('getModels', { type, brand }, 'model')
    },

    async setYear(model, isAdd = false) {
      const { type, brand } = this.form

      await this.setData({
        list: { year: [] },
        form: { model, year: '', price: '', license: '' },
      })

      if (!model) return

      if (isAdd) await this.handleAdd(model, 'model')
      else await this.handleSelect('getYears', { type, brand, model }, 'year')
    },

    async setPrice(year, isAdd = false) {
      const api = new FIPEAPI()
      const { type, brand, model } = this.form

      await this.setData({
        form: { year, price: '', license: '' },
      })

      if (!year) return

      if (isAdd) return await this.handleAdd(year, 'year')

      this.$emit('loading', true)

      await api
        .getValue({ type, brand, model, year })
        .then(async ({ data }) => {
          this.info = data

          const { price } = data
          await this.setData({ form: { price } })
        })
        .catch((err) => {
          const error = {
            message: err.response.data.error.message,
            others: [],
          }

          if (err?.response?.data?.error?.errors) {
            const entries = Object.entries(err?.response?.data?.error?.errors)
            for (const [entry, values] of entries) {
              for (const [key, value] of Object.entries(values)) {
                error.others.push({ entry, value })
              }
            }
          }

          this.$emit('message', error)
        })
        .finally(() => this.$emit('loading', false))
    },

    async handleSelect(request, params, target) {
      const api = new FIPEAPI()
      const obj = {
        list: {},
        added: {},
      }

      this.$emit('loading', true)

      await api[request](params)
        .then(async ({ data }) => {
          obj.list[target] = data.map(({ code, name }) => ({
            name,
            value: code,
          }))
          obj.added[target] = false

          this.info = {}

          await this.setData(obj)
        })
        .catch((err) => {
          const error = {
            message:
              err?.response?.data?.error?.message ||
              err?.error?.response?.data?.error?.message ||
              err?.message ||
              err,
            others: [],
          }

          if (err?.response?.data?.error?.errors) {
            const entries = Object.entries(err?.response?.data?.error?.errors)
            for (const [entry, values] of entries) {
              for (const [key, value] of Object.entries(values)) {
                error.others.push({ entry, value })
              }
            }
          }

          this.$emit('message', error)
        })
        .finally(() => this.$emit('loading', false))
    },

    async handleAdd(data, target) {
      const obj = {
        list: {},
        form: {},
        added: {},
      }

      obj.list[target] = [{ name: data, value: data }, ...this.list[target]]
      obj.form[target] = data
      obj.added[target] = true

      this.info = {}

      await this.setData(obj)
    },

    input(data) {
      const value = (data || '0').replace(/\D/g, '') // removes everything that isn't a digit

      const formattedValue = value * 0.01
      const maskedValue = this.maskValue(formattedValue)

      this.form.price = maskedValue
    },

    async getFormData() {
      const getValue = (key) => {
        let field = this.$refs[key]

        switch (key) {
          case 'type':
            field = field.$el.querySelector('select')
            return field.options[field.selectedIndex].text

          case 'price':
          case 'license':
            return field.value

          default:
            field = field.$el.querySelector('input')
            return field.value
        }
      }

      const data = { account_id: this.account.id }

      for (const key of Object.keys(this.form)) {
        if (this.added[key]) {
          data[key] = this.form[key]
        } else {
          switch (key) {
            case 'year':
              data.year = this.info.modelYear || getValue(key)
              break

            default:
              data[key] = this.info[key] || getValue(key)
              break
          }
        }
      }

      const { year, ...rest } = data

      return {
        year: parseInt(year),
        ...rest,
      }
    },

    async submit() {
      const api = new VehicleAPI()

      this.actionLoading = true
      this.$emit('loading', true)

      const data = await this.getFormData()

      await api
        .saveVehicle(data)
        .then(() => {
          this.$gtm.trackEvent({
            event: 'contract-vehicle',
            account_id: this.account.id,
          })
          this.$emit('nextStep')
        })
        .catch((err) => {
          const error = {
            message:
              err?.response?.data?.error?.message ||
              err?.error?.response?.data?.error?.message ||
              err?.message ||
              err,
            others: [],
          }

          if (err?.response?.data?.error?.errors) {
            const entries = Object.entries(err?.response?.data?.error?.errors)
            for (const [entry, values] of entries) {
              for (const [key, value] of Object.entries(values)) {
                error.others.push({ entry, value })
              }
            }
          }

          this.$emit('message', error)
        })
        .finally(() => {
          const el = document.querySelector('.form-contract__step-by-step')
          el.scrollIntoView()

          this.actionLoading = false
          this.$emit('loading', false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-info-vehicle {
  .column {
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      padding: auto;
    }
  }
}

.info-basic {
  max-width: 80%;
  margin: 0 auto;

  .control {
    margin-top: 3.25rem;
  }

  &--span {
    color: $color-primary;
  }
}
</style>
