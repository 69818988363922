import Base from '@/lib/api/Base'

const api = new Base()

export default {
  async FETCH_THEME({ commit }) {
    const { data } = await api.fetchTheme()
    await commit('SET_THEME', data)
  },
}
