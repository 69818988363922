import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    excludedAsCSSVariable: ['business', 'show', 'externalLinks'],
  }),

  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    }),
  },

  async created() {
    await this.fetchTheme()
    await this.setAppTheme()
  },

  methods: {
    ...mapActions({ fetchTheme: 'app/FETCH_THEME' }),

    hexToRGB(hex) {
      const rgb = []

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 3; ++i) {
        const mod = hex.length === 4 ? 1 : 2

        const a = i * mod + 1
        const b = mod - 1 + a

        rgb[i] = `0x${hex[a]}${hex[b]}`
      }

      return `${+rgb[0]},${+rgb[1]},${+rgb[2]}`
    },

    setCssVariable({ property, key, value }) {
      document.documentElement.style.setProperty(`--${property}-${key}`, value)
    },

    setAppTheme() {
      const entries = Object.entries(this.theme)

      // eslint-disable-next-line no-restricted-syntax
      for (const [property, values] of entries) {
        if (!this.excludedAsCSSVariable.includes(property)) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(values)) {
            this.setCssVariable({ property, key, value })

            if (property === 'color') {
              const name = `${key}-rgb`
              const rgb = this.hexToRGB(value)

              this.setCssVariable({ property, key: name, value: rgb })
            }
          }
        }
      }
    },
  },
}
