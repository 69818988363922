<template>
  <div>
    <div :class="{ 'no-bg': !showBackgroundImage }" class="form-finish">
      <div class="columns">
        <div class="column is-offset-6 is-6">
          <p id="contractFinished">{{ title }}</p>
        </div>
      </div>
    </div>

    <template v-if="!showQrcode">
      <div v-if="isPage.fgts" class="column">
        Em até um dia útil o banco enviará um link por WhatsApp / SMS para você
        formalizar o contrato.<br /><br />
        Serão 3 etapas simples para serem cumpridas:<br />
        Foto da sua carteira de identidade ou CNH;<br />
        Uma selfie;<br />
        O aceite do contrato.<br /><br />
        Pronto! Em até 24h o dinheiro cairá na conta que você informou aqui no
        formulário.<br />
      </div>

      <div v-else class="column">
        Pronto! Agora é conosco, iremos analisar as informações preenchidas e
        retornaremos o mais breve possível.<br />
      </div>
    </template>
    <template v-else>
      <div
        class="qrcode p-3 mt-3 mb-6 mx-auto"
        :class="{ active: showQRCodeInPage }"
      >
        <p class="qrcode__text mb-3">
          Recebemos sua proposta! Agilize seu crédito, efetuando a formalização
          com envio de selfie!
        </p>

        <p class="qrcode__text mb-6">
          Utilize o navegador <span>Chrome</span> para esta etapa!
        </p>

        <canvas ref="qrcode" class="qrcode__canvas" />

        <button class="button is-link is-small has-border" @click="saveQRCode">
          <span>Salvar imagem</span>
          <span class="icon">
            <i class="fas fa-download" />
          </span>
        </button>
      </div>
    </template>

    <div v-if="!showQrcode" class="column">
      E nós estamos aqui para outras dúvidas e esclarecimentos.<br /><br />
      Um abraço do {{ businessName }}.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QRCode from 'qrcode'

export default {
  name: 'FormSendSuccess',

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
    showQrcode: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data: () => ({
    msgEnviada: require('@/assets/img/contrate-agora-form/banner-msg-enviada.png'),
    showQRCodeInPage: false,
  }),

  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
      account: (state) => state.fgts.account,
      proposal_id: (state) => state.fgts.proposalID,
    }),

    businessName() {
      return this.theme.business?.name || ''
    },

    showBackgroundImage() {
      return this.theme.show?.FormSendSucess.backgroundImage || false
    },

    title() {
      return this.proposal_id
        ? 'Proposta enviada ao banco'
        : 'Sua resposta foi registrada'
    },
  },

  mounted() {
    const el = document.querySelector('.form-finish')
    el.scrollIntoView()

    this.$nextTick(() => {
      if (this.showQrcode) this.genQRCode()
    })
  },

  methods: {
    genQRCode() {
      const canvas = this.$refs.qrcode
      const { token } = this.account
      const link = `https://fgts-cb.pratadigital.com.br/validacao/${token}/foto-documento/selfie`
      const vm = this

      QRCode.toCanvas(canvas, link, (error) => {
        vm.showQRCodeInPage = true

        if (!error) return

        console.log(error)
        vm.showQRCodeInPage = false
      })
    },

    saveQRCode() {
      const canvas = this.$refs.qrcode
      const image = canvas
        .toDataURL('image/png', 1.0)
        .replace('image/png', 'image/octet-stream')
      const link = document.createElement('a')

      link.href = image
      link.setAttribute('download', 'qrcode-validation-link.png')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>

<style lang="scss">
$recuo: 50px;

.form-finish {
  margin: {
    // top: $recuo;
    bottom: $recuo;
  }

  display: flex;
  background: {
    color: $color-primary;
    position: center;
    repeat: no-repeat;
    size: cover;
    // image: url(/img/banner-msg-enviada.398dfba7.png);
  }
  /* min-height: 320px; */
  padding: 0 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;

  text-align: center;

  &.no-bg {
    background: {
      image: none !important;
      color: $color-secondary;
    }

    * {
      color: $color-light;
    }
  }

  @media (min-width: 769px) {
    margin: {
      top: $recuo;
      bottom: $recuo;
    }

    background: {
      image: url('~@/assets/img/contrate-agora-form/banner-msg-enviada.png');
      position: center;
      repeat: no-repeat;
      size: cover;
    }
    min-height: 320px;

    text-align: left;
  }

  p {
    font: {
      family: Montserrat-Bold;
      size: 40px;
    }
    color: $color-secondary;
  }

  .column {
    margin: 0 auto;
    min-width: 500px;
    padding: 60px 20px;
    //margin-top: 110px;
  }
}

.qrcode {
  --size: 0.1px;
  --display: none;

  display: var(--display);
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__text {
    font: {
      size: 18px;
      family: Montserrat-Bold;
    }
    color: $color-secondary;

    span {
      color: $color-primary;
    }
  }

  &__canvas {
    margin: 0 auto;

    width: var(--size);
    height: var(--size);
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .button {
    margin-top: 1rem;

    &.has-border {
      border: 1px solid currentColor;
    }

    + .button {
      margin-top: 0.5rem;
    }
  }

  &.active {
    --size: 150px;
    --display: flex;
  }
}
</style>
