<template>
  <div :class="{ loading: config.loading }" class="validation-final">
    <validation-header :current="5">Obrigado!</validation-header>

    <div class="validation-final__container slide-in">
      <div class="validation-final__content">
        <template v-if="config.loading">
          <span class="icon spinner">
            <i class="fas fa-spinner"></i>
          </span>
          <span>carregando ...</span>
        </template>
        <template v-else>
          <i class="far fa-check-circle" />
          <p>
            <strong>Dados salvos</strong>
          </p>
          <p>Seus dados foram salvos com sucesso.</p>
          <p>
            Tão logo eles sejam validados, um de nossos consultores seguirá o
            contato.
          </p>
          <p>Aguarde!</p>
        </template>
      </div>
      <div class="validation-final__ctrl">
        <button class="button btn" @click="goHome">
          <span>Voltar ao site</span>
          <span class="icon">
            <i class="fas fa-home" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ValidationHeader from './Header.vue'

import checkMobileCompatibility from '@/mixins/checkMobileCompatibility'
import checkAccountID from '@/mixins/checkAccountID'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationFinal',

  components: {
    ValidationHeader,
  },

  mixins: [checkMobileCompatibility, checkAccountID],

  data: () => ({
    config: {
      loading: false,
    },
  }),

  computed: {
    hasTerms() {
      return this.$route.params.terms
    },
  },

  async mounted() {
    console.log('mounted', this.$route.params)
    if (this.hasTerms) await this.check()
  },

  methods: {
    ...mapActions({
      setAccount: 'validation/SET_ACCOUNT',
    }),

    goHome() {
      this.$router.push({ name: 'validation-login' })
    },

    async check() {
      const api = new ValidationAPI()
      const data = {
        account_id: this.account.account_id,
      }

      this.config.loading = true

      await api
        .check(data)
        .then(() => this.cleanAccount())
        .catch(() => this.goToSelfie())
        .finally(() => {
          this.config.loading = false
        })
    },

    cleanAccount() {
      this.setAccount({})
    },

    goToSelfie() {
      const name = 'validation-photo-selfie'
      const type = 'selfie'
      const side = 'unico'
      const mode = 'user'

      this.$router.push({ name, params: { type, side, mode } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

.validation-final {
  @include base();

  &__container {
    margin-bottom: 0;
  }

  &__content {
    color: $color-secondary;
    text-align: center;

    .far {
      margin-bottom: 10px;
      font: {
        size: 4em;
      }
      color: $color-primary;
    }
  }

  &__ctrl {
    .button {
      margin-top: 30px;
    }
  }
}

.spinner {
  position: absolute;
  top: 20px;
  right: 20px;

  font-size: 2em;
  color: $color-primary;

  animation: 0.75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
</style>
