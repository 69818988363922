<template>
  <div class="faq">
    <div v-for="item in items" :key="item.id" class="faq-item">
      <div
        :id="`question-${item.id}`"
        class="faq-item__question"
        @click="openQuestion(item.id)"
      >
        <h2>
          <span :id="`img-question-${item.id}`">+</span> {{ item.question }}
        </h2>
      </div>
      <div :id="`answer${item.id}`" class="faq-item__answer">
        <p v-html="item.answer"></p>
      </div>
    </div>
  </div>
</template>

<script>
import verticalBorder from '@/assets/img/fgts-lp/border.png'

export default {
  name: 'BaseFaq',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      verticalBorder,
    }
  },

  methods: {
    openQuestion(id) {
      this.items.forEach((element) => {
        document.getElementById(`answer${element.id}`).style.display = 'none'
        document.getElementById(`img-question-${element.id}`).innerHTML = '+'

        if (element.id === id) {
          document.getElementById(`answer${id}`).style.display = 'flex'
          document.getElementById(`img-question-${id}`).innerHTML = '-'
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.faq {
  padding: 40px 30px;
}
.faq-item {
  &__question {
    font-size: 20px;
    font-family: Montserrat-ExtraBold;
    color: $color-secondary;
    margin-bottom: 15px;
    line-height: 1.2;
    cursor: pointer;

    span {
      color: $color-primary;
      font-size: 25px;
      padding-right: 10px;
    }
  }

  &__answer {
    display: none;
    margin-left: 30px;
    padding-left: 30px;
    border-left: 2px solid $color-primary;
    margin-bottom: 30px;

    img {
      margin-right: 20px;
      height: fit-content;
    }
  }
}
</style>
