import Base from './Base'

export default class Simulator extends Base {
  getDataSimulation(data) {
    const { balance_value: balance, birth: birth_month } = data
    const params = { balance, birth_month }

    return this.api.get('/simulators/fgts-credit', { params })
  }

  checkAuthorization(document) {
    const params = { document }
    return this.api.get('/antecipate-fgts/checkAuthorization', { params })
  }

  getOccupations() {
    return this.api.get('/clients/occupations')
  }

  getBanks() {
    return this.api.get('/payments/banks')
  }

  async formContractInfoBasic(data) {
    return await this.api.post('/clients/account', data)
  }

  async formContractQualification(data) {
    return await this.api.post('/clients/qualification', data)
  }

  async formContractReference(data) {
    return await this.api.post('/clients/referral', data)
  }

  async formContractAddress(data) {
    return await this.api.post('/clients/address', data)
  }

  async formContractProfessional(data) {
    return await this.api.post('/clients/professional', data)
  }

  async formContractBankAccount(data) {
    return await this.api.post('/clients/bank-account', data)
  }

  async formContractProposal(data) {
    return await this.api.post('/proposals', data)
  }
}
