<template>
  <div class="modal modal-video is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <iframe
        v-if="isMobile"
        id="iframe-modal-mobile"
        :src="linkYoutube"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

      <iframe
        v-else
        id="iframe-modal"
        :src="linkYoutube"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <button class="modal-close" @click="closeModal"></button>
  </div>
</template>

<script>
export default {
  name: 'BaseModalVideo',

  props: {
    linkYoutube: {
      type: String,
      require: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeModal() {
      if (this.isMobile) {
        document.getElementById('iframe-modal-mobile').src = ''
        document.getElementById('iframe-modal-mobile').src = this.linkYoutube
      }
      document.getElementById('iframe-modal').src = ''
      document.getElementById('iframe-modal').src = this.linkYoutube

      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-video {
  .modal-content {
    @media (min-width: 1024px) {
      min-width: 800px;
      width: 70%;
    }
  }
}

#iframe-modal,
#iframe-modal-mobile {
  width: 100%;
  min-height: 450px;
  padding: 20px;
}
</style>
