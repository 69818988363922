import Vue from 'vue'
import currency from 'currency.js'

Vue.filter('currency', (payload) => {
  const number = payload === null || payload === undefined ? 0 : payload
  let float = 0
  if (typeof number === 'string') {
    float = parseFloat(number.replace(',', '.'))
  } else {
    float = number
  }
  return currency(float, {
    separator: '.',
    decimal: ',',
    symbol: 'R$ ',
    formatWithSymbol: true,
  }).format()
})

Vue.filter('currencyInteger', (payload) => {
  const number = payload === null || payload === undefined ? 0 : payload
  return 'R$' + parseInt(number)
})
