<template>
  <div class="form-info-basic">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="form-contract__title">
          <h2><span style="font-size: 50px">Dados Básicos</span></h2>
        </div>
      </div>

      <div class="column">
        <div class="info-basic">
          <base-input
            :value="name"
            :has-error="
              validation.hasError('name') || name_error ? true : false
            "
            :first-error="validation.firstError('name') || name_error"
            :is-passed="validation.isPassed('name') && !name_error"
            @input="name = $event"
          >
            Nome <span class="info-basic--span">*</span>
          </base-input>

          <base-input :value="email" type="email" @input="email = $event">
            Email <span class="info-basic--span">*</span>
          </base-input>

          <base-input
            :value="phone"
            :has-error="
              validation.hasError('phone') || phone_error ? true : false
            "
            :first-error="validation.firstError('phone') || phone_error"
            :is-passed="validation.isPassed('phone') && !phone_error"
            :type="'tel'"
            :format="'(##) #####-####'"
            @input="phone = $event"
          >
            Celular com DDD <span class="info-basic--span">*</span>
          </base-input>

          <!-- <base-input
            v-if="isPage.vehicle"
            :value="tel"
            :type="'tel'"
            :format="'(##) ####-####'"
            :maxlength="14"
            @input="tel = $event"
          >
            Telefone fixo com DDD
          </base-input> -->

          <base-input
            :value="document"
            :has-error="
              validation.hasError('document') || document_error ? true : false
            "
            :first-error="validation.firstError('document') || document_error"
            :is-passed="validation.isPassed('document') && !document_error"
            type="tel"
            format="###.###.###-##"
            @input="document = $event"
          >
            CPF <span class="document--span">*</span>
          </base-input>
        </div>
      </div>

      <div class="column">
        <div class="info-basic">
          <div
            v-if="
              (!$route.query.utm_campaign && !utm_campaign) ||
              $route.query.utm_campaign == 'octa'
            "
            :class="{
              'has-text-errorlight':
                validation.hasError('referral') || referral_error
                  ? true
                  : false,
              'has-text-successlight':
                validation.isPassed('referral') && !referral_error,
            }"
            class="control address__address-local address-number"
          >
            <select v-model="referral">
              <option :value="null">ESCOLHER</option>
              <option
                v-for="(item, index) in referrals"
                :key="index"
                :value="item.referral"
                :required="true"
              >
                {{ item.label }}
              </option>
            </select>
            <label class="label">Como você nos conheceu? <span>*</span></label>
            <i class="bar"></i>
            <p
              v-if="
                validation.hasError('referral') || referral_error ? true : false
              "
              class="help is-error"
            >
              {{ validation.firstError('referral') || referral_error }}
            </p>
          </div>
          <div class="info-basic__gender">
            <p>Qual seu gênero/sexo? <span class="info-basic--span">*</span></p>
            <label v-for="(option, index) in genderOptions" :key="index">
              <input
                v-model="gender"
                type="radio"
                :value="option.value"
                @click="isOtherGender = false"
              />
              {{ option.label }}
            </label>
            <label class="info-basic__gender--other">
              <input
                v-model="gender"
                type="radio"
                @click="isOtherGender = true"
              />
              Outro:
              <base-input
                v-show="isOtherGender"
                v-model="genderOther"
                :has-error="
                  validation.hasError('genderOther') || genderOther_error
                    ? true
                    : false
                "
                :first-error="
                  validation.firstError('genderOther') || genderOther_error
                "
                :is-passed="
                  validation.isPassed('genderOther') && !genderOther_error
                "
              />
            </label>
          </div>

          <base-input
            :value="birthdate"
            :has-error="
              validation.hasError('birthdate') || birthdate_error ? true : false
            "
            :first-error="validation.firstError('birthdate') || birthdate_error"
            :is-passed="validation.isPassed('birthdate') && !birthdate_error"
            :type="'tel'"
            :format="'##/##/####'"
            @input="birthdate = $event"
          >
            Você nasceu que dia?
            <span class="info-basic--span">*</span>
          </base-input>
          <small style="font-size: 0.8rem">(Dia/Mês/Ano)</small>

          <!-- <base-input
            v-if="isPage.vehicle"
            :value="nationality"
            :has-error="
              validation.hasError('nationality') || nationality_error
                ? true
                : false
            "
            :first-error="
              validation.firstError('nationality') || nationality_error
            "
            :is-passed="
              validation.isPassed('nationality') && !nationality_error
            "
            @input="nationality = $event"
          >
            Nacionalidade <span class="info-basic--span">*</span>
          </base-input> -->
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="info-basic">
          <div class="form-contract__button-next">
            <button
              :disabled="!formIsValidated || actionLoading"
              type="submit"
              @click="handleConsult"
            >
              PRÓXIMO
            </button>
          </div>
        </div>
      </div>
    </div>

    <base-modal
      v-if="modal.show"
      :title="modal.title"
      :has-btn-secondary="false"
      :message-error="modal.error"
      :has-btn-primary="false"
      @close="close"
      @click-secondary="close"
    >
      <template #body>
        <p class="has-text-danger">
          {{ modal.alert }}
        </p>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SimpleVueValidator from 'simple-vue-validator'

//components
import BaseInput from '@/components/BaseInput.vue'
import BaseModal from '@/components/BaseModal.vue'

//data
import iReferrals from '@/lib/data/i-referrals.js'
import eReferrals from '@/lib/data/e-referrals.js'
import cReferrals from '@/lib/data/c-referrals.js'

//libs
import FormContractApi from '@/lib/api/FGTS'
import BalanceAPI from '@/lib/api/Balance'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'InfoBasic',

  components: {
    BaseInput,
    BaseModal,
  },

  mixins: [SimpleVueValidator.mixin],

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  validators: {
    name(value) {
      this.name_error = ''
      return Validator.value(value)
        .required()
        .regex(/^[^0-9]*$/, 'Não é permitido números')
        .regex(
          /[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+\s+[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+/g,
          'Escreva o nome completo'
        )
    },
    phone(value) {
      this.phone_error = ''
      return Validator.value(value).required()
    },
    document(value) {
      this.document_error = ''
      return Validator.value(value)
        .required()
        .minLength(14, 'Não foi possível validar seu CPF')
        .maxLength(14, 'Não foi possível validar seu CPF')
    },
    referral(value) {
      this.referral_error = ''
      return Validator.value(value).required()
    },
    birthdate(value) {
      this.birthdate_error = ''
      return Validator.value(value)
        .required()
        .regex(
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
          'Insira uma data válida'
        )
    },
    gender(value) {
      this.gender_error = ''
      return Validator.value(value).required()
    },
    genderOther(value) {
      this.genderOther_error = ''
      return Validator.value(value).required()
    },
    // nationality(value) {
    //   this.nationality_error = ''
    //   return Validator.value(value).required()
    // },
  },

  data: () => ({
    name_error: '',
    email_error: '',
    phone_error: '',
    document_error: '',
    // nationality_error: '',
    utm_campaign_error: '',
    utm_source_error: '',
    utm_medium_error: '',
    seller_error: '',
    referral_error: '',
    gender_error: '',
    genderOther_error: '',
    birthdate_error: '',
    name: '',
    email: '',
    phone: '',
    // tel: '',
    document: '',
    // nationality: '',
    gender: '',
    genderOther: '',
    birthdate: '',
    genderOptions: [
      { value: 'Feminino', label: 'Feminino/Mulher' },
      { value: 'Masculino', label: 'Masculino/Homem' },
    ],
    isOtherGender: false,
    referrals: null,
    actionLoading: false,
    form: {
      $_referral: '',
    },
    modal: {
      show: false,
      title: '',
      alert: '',
      error: '',
      images: {
        banner: require('@/assets/img/fgts-lp/banner-mobile.png'),
        dot: require('@/assets/img/fgts-lp/ponto.png'),
      },
    },
  }),

  computed: {
    ...mapState({
      $_account: (state) => state.fgts.account,
      $_spreadsheet: (state) => state.fgts.spreadsheet,
      $_status: (state) => state.balance.status,
      $_amount: (state) => state.balance.amount,
      $_document: (state) => state.balance.document,
    }),

    account: {
      get() {
        return this.$_account
      },
      set(payload) {
        this.setAccount(payload)
      },
    },

    spreadsheet: {
      get() {
        return this.$_spreadsheet
      },
      set(payload) {
        this.setSpreadsheet(payload)
      },
    },

    status: {
      get() {
        return this.$_status
      },
      set(payload) {
        this.setStatus(payload)
      },
    },

    amount: {
      get() {
        return this.$_amount
      },
      set(payload) {
        this.setAmount(payload)
      },
    },

    balanceDocument: {
      get() {
        return this.$_document
      },
      set(payload) {
        this.setDocument(payload)
      },
    },

    referral: {
      get() {
        const utm_campaign = this.$route.query.utm_campaign
        const referral = this.form.$_referral

        let status = 'pessoa-outros'

        if (referral) return referral

        if (utm_campaign) return utm_campaign

        if (this.isPage.fgts) {
          status = 'fgts-outros'
        }
        if (this.isPage.vehicle) {
          status = 'veiculo-outros'
        }
        if (this.isPage.immobile) {
          status = 'imovel-outros'
        }

        return status
      },
      set(value) {
        this.$set(this.form, '$_referral', value)
      },
    },

    seller() {
      return this.$route.query.seller
    },

    utm_campaign() {
      return this.$route.query.utm_campaign
    },

    utm_source() {
      return this.$route.query.utm_source
    },

    utm_medium() {
      return this.$route.query.utm_medium
    },

    formIsValidated() {
      const validate = (target) => this.validation.isPassed(target)
      return (
        validate('name') &&
        validate('phone') &&
        validate('document') &&
        validate('birthdate') &&
        (this.isOtherGender ? validate('genderOther') : validate('gender'))
        // (this.isPage.vehicle ? validate('nationality') : true)
      )
    },

    isValidDocument() {
      return this.validation.isPassed('document')
    },

    hasPassed() {
      return this.validation.isPassed('document')
    },

    hasError() {
      return this.validation.hasError('document')
    },

    hasFirstError() {
      return this.validation.firstError('document')
    },

    stepsContainer() {
      const el = document.querySelector('.form-contract__step-by-step')
      return el
    },
  },

  created() {
    switch (this.$router.currentRoute.path) {
      case '/supersim-contrate-agora':
        this.utm_campaign = 'fgts-supersim'
        this.referral = 'fgts-supersim'
        this.referrals = []
        break
      case '/e-contrate-agora':
        this.referrals = eReferrals
        break
      case '/c-contrate-agora':
        this.referrals = cReferrals
        break
      default:
        this.referrals = iReferrals
        break
    }
  },

  mounted() {
    this.document = this.balanceDocument
  },

  methods: {
    ...mapActions({
      setAccount: 'fgts/SET_ACCOUNT',
      setSpreadsheet: 'fgts/SET_SPREADSHEET',
      setStatus: 'balance/SET_STATUS',
      setAmount: 'balance/SET_AMOUNT',
      setDocument: 'balance/SET_DOCUMENT',
    }),

    close() {
      this.modal.show = false
    },

    resetModal() {
      this.modal.error = ''
      this.modal.alert = ''
      this.amount = null
    },

    checkDocument() {
      const document = (this.document || '').replace(/\D/g, '')
      const balanceDocument = (this.balanceDocument || '').replace(/\D/g, '')

      return document === balanceDocument
    },

    handleConsult() {
      let status = 'sem garantia'

      if (this.isPage.fgts) return this.consult()

      if (this.isPage.vehicle) {
        status = 'veículo'
      }

      if (this.isPage.immobile) {
        status = 'imóvel'
      }

      this.spreadsheet = status
      this.submit()
    },

    async consult() {
      const isSameDocument = this.checkDocument()

      if (isSameDocument && this.amount) return this.submit()

      this.resetModal()

      if (!this.isValidDocument) {
        this.modal.show = true
        this.modal.title = 'Atenção'
        this.modal.alert = this.hasFirstError ?? 'CPF precisa ser preenchido'
      } else {
        const api = new BalanceAPI()
        const params = {
          document: this.document.replace(/\D/g, ''),
        }

        try {
          this.actionLoading = true
          this.$emit('loading', true)

          const { data } = await api.consult(params)
          this.handleStatus({
            status: data.data.Planilha,
            amount: data.data.valorPrincipal,
          })

          await this.submit()
        } catch (err) {
          this.handleError(err)
        } finally {
          this.actionLoading = false
          this.$emit('loading', false)
        }
      }
    },

    async getData() {
      const gender = this.isOtherGender ? this.genderOther : this.gender

      const obs = this.spreadsheet || this.status
      const amount = this.amount

      const promoter_id = !~window.location.origin.indexOf('fgts') ? 1 : 1541

      const params = {
        utm_campaign: this.utm_campaign,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        referral: this.referral,
        seller: this.seller,
        name: this.name,
        email: this.email,
        phone: this.phone,
        document: this.document,
        birthdate: this.birthdate,
        gender,
        tenant_id: 1,
        master_id: 1,
        promoter_id,
        rate_id: 6,
        ...(obs ? { obs } : ''),
        ...(amount ? { amount } : ''),
        // ...(this.isPage.vehicle ? { tel: this.tel } : ''),
        // ...(this.isPage.vehicle ? { nationality: this.nationality } : ''),
      }

      return params
    },

    handleStatus(data) {
      const { status, amount } = data

      if (!status) return

      this.status = status
      this.spreadsheet = status
      this.amount = amount
      this.balanceDocument = this.document
    },

    async submit() {
      const el = this.stepsContainer
      const params = await this.getData()

      this.actionLoading = true
      this.$emit('loading', true)
      this.close()

      this.saveUserData(params).then(() => {
        el.scrollIntoView()
        this.actionLoading = false

        this.$emit('loading', false)
      })
    },

    handleError({ data }) {
      const error = {
        message: data?.error?.message || data,
        others: [],
      }

      if (data?.error?.errors) {
        const entries = Object.entries(data?.error?.errors)
        for (const [entry, values] of entries) {
          for (const [key, value] of Object.entries(values)) {
            error.others.push({ entry, value })
          }
        }
      }

      this.$emit('message', error)
    },

    emitGAEvent(data) {
      if (process.env.NODE_ENV !== 'development') {
        this.$gtm.trackEvent({
          event: 'contract-info-basic',
          name: this.name,
          email: this.email,
          phone: this.phone,
          document: this.document,
          birthdate: this.birthdate,
          gender: data.gender,
        })
      }
    },

    setAccountData({ data }) {
      this.account = {
        ...data,
        email: this.email,
      }
    },

    async saveUserData(params) {
      const api = new FormContractApi()

      await api
        .formContractInfoBasic(params)
        .then(({ data }) => {
          this.emitGAEvent(data)
          this.setAccountData(data)

          this.$emit('nextStep')
        })
        .catch((err) => this.handleError(err.response))
    },
  },
}
</script>

<style lang="scss" scoped>
.form-info-basic {
  .column {
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      padding: auto;
    }
  }
}

.info-basic {
  max-width: 80%;
  margin: 0 auto;

  .control {
    margin-top: 3.25rem;
  }

  &--span {
    color: $color-primary;
  }

  &__gender {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    display: flex;
    flex-direction: column;

    p,
    label {
      font-size: 0.8rem;
      color: #616161;
      top: -1rem;
      left: 0;
      margin-bottom: 6px;
    }

    &--other {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      input {
        margin: 0;
        margin-right: 5px;
        margin-top: 4px;
      }

      .control {
        margin: -5px;
        width: 100%;
        padding-left: 10px;

        input {
          width: 100%;
        }
      }
    }
  }

  &__born-local {
    margin-top: 3.25rem;
    select {
      height: 33px;
    }
  }
}
</style>
