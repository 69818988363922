import HomeInfoWhatsApp from '@/views/FgtsLp.vue'

const path = '/entenda'
const name = 'fgts-lp-info-whatsapp'
const component = HomeInfoWhatsApp

export default [
  {
    path,
    name,
    component,
  },
]
