export default {
  SET_ACCOUNT(state, payload) {
    state.account = payload
  },

  SET_SPREADSHEET(state, payload) {
    state.spreadsheet = payload
  },

  SET_PROPOSAL_ID(state, payload) {
    state.proposalID = payload
  },

  SET_WISH_AMOUNT(state, payload) {
    state.wishAmount = payload
  },
}
