import Form from '@/views/FormContract.vue'

const name = 'form-contract-subdomain'
const component = Form

export default [
  {
    path: '*', // ex.: 'contrate-agora'
    name,
    component,
  },
]
