<template>
  <div
    :class="{ hasAllPermissions: !message.error && hasAllPermissions }"
    class="validation-photo is--full"
  >
    <validation-header
      v-show="showTitle"
      ref="header"
      class="validation-photo__header"
      :current="4"
    >
      Foto do documento
      <span class="type">{{ docType }}</span>
      <span class="side">{{ docSideLabel }}</span>
    </validation-header>

    <div v-if="message.error" class="alert">
      <p class="has-text-danger has-background-danger-light p-3">
        {{ message.text }}
      </p>
    </div>

    <div ref="container" class="validation-photo__container slide-in">
      <div
        ref="content"
        :class="{ expanded: config.expanded, hasPhotoLoaded }"
        class="validation-photo__content"
      >
        <i
          v-if="!message.error"
          :class="[config.expanded ? 'fa-times' : 'fa-question']"
          class="fas"
          @click="config.expanded = !config.expanded"
        />

        <div v-show="!config.expanded" key="image" class="photo">
          <span v-show="!hasPhotoLoaded && !config.loading">
            sem foto carregada
          </span>

          <span v-if="config.loading" class="icon spinner">
            <i class="fas fa-circle-notch" />
          </span>

          <div v-show="hasPhotoLoaded" ref="img" class="camera camera--img">
            <img
              :src="form.img"
              :alt="`${docType}-${docSideLabel}`"
              @load="handleImageLoaded"
            />
          </div>

          <div
            id="box-camera"
            ref="canvas"
            :class="{ hasPhotoLoaded }"
            class="camera--canvas"
          />

          <p v-show="config.showInfo" class="info">
            <span>type: {{ docType }}</span>
            <span>mode: {{ facingMode }}</span>
            <span>terms: {{ account.terms }}</span>
            <span>isVerse: {{ isVerse }}</span>
            <span>isOpened: {{ isOpened }}</span>
          </p>
        </div>

        <div
          v-show="showTutorial"
          key="tutorial"
          class="validation-photo__tutorial"
        >
          <template v-if="message.error">
            <p>
              <strong>Ocorreu um erro na solicitação de recursos</strong>
            </p>
            <ul class="list error">
              <li>É preciso garantir permissão de acesso à câmera</li>
              <li>
                Feche outras aplicações que possam estar utilizando a câmera
              </li>
              <li>
                Dispositivos podem, momentaneamente, falhar em liberar o acesso
                à câmera. Tente novamente em instantes
              </li>
            </ul>
          </template>
          <template v-else>
            <p>
              <strong>
                Cuidados para tirar a foto do seu documento corretamente
              </strong>
            </p>
            <ul class="list">
              <template v-if="!hasAllPermissions">
                <li>Permitir acesso: câmera</li>
              </template>
              <li>Tire seu documento do plástico protetor</li>
              <li>Verifique se a imagem está nítida e sem reflexo</li>
              <li>Imagem precisa estar bem enquadrada</li>
              <li>É recomendado que na imagem não haja espaços sobrando</li>
            </ul>
          </template>
        </div>
      </div>

      <div class="validation-photo__ctrl">
        <button
          v-if="message.error"
          key="btn-go-home"
          class="button btn"
          @click="retry"
        >
          <span>Tentar novamente</span>
          <span class="icon">
            <i class="fas fa-undo" />
          </span>
        </button>

        <button
          v-else-if="!hasAllPermissions"
          key="btn-allow"
          class="button btn"
          @click="loadSDK"
        >
          <span>Permitir</span>
          <span class="icon">
            <i class="fas fa-shield-alt" />
          </span>
        </button>

        <template v-else-if="!config.expanded">
          <span v-if="hasPhotoLoaded">
            <button
              class="button btn is-secondary"
              @click="resetImage({ toPlay: true })"
            >
              <i class="fas fa-undo" />
            </button>

            <button class="button btn" @click="submit">
              <span>Salvar</span>
              <span class="icon">
                <i class="fas fa-save" />
              </span>
            </button>
          </span>
        </template>
      </div>
    </div>

    <div v-if="showCTABack && !message.error" class="cta-back">
      <button class="button is-text is-small" @click="goBack">
        <span v-if="hasAllPermissions" key="cta-icon">
          <i class="fas fa-undo-alt" />
        </span>
        <span v-else key="cta-text">Enviar outro tipo?</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  UnicoCheckBuilder,
  UnicoThemeBuilder,
  DocumentCameraTypes,
} from 'unico-webframe'
import _ from 'lodash'

import ValidationHeader from './Header.vue'

import checkMobileCompatibility from '@/mixins/checkMobileCompatibility'
import checkAccountID from '@/mixins/checkAccountID'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationPhotoDocs',

  components: {
    ValidationHeader,
  },

  mixins: [checkMobileCompatibility, checkAccountID],

  props: {
    docSide: {
      type: String,
      default: '',
      required: true,
    },

    docType: {
      type: String,
      default: '',
      required: true,
    },
  },

  data: () => ({
    sdk: {
      builder: null,
      theme: null,
      camera: null,
      services: '/data/services.json',
      models: '/data/models',
      resources: '/data/resources',
    },
    form: {
      img: '',
      photos: {
        docs: {
          base64: '',
          encrypted: '',
        },
      },
    },
    config: {
      showInfo: false,
      expanded: true,
      loading: false,
    },
    message: {
      error: false,
      text: null,
    },
    typesWithTypification: [
      'CNH',
      'CNH_FRENTE',
      'CNH_VERSO',
      'CPF',
      'RG_FRENTE',
      'RG_VERSO',
      'RG_FRENTE_NOVO',
      'RG_VERSO_NOVO',
    ],
  }),

  computed: {
    ...mapState({
      account: (state) => state.validation.account,
      sides: (state) => state.validation.sides,
      process_id: (state) => state.validation.process_id,
    }),

    ...mapGetters({
      sidesOptions: 'validation/getSidesOptions',
    }),

    facingMode() {
      return 'environment'
    },

    isVerse() {
      return this.docSide === this.sides.verso
    },

    isOpened() {
      return this.docSide === this.sides.aberto
    },

    docSideLabel() {
      const options = {
        frente: 'Página com FOTO',
        verso: 'Página com CPF',
      }
      const label = this.sidesOptions[this.docSide]

      if (this.docType === 'CTPS')
        return options[this.sidesOptions[this.docSide]]

      return label
    },

    docCameraTypes() {
      const label = this.docSideLabel.toUpperCase()
      let type = this.docType

      if (type === 'CTPS') return `CTPS`

      if (type === 'NEWRG') return `RG_${label}_NOVO`

      if (this.isOpened) return type

      return `${type}_${label}`
    },

    hasAllPermissions() {
      return this.sdk.camera !== null
    },

    hasPhotoLoaded() {
      return this.form.img !== ''
    },

    showTitle() {
      if (this.hasAllPermissions && this.config.expanded) return false
      return true
    },

    showTutorial() {
      if (this.message.error === true) return true
      return this.config.expanded
    },

    showCTABack() {
      if (this.hasAllPermissions) return !this.config.expanded
      return true
    },
  },

  watch: {
    $route() {
      this.play()

      this.addAnimation(this.$refs.container)
      this.addAnimation(this.$refs.header.$el)
    },
  },

  async mounted() {
    if (this.docType === '') this.goBack()
  },

  methods: {
    retry() {
      window.location.reload(true)
    },

    goBack() {
      this.$router.push({ name: 'validation-photo' })
    },

    resetImage({ toPlay = false } = {}) {
      const img = this.$refs.img?.querySelector('img') || null
      this.form.img = ''

      if (img === null) return

      this.removeAnimation(img, 'fade-in')

      if (toPlay) this.play()
    },

    instantiateNewBuilder() {
      try {
        this.sdk.builder = new UnicoCheckBuilder()
      } catch (err) {
        this.handleError(err)
      }
    },

    instantiateTheme() {
      try {
        this.sdk.theme = new UnicoThemeBuilder()
          .setColorSilhouetteSuccess('#0384fc')
          .setColorSilhouetteError('#D50000')
          .setColorSilhouetteNeutral('#fcfcfc')
          .setBackgroundColor('#dff1f5')
          .setColorText('#0384fc')
          .setBackgroundColorComponents('#0384fc')
          .setColorTextComponents('#dff1f5')
          .setBackgroundColorButtons('#0384fc')
          .setColorTextButtons('#dff1f5')
          .setBackgroundColorBoxMessage('#fff')
          .setColorTextBoxMessage('#000')
          .setHtmlPopupLoading(
            `<div style="position:absolute;top:45%;right:50%;transform:translate(50%,-50%);z-index:10;text-align:center;">Carregando...</div>`
          )
          .build()
      } catch (err) {
        this.handleError(err)
      }
    },

    async instantiateCamera() {
      try {
        await this.sdk.builder
          .setTheme(this.sdk.theme)
          .setModelsPath(this.sdk.models)
          .setResourceDirectory(this.sdk.resources)

        this.sdk.camera = await this.sdk.builder.build()
      } catch (err) {
        this.handleError(err)
      }
    },

    async loadSDK() {
      this.config.loading = true

      await Promise.all([this.instantiateNewBuilder(), this.instantiateTheme()])
        .then(async () => {
          await this.instantiateCamera()

          this.config.expanded = false

          await this.play()
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.config.loading = false
        })
    },

    async play() {
      this.resetImage()
      await this.prepareDocs()
    },

    handleError(err) {
      this.message.text = _.isString(err)
        ? err
        : err?.message ||
          err?.error?.message ||
          'Ocorreu um problema durante sua solicitação'

      this.message.error = true
      this.config.expanded = true
      this.config.loading = false
    },

    handleSuccess({ base64, encrypted }, target = 'docs') {
      const base64Formatted = !~base64.indexOf('data:image')
        ? `data:image/jpeg;base64,${base64}`
        : base64

      this.$set(this.form.photos, target, {
        base64: base64Formatted,
        encrypted,
      })
      this.form.img = base64Formatted
    },

    prepareDocs() {
      const _this = this
      const type = this.docCameraTypes
      const documentCameraType = this.typesWithTypification.includes(type)
        ? DocumentCameraTypes[this.docCameraTypes]
        : DocumentCameraTypes.OTHERS(this.docCameraTypes)

      this.config.loading = true

      this.sdk.camera
        .prepareDocumentCamera(this.sdk.services, documentCameraType)
        .then((opener) => {
          this.config.loading = false

          opener.open({
            on: {
              success: (data) => _this.handleSuccess(data, 'docs'),
              error: (err) => _this.handleError(err),
              support: (msg) => console.log(msg),
            },
          })
        })
    },

    nextStep() {
      // from (A) to (B)
      let name = 'validation-photo-docs'
      let type = this.docType
      let side = this.sides.verso
      let mode = this.facingMode
      let terms = false

      this.config.expanded = false

      // from (B || C) to (Final)
      if (this.isOpened || this.isVerse) {
        name = 'validation-final'
        type = ''
        side = ''
        mode = ''
        terms = this.account.terms
      }

      this.$router.push({
        name,
        params: {
          terms,
          ...(type ? { type } : ''),
          ...(side ? { side } : ''),
          ...(mode ? { mode } : ''),
        },
      })
    },

    getFormattedData() {
      const { account_id, name, document } = this.account
      const { base64: img, encrypted } = this.form.photos.docs
      const { docSide: side, docType: type, process_id } = this

      return {
        name,
        document,
        account_id,
        img,
        encrypted,
        type,
        side,
        process_id,
      }
    },

    async submit() {
      const api = new ValidationAPI()
      const params = this.getFormattedData()

      this.config.loading = true
      this.message.error = false
      this.message.text = null

      try {
        await api.postDocs(params)
        this.nextStep()
      } catch (err) {
        this.handleError(err || { error: err })
      } finally {
        this.resetImage()
        this.config.loading = false
      }
    },

    addAnimation(target, animation) {
      // reset animation effect
      target.style.animation = 'none'
      target.offsetWidth
      target.style.animation = null

      // add new animation
      if (animation) target.classList.add(animation)
    },

    removeAnimation(target, animation) {
      if (target) target.classList.remove(animation)
    },

    handleImageLoaded(event) {
      const target = event.target
      const { width, height, style } = target

      if (width > height)
        style.setProperty('transform', 'rotate(90deg) scale(1.5)')
      else style.setProperty('transform', 'initial')

      this.addAnimation(target, 'fade-in')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_photo.scss';
</style>
