<template>
  <div class="correspondentes-bancarios">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="box-header">
            <h3 class="correspondentes-bancarios__title">
              Não precisa abrir conta corrente nova, o dinheiro vai cair na sua
              conta!
            </h3>
          </div>
        </div>
        <div v-if="!isPage.immobile" class="column is-7">
          <p class="text">Nossos parceiros</p>
          <div class="columns">
            <article
              v-for="(bank, index) in banks"
              :key="index"
              :class="`correspondente-${index + 1}`"
              class="col correspondentes-bancarios__bancos"
            >
              <div class="entry-image">
                <div class="mask">
                  <img width="193" height="91" :src="bank" />
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseBannerInformation',

  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data: () => ({
    validBanks: [
      // require('@/assets/img/parceiros/banco-bv-logo.png'),
      // require('@/assets/img/parceiros/banco-safra-logo.png'),
      // require('@/assets/img/parceiros/banco-pan-logo.png'),
      // require('@/assets/img/parceiros/c6-bank-logo.png'),
      // require('@/assets/img/parceiros/logo-cb-partners.png'),
      require('@/assets/img/parceiros/logo-qi-tech-v2.png'),
    ],
  }),

  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    }),

    banks() {
      return this.validBanks
    },
  },
}
</script>

<style lang="scss" scoped>
#correspondentes-bancarios {
  padding: 0 !important;
}

.correspondentes-bancarios {
  background-image: url('~@/assets/img/fgts-lp/bg-parceiros-mobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 769px) {
    padding: 60px;
    background-image: url('~@/assets/img/fgts-lp/bg-parceiros.png');
  }

  .box-header {
    height: 100%;

    @media (min-width: 769px) {
      display: flex;
      align-items: center;
      margin-right: 20px;
      justify-content: center;
      height: 100%;
    }
  }

  .text {
    margin-bottom: 20px;

    font: {
      family: Montserrat-SemiBold;
      size: 1.75em;
    }
    text-align: center;
    color: #ffffff;

    @media (min-width: 769px) {
      text-align: left;
    }
  }

  &__title {
    font-size: 32px;
    font-family: Montserrat-SemiBold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 100px;

    @media (min-width: 769px) {
      text-align: left;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  &__bancos {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    margin: 0 auto 30px;

    @media (min-width: 769px) {
      max-width: auto;
      margin: 0 30px 0 0;

      &:first-child {
        margin-left: 0.75rem;
      }
    }

    .mask {
      $size: 100px;

      width: $size;
      height: $size;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: grayscale(100%);
      transition: all 0.5s ease-in-out;

      &:hover {
        filter: grayscale(0);
      }
    }
  }
}
</style>
