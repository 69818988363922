<template>
  <div
    :class="{ loading: config.loading }"
    class="validation-documents is--full"
  >
    <validation-header :current="2">Confirmar dados</validation-header>

    <div class="validation-documents__container slide-in">
      <div v-if="message.error" class="alert">
        <p class="p-5 has-text-danger has-background-danger-light">
          {{ message.error }}
        </p>
        <p>Ocorreu um problema durante a requisição dos dados</p>
        <p>Isso pode ocorrer devido a instabilidades momentâneas na rede</p>
        <p>Recarregue a página e tente novamente</p>

        <button class="button btn mt-5" @click.prevent="reload">
          <span>Recarregar</span>
          <span class="icon">
            <i class="fas fa-undo" />
          </span>
        </button>
      </div>
      <template v-else>
        <div class="validation-documents__content">
          <template v-if="config.loading">
            <span class="icon spinner">
              <i class="fas fa-spinner"></i>
            </span>
            <span>carregando ...</span>
          </template>

          <template v-else>
            <template
              v-for="({ label, value, signal, suffix }, index) in simulation"
            >
              <div :key="index" class="control">
                <label>{{ label }}</label>
                <span v-if="signal" class="input">
                  {{ money(value) }}
                </span>
                <span v-else class="input">{{ value }} {{ suffix }}</span>
                <i class="bar" />
              </div>
            </template>

            <ul class="list mt-5">
              <li class="control">
                <label>Parcelas</label>
              </li>
              <li
                v-for="({ due_date, total_amount }, index) in periods"
                :key="index"
                class="list__item text"
              >
                <span>{{ due_date | truncate(4, '') }}: </span>
                <input :value="money(total_amount)" type="text" disabled />
              </li>
              <li class="control">
                <i class="bar" />
              </li>
            </ul>
          </template>
        </div>

        <div v-if="!config.loading" class="validation-documents__ctrl">
          <button class="button btn" @click="handleTerms(true)">
            <span>Aceito</span>
            <span class="icon">
              <i class="fas fa-check" />
            </span>
          </button>
          <button class="button btn is--secondary" @click="handleTerms(false)">
            <span>Não aceito</span>
            <span class="icon">
              <i class="fas fa-times" />
            </span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import QITechAPI from '@/lib/api/QITech'

import ValidationHeader from './Header.vue'

import checkMobileCompatibility from '@/mixins/checkMobileCompatibility'
import checkAccountID from '@/mixins/checkAccountID'

export default {
  name: 'ValidationConsult',

  components: {
    ValidationHeader,
  },

  mixins: [checkMobileCompatibility, checkAccountID],

  data: () => ({
    info: {},
    form: {
      terms: false,
    },
    message: {
      error: false,
    },
    config: {
      loading: false,
    },
  }),

  computed: {
    ...mapState({
      account: (state) => state.validation.account,
    }),

    simulation() {
      const keys = Object.keys(this.info)

      if (keys.length === 0) return []

      return [
        {
          label: 'Tipo de produto',
          value: 'Empréstimo saque-aniversário FGTS',
        },
        {
          label: 'Valor a receber',
          value: this.info.disbursed_issue_amount,
          signal: 'money',
        },
        {
          label: 'Valor operação',
          value: this.info.assignment_amount,
          signal: 'money',
        },
        {
          label: 'Taxa de Juros',
          value: (this.info.monthly_rate * 100).toFixed(2),
          suffix: '% a.m.',
        },
        {
          label: 'Custo efetivo total',
          value: (this.info.cet * 100).toFixed(2),
          suffix: '% a.m.',
        },
      ]
    },

    periods() {
      const keys = Object.keys(this.info)

      if (keys.length === 0) return []

      if (!this.info.periods) return []

      return this.info.periods.map(({ due_date, total_amount }) => ({
        due_date,
        total_amount: total_amount.toFixed(2),
      }))
    },
  },

  mounted() {
    this.consult()
  },

  methods: {
    ...mapActions({
      setAccount: 'validation/SET_ACCOUNT',
    }),

    reload() {
      window.location.reload(true)
    },

    money(value) {
      return parseFloat(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },

    formatDate(date) {
      return date.split('-').reverse().join('/')
    },

    setData({ data }) {
      for (const key of Object.keys(this.info)) {
        this.$delete(this.info, key)
      }
      for (const key of Object.keys(data)) {
        this.$set(this.info, key, data[key])
      }
    },

    async consult() {
      const api = new QITechAPI()
      const params = {
        document: (this.account.document || '').replace(/\D/g, ''),
        wish_amount: this.account.wish_amount || '',
      }

      this.config.loading = true
      this.message.error = false

      try {
        const { data } = await api.consult(params)

        if (data.data.permiteConsulta === false) {
          this.message.error = true
        } else this.setData(data)
      } catch (err) {
        console.log({ ...err })
        this.message.error = err.response.data.error.message
      } finally {
        this.config.loading = false
      }
    },

    async handleTerms(state) {
      this.form.terms = state

      await this.setAccount({
        ...this.account,
        terms: state,
      })

      this.nextStep()
    },

    nextStep() {
      const name = 'validation-photo-selfie'
      const type = 'selfie'
      const side = 'unico'
      const mode = 'user'

      this.$router.push({ name, params: { type, side, mode } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

.validation-documents {
  @include base();

  [class*='__ctrl'] {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 30px;

    .is--secondary {
      background-color: $color-light;
      border-color: $color-light;
    }
  }
}

.alert {
  padding: 30px;
  text-align: center;

  p {
    + p {
      margin-top: 30px;
    }
  }
}

div.control {
  margin-bottom: 0;

  display: grid;
  width: 100%;

  grid-template-columns: 1fr;

  &:first-child {
    margin-top: 0;
  }

  + div.control {
    margin-top: 30px;
  }

  label {
    color: #616161;
  }

  span {
    display: block;
    border: none;
    height: auto;
    box-shadow: none;
    padding: 0.5em 0.125rem 0.75em;
    background-color: rgba(#000, 0);

    color: $color-secondary;
    line-height: 1em;
  }

  input {
    color: $color-secondary;
  }
}

.spinner {
  position: absolute;
  top: 20px;
  right: 20px;

  font-size: 2em;
  color: $color-primary;

  animation: 0.75s linear infinite spinner-border;
}

.list {
  margin: 0;
  padding: 0;

  &__item {
    $padding: 30px;

    position: relative;
    margin-bottom: 20px;

    display: flex;
    padding-left: $padding;

    &::before {
      $size: 10px;

      content: '';

      position: absolute;
      top: 50%;
      left: (($padding / 2) - ($size / 2));

      display: block;
      width: $size;
      height: $size;
      background-color: $color-primary;

      transform: translateY(-50%);
    }

    input,
    span {
      display: inline-block;

      font: {
        family: inherit;
        size: inherit;
      }
    }

    span {
      color: $color-secondary;
    }

    input {
      margin-left: 5px;

      border: none;
      background-color: rgba(#fff, 0);

      color: #444;
    }
  }
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
</style>
