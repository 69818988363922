var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"validation__header fade-in"},[_c('img',{staticClass:"validation__logo",attrs:{"src":_vm.logo,"alt":_vm.businessName}}),(_vm.hasSteps)?_c('div',{staticClass:"validation__steps"},_vm._l((Array(_vm.times)),function(step,index){return _c('div',{key:index,staticClass:"validation__step",class:[{ done: index < _vm.current }, { current: index === _vm.current }]},[_c('i',{class:[
          { 'fas fa-check-circle': _vm.current === _vm.times - 1 || index < _vm.current },
          {
            'fas fa-exclamation-circle':
              index === _vm.current && _vm.current !== _vm.times - 1,
          },
          { 'far fa-circle': index > _vm.current },
        ]})])}),0):_vm._e(),(_vm.hasTitle)?_c('h3',{staticClass:"validation__title"},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }