export default {
  SET_STATUS(state, payload) {
    state.status = payload
  },

  SET_AMOUNT(state, payload) {
    state.amount = payload
  },

  SET_DOCUMENT(state, payload) {
    state.document = payload
  },
}
